<div class="aside aside-left aside-fixed d-flex flex-column flex-row-auto" [class.aside-on]="toggled">
    <div class="brand flex-column-auto">
        <a class="brand-logo" [routerLink]="['/']">
            <span class="brand-title">BABEL</span>
        </a>
    </div>
    <div class="aside-menu-wrapper flex-column-fluid">
        <div class="aside-menu">
            <ul class="menu-nav">
                <li class="menu-item menu-item-submenu" [class.menu-item-open]="opened == 'telephony'" (click)="toggle('telephony')">
                    <a class="menu-link menu-toggle">
                        <span class="menu-icon">
                            <fa-icon [icon]="faPhone"></fa-icon>
                        </span>
                        <span class="menu-text">Téléphonie</span>
                    </a>
                    <div class="menu-submenu">
                        <ul class="menu-subnav">
                            <li class="menu-item" >
                                <a [routerLink]="['/telephony/calls']" class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Appels</span>
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="user.admin">
                                <a [routerLink]="['/telephony/call-qualifications']" class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Qualifications</span>
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="user.admin">
                                <a [routerLink]="['/telephony/call-reasons']" class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Motifs</span>
                                </a>
                            </li>
                            <li class="menu-item">
                                <a [routerLink]="['/telephony/call-campaigns']" class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Campagnes</span>
                                </a>
                            </li>
                            <li class="menu-item" *ngIf="user.admin">
                                <a [routerLink]="['/telephony/statistics']" class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Statistiques</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="menu-item menu-item-submenu" [class.menu-item-open]="opened == 'tickets'" (click)="toggle('tickets')" *ngIf="user.admin">
                    <a class="menu-link menu-toggle">
                        <span class="menu-icon">
                            <fa-icon [icon]="faTicketAlt"></fa-icon>
                        </span>
                        <span class="menu-text">Tickets</span>
                    </a>
                    <div class="menu-submenu">
                        <ul class="menu-subnav">
                            <li class="menu-item" >
                                <a [routerLink]="['/tickets']"class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Tickets</span>
                                </a>
                            </li>
                            <li class="menu-item" >
                                <a [routerLink]="['/tickets/ticket-domains']"class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Domaines</span>
                                </a>
                            </li>
                            <li class="menu-item" >
                                <a [routerLink]="['/tickets/ticket-bridges']"class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Passerelles</span>
                                </a>
                            </li>
                            <li class="menu-item" >
                                <a [routerLink]="['/tickets/ticket-quick-answers']"class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Réponses rapides</span>
                                </a>
                            </li>
                            <li class="menu-item" >
                                <a [routerLink]="['/tickets/ticket-actions']"class="menu-link">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">Actions</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="menu-item">
                    <a routerLinkActive="active" [routerLink]="['/contacts']" class="menu-link">
                        <span class="menu-icon">
                            <fa-icon [icon]="faUsers"></fa-icon>
                        </span>
                        <span class="menu-text">Contacts</span>
                    </a>
                </li>
                <li class="menu-item" *ngIf="user.admin">
                    <a routerLinkActive="active" [routerLink]="['/switchboards']" class="menu-link">
                        <span class="menu-icon">
                            <fa-icon [icon]="faCloud"></fa-icon>
                        </span>
                        <span class="menu-text">Standards</span>
                    </a>
                </li>
                <li class="menu-item">
                    <a routerLinkActive="active" [routerLink]="['/profile/general']" class="menu-link">
                        <span class="menu-icon">
                            <fa-icon [icon]="faUserCog"></fa-icon>
                        </span>
                        <span class="menu-text">Profile</span>
                    </a>
                </li>
                <li class="menu-item" *ngIf="user.admin">
                    <a routerLinkActive="active" [routerLink]="['/users']" class="menu-link">
                        <span class="menu-icon">
                            <fa-icon [icon]="faUsers"></fa-icon>
                        </span>
                        <span class="menu-text">Utilisateurs</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>