import { Component, Input, OnInit } from '@angular/core';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
  @Input() src;
  audio: any;
  duration: any;
  width: string = '0%';
  action: string = 'play';
  faPlay = faPlay;
  faPause = faPause;
  constructor() { }

  ngOnInit(): void {
    this.audio = new Audio(this.src);
    setInterval(() => {
      this.width = this.audio.currentTime / this.audio.duration * 100 + "%";
    }, 500);
  }

  play() {
    this.audio.play();
    this.action = 'pause';
  }

  pause() {
    this.audio.pause();
    this.action = 'play';
  }  
  

}
