import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { environment } from 'src/environments/environment';
import { User } from '../API.service';
import { AuthService } from './auth.service';
import { TwilioService } from './twilio.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  pusher: Pusher;
  user: User;
  channel: Channel;

  constructor(
    private twilioService: TwilioService,
    private authService: AuthService
  ) {
    this.user = this.authService.getCurrentUser();
    this.pusher = new Pusher(environment.pusher.appKey, {
      cluster: 'eu',
    });
  }

  subscribe() {
    this.channel = this.pusher.subscribe('outbound-calls');
    this.channel.bind(this.user.email, (data) => {
      this.twilioService.toCall.next(data.number);
    });
  }
}
