<div class="toolbar">
    <div class="btn-group">
        <button type="button" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="bold()">
            <fa-icon [icon]="faBold"></fa-icon>
        </button>

        <button type="button" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="italic()">
            <fa-icon [icon]="faItalic"></fa-icon>
        </button>
    </div>

    <div class="btn-group ml-3">
        <button type="button" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="listOl()">
            <fa-icon [icon]="faListOl"></fa-icon>
        </button>

        <button type="button" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="listUl()">
            <fa-icon [icon]="faListUl"></fa-icon>
        </button>
    </div>
</div>


<div class="content mt-3" [attr.data-placeholder]="text.length == 0 ? 'Tapez votre message...' : ''"
    contenteditable="true" #content (input)="change($event.target.innerHTML)"></div>