<div class="d-flex align-items-center justify-content-between mb-3">
    <span class="font-size-h2">Transférer l'appel à</span>
    <button class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="cancel()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>

<form autocomplete="off" [formGroup]="contactForm">
    <div class="form-group">
        <input class="form-control" autocomplete="off" formControlName="search">
    </div>
</form>

<div *ngIf="filteredUsers.length > 0" class="mt-3">
    <span class="text-muted font-size-lg font-weight-bolder">Membres de l'équipe</span>
    <div class="row">
        <div class="col-4" *ngFor="let user of filteredUsers">
            <div class="text-center my-2 teammate" (click)="toggleSelectedUser(user)">
                <div class="symbol symbol-50 "
                    [class]="selectedUser && selectedUser.id === user.id ? 'symbol-light-success' : 'symbol-light'">
                    <span class="symbol-label">
                        <app-user-avatar [user]="user"></app-user-avatar>
                    </span>
                </div>
                <div class="text-dark mt-3">{{ user.firstname ? user.firstname + ' ' + user.lastname :
                    user.username }}</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedUser" class="mt-3">
    <button class="btn btn-block btn-block btn-primary mb-3" (click)="coldTransfer()">Transférer maintenant</button>
    <button class="btn btn-block btn-block btn-warning" (click)="warmTransfer()">Parler à {{ selectedUser.firstname }} avant</button>
</div>
