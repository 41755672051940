<div class="btn-group">
    <button 
        type="button" 
        class="btn"
        [class.btn-success]="user.activitySid === availableSid"
        [class.btn-warning]="user.activitySid === unavailableSid"
        [class.btn-danger]="user.activitySid === offlineSid"
    >
        <span [ngSwitch]="user.activitySid">
            <div *ngSwitchCase="availableSid">Disponible</div>
            <div *ngSwitchCase="unavailableSid">Occupé</div>
            <div *ngSwitchCase="offlineSid">Hors ligne</div>
        </span>
    </button>
    <button 
        type="button" 
        class="btn dropdown-toggle dropdown-toggle-split" 
        [class.btn-success]="user.activitySid === availableSid"
        [class.btn-warning]="user.activitySid === unavailableSid"
        [class.btn-danger]="user.activitySid === offlineSid"
        data-toggle="dropdown"
        aria-haspopup="true" 
        aria-expanded="false" 
        (click)="show = !show"
    >
        <span class="sr-only">Toggle Dropdown</span>
    </button>
    <div class="dropdown-menu" [class.show]="show">
        <span class="dropdown-item" (click)="setOffline()" *ngIf="user.activitySid !== offlineSid">Passer hors ligne</span>
        <span class="dropdown-item" (click)="setUnavailable()" *ngIf="user.activitySid !== unavailableSid">Passer en occupé</span>
        <span class="dropdown-item" (click)="setAvailable()" *ngIf="user.activitySid !== availableSid">Passer en disponible</span>
    </div>
</div>