import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Storage } from 'aws-amplify';
import { User } from 'src/app/API.service';
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnChanges {
  @Input() user: User;
  avatar: any = null;
  constructor() { }

  async ngOnChanges() {
    if (this.user.avatar) {
      this.avatar = await Storage.get(this.user.avatar);
    }
    
  }

}
