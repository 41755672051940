/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateSwitchboard: OnCreateSwitchboardSubscription;
  onUpdateSwitchboard: OnUpdateSwitchboardSubscription;
  onDeleteSwitchboard: OnDeleteSwitchboardSubscription;
  onCreateCall: OnCreateCallSubscription;
  onUpdateCall: OnUpdateCallSubscription;
  onDeleteCall: OnDeleteCallSubscription;
  onCreateCallParticipant: OnCreateCallParticipantSubscription;
  onUpdateCallParticipant: OnUpdateCallParticipantSubscription;
  onDeleteCallParticipant: OnDeleteCallParticipantSubscription;
  onCreateCallQualification: OnCreateCallQualificationSubscription;
  onUpdateCallQualification: OnUpdateCallQualificationSubscription;
  onDeleteCallQualification: OnDeleteCallQualificationSubscription;
  onCreateCallReason: OnCreateCallReasonSubscription;
  onUpdateCallReason: OnUpdateCallReasonSubscription;
  onDeleteCallReason: OnDeleteCallReasonSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateInteractiveVoiceResponse: OnCreateInteractiveVoiceResponseSubscription;
  onUpdateInteractiveVoiceResponse: OnUpdateInteractiveVoiceResponseSubscription;
  onDeleteInteractiveVoiceResponse: OnDeleteInteractiveVoiceResponseSubscription;
  onCreateInteractiveVoiceResponseUser: OnCreateInteractiveVoiceResponseUserSubscription;
  onUpdateInteractiveVoiceResponseUser: OnUpdateInteractiveVoiceResponseUserSubscription;
  onDeleteInteractiveVoiceResponseUser: OnDeleteInteractiveVoiceResponseUserSubscription;
  onCreateCallCampaign: OnCreateCallCampaignSubscription;
  onUpdateCallCampaign: OnUpdateCallCampaignSubscription;
  onDeleteCallCampaign: OnDeleteCallCampaignSubscription;
  onCreateCallCampaignCustomer: OnCreateCallCampaignCustomerSubscription;
  onUpdateCallCampaignCustomer: OnUpdateCallCampaignCustomerSubscription;
  onDeleteCallCampaignCustomer: OnDeleteCallCampaignCustomerSubscription;
  onCreateCallObjection: OnCreateCallObjectionSubscription;
  onUpdateCallObjection: OnUpdateCallObjectionSubscription;
  onDeleteCallObjection: OnDeleteCallObjectionSubscription;
  onCreateCallScript: OnCreateCallScriptSubscription;
  onUpdateCallScript: OnUpdateCallScriptSubscription;
  onDeleteCallScript: OnDeleteCallScriptSubscription;
  onCreateCallScriptRelation: OnCreateCallScriptRelationSubscription;
  onUpdateCallScriptRelation: OnUpdateCallScriptRelationSubscription;
  onDeleteCallScriptRelation: OnDeleteCallScriptRelationSubscription;
  onCreateContact: OnCreateContactSubscription;
  onUpdateContact: OnUpdateContactSubscription;
  onDeleteContact: OnDeleteContactSubscription;
  onCreateTicketBridge: OnCreateTicketBridgeSubscription;
  onUpdateTicketBridge: OnUpdateTicketBridgeSubscription;
  onDeleteTicketBridge: OnDeleteTicketBridgeSubscription;
  onCreateTicket: OnCreateTicketSubscription;
  onUpdateTicket: OnUpdateTicketSubscription;
  onDeleteTicket: OnDeleteTicketSubscription;
  onCreateTicketQuickAnswer: OnCreateTicketQuickAnswerSubscription;
  onUpdateTicketQuickAnswer: OnUpdateTicketQuickAnswerSubscription;
  onDeleteTicketQuickAnswer: OnDeleteTicketQuickAnswerSubscription;
  onCreateTicketMessage: OnCreateTicketMessageSubscription;
  onUpdateTicketMessage: OnUpdateTicketMessageSubscription;
  onDeleteTicketMessage: OnDeleteTicketMessageSubscription;
  onCreateTicketDomain: OnCreateTicketDomainSubscription;
  onUpdateTicketDomain: OnUpdateTicketDomainSubscription;
  onDeleteTicketDomain: OnDeleteTicketDomainSubscription;
  onCreateTicketSubDomain: OnCreateTicketSubDomainSubscription;
  onUpdateTicketSubDomain: OnUpdateTicketSubDomainSubscription;
  onDeleteTicketSubDomain: OnDeleteTicketSubDomainSubscription;
  onCreateTicketQuickAnswerTicketSubDomain: OnCreateTicketQuickAnswerTicketSubDomainSubscription;
  onUpdateTicketQuickAnswerTicketSubDomain: OnUpdateTicketQuickAnswerTicketSubDomainSubscription;
  onDeleteTicketQuickAnswerTicketSubDomain: OnDeleteTicketQuickAnswerTicketSubDomainSubscription;
  onCreateTicketAction: OnCreateTicketActionSubscription;
  onUpdateTicketAction: OnUpdateTicketActionSubscription;
  onDeleteTicketAction: OnDeleteTicketActionSubscription;
};

export type ModelCallConnection = {
  __typename: "ModelCallConnection";
  items: Array<Call>;
  nextToken?: string | null;
};

export type Call = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: Switchboard | null;
  userID?: string | null;
  user?: User | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: Customer | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<Order | null> | null;
  shippings?: Array<Shipping | null> | null;
  cart?: Cart | null;
  callParticipants?: ModelCallParticipantConnection | null;
  callQualificationID?: string | null;
  callQualification?: CallQualification | null;
  callReasonID?: string | null;
  callReason?: CallReason | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: InteractiveVoiceResponse | null;
  callCampaignID?: string | null;
  callCampaign?: CallCampaign | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type Switchboard = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: InteractiveVoiceResponse | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type InteractiveVoiceResponse = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: InteractiveVoiceResponse | null;
  name: string;
  playText?: string | null;
  users?: ModelInteractiveVoiceResponseUserConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelInteractiveVoiceResponseUserConnection = {
  __typename: "ModelInteractiveVoiceResponseUserConnection";
  items: Array<InteractiveVoiceResponseUser>;
  nextToken?: string | null;
};

export type InteractiveVoiceResponseUser = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: User;
  interactiveVoiceResponse: InteractiveVoiceResponse;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: ModelInteractiveVoiceResponseUserConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum Gender {
  male = "male",
  female = "female"
}

export type Customer = {
  __typename: "Customer";
  id: number;
  firstname: string;
  lastname: string;
  gender: string;
  email: string;
  blocked: boolean;
  badge: string;
  pareto: string;
  addresses?: Array<Address | null> | null;
  createdAt: string;
};

export type Address = {
  __typename: "Address";
  id: number;
  firstname: string;
  lastname: string;
  prefix?: string | null;
  street: string;
  street2?: string | null;
  zipcode: string;
  city: string;
  country: string;
  society?: string | null;
  shippingInformations?: string | null;
  phone: string;
  mobilePhone?: string | null;
};

export type Order = {
  __typename: "Order";
  id: number;
  incrementId: string;
  totalAmount: number;
  status: string;
  marketplace?: string | null;
  orderProducts?: Array<OrderProduct | null> | null;
  createdAt: string;
};

export type OrderProduct = {
  __typename: "OrderProduct";
  name: string;
  qty: number;
};

export type Shipping = {
  __typename: "Shipping";
  id: number;
  status: string;
  createdAt: string;
  tracking?: string | null;
  shippingMethod: string;
};

export type Cart = {
  __typename: "Cart";
  id: number;
  totalAmount: number;
  cartProducts?: Array<CartProduct | null> | null;
};

export type CartProduct = {
  __typename: "CartProduct";
  name: string;
  qty: number;
  totalAmount: number;
};

export type ModelCallParticipantConnection = {
  __typename: "ModelCallParticipantConnection";
  items: Array<CallParticipant>;
  nextToken?: string | null;
};

export type CallParticipant = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type CallQualification = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type CallReason = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type CallCampaign = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: ModelCallObjectionConnection | null;
  callScriptID?: string | null;
  callScript?: CallScript | null;
  callWidgets?: Array<CallWidget | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelCallObjectionConnection = {
  __typename: "ModelCallObjectionConnection";
  items: Array<CallObjection>;
  nextToken?: string | null;
};

export type CallObjection = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type CallScript = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type CallWidget = {
  __typename: "CallWidget";
  type: string;
  params?: string | null;
};

export type CreateSwitchboardInput = {
  id?: string | null;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  position: number;
  type: string;
};

export type ModelSwitchboardConditionInput = {
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  logo?: ModelStringInput | null;
  welcomeMessage?: ModelStringInput | null;
  hasSeasonalWelcomeMessage?: ModelBooleanInput | null;
  winterWelcomeMessage?: ModelStringInput | null;
  springWelcomeMessage?: ModelStringInput | null;
  automnWelcomeMessage?: ModelStringInput | null;
  summerWelcomeMessage?: ModelStringInput | null;
  waitMusic?: ModelStringInput | null;
  recordingMessage?: ModelStringInput | null;
  closeMessage?: ModelStringInput | null;
  openTime?: ModelStringInput | null;
  closeTime?: ModelStringInput | null;
  connectorType?: ModelStringInput | null;
  connectorId?: ModelStringInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  position?: ModelIntInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelSwitchboardConditionInput | null> | null;
  or?: Array<ModelSwitchboardConditionInput | null> | null;
  not?: ModelSwitchboardConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateSwitchboardInput = {
  id: string;
  name?: string | null;
  phone?: string | null;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  position?: number | null;
  type?: string | null;
};

export type DeleteSwitchboardInput = {
  id: string;
};

export type CreateCallInput = {
  id?: string | null;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  userID?: string | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: CustomerInput | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<OrderInput | null> | null;
  shippings?: Array<ShippingInput | null> | null;
  cart?: CartInput | null;
  callQualificationID?: string | null;
  callReasonID?: string | null;
  interactiveVoiceResponseID?: string | null;
  callCampaignID?: string | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
};

export type CustomerInput = {
  id: number;
  firstname: string;
  lastname: string;
  gender: string;
  email: string;
  blocked: boolean;
  badge: string;
  pareto: string;
  addresses?: Array<AddressInput | null> | null;
  createdAt: string;
};

export type AddressInput = {
  id: number;
  firstname: string;
  lastname: string;
  prefix?: string | null;
  street: string;
  street2?: string | null;
  zipcode: string;
  city: string;
  country: string;
  society?: string | null;
  shippingInformations?: string | null;
  phone: string;
  mobilePhone?: string | null;
};

export type OrderInput = {
  id: number;
  incrementId: string;
  totalAmount: number;
  status: string;
  marketplace?: string | null;
  orderProducts?: Array<OrderProductInput | null> | null;
  createdAt: string;
};

export type OrderProductInput = {
  name: string;
  qty: number;
};

export type ShippingInput = {
  id: number;
  status: string;
  createdAt: string;
  tracking?: string | null;
  shippingMethod: string;
};

export type CartInput = {
  id: number;
  totalAmount: number;
  cartProducts?: Array<CartProductInput | null> | null;
};

export type CartProductInput = {
  name: string;
  qty: number;
  totalAmount: number;
};

export type ModelCallConditionInput = {
  callSid?: ModelStringInput | null;
  from?: ModelStringInput | null;
  to?: ModelStringInput | null;
  direction?: ModelStringInput | null;
  switchboardID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  conferenceName?: ModelStringInput | null;
  status?: ModelStringInput | null;
  pickupAt?: ModelStringInput | null;
  hangupAt?: ModelStringInput | null;
  waitingAt?: ModelStringInput | null;
  completeAt?: ModelStringInput | null;
  callQualificationID?: ModelIDInput | null;
  callReasonID?: ModelIDInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  callCampaignID?: ModelIDInput | null;
  recording?: ModelBooleanInput | null;
  recordingSid?: ModelStringInput | null;
  recordingUrl?: ModelStringInput | null;
  voicemail?: ModelBooleanInput | null;
  voicemailRead?: ModelBooleanInput | null;
  turnOver?: ModelFloatInput | null;
  orderId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCallConditionInput | null> | null;
  or?: Array<ModelCallConditionInput | null> | null;
  not?: ModelCallConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCallInput = {
  id: string;
  callSid?: string | null;
  from?: string | null;
  to?: string | null;
  direction?: string | null;
  switchboardID?: string | null;
  userID?: string | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: CustomerInput | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<OrderInput | null> | null;
  shippings?: Array<ShippingInput | null> | null;
  cart?: CartInput | null;
  callQualificationID?: string | null;
  callReasonID?: string | null;
  interactiveVoiceResponseID?: string | null;
  callCampaignID?: string | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
};

export type DeleteCallInput = {
  id: string;
};

export type CreateCallParticipantInput = {
  id?: string | null;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
};

export type ModelCallParticipantConditionInput = {
  callID?: ModelIDInput | null;
  callSid?: ModelStringInput | null;
  status?: ModelStringInput | null;
  mode?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelCallParticipantConditionInput | null> | null;
  or?: Array<ModelCallParticipantConditionInput | null> | null;
  not?: ModelCallParticipantConditionInput | null;
};

export type UpdateCallParticipantInput = {
  id: string;
  callID?: string | null;
  callSid?: string | null;
  status?: string | null;
  mode?: string | null;
  userID?: string | null;
};

export type DeleteCallParticipantInput = {
  id: string;
};

export type CreateCallQualificationInput = {
  id?: string | null;
  type: string;
  name: string;
  position: number;
};

export type ModelCallQualificationConditionInput = {
  type?: ModelStringInput | null;
  name?: ModelStringInput | null;
  position?: ModelIntInput | null;
  and?: Array<ModelCallQualificationConditionInput | null> | null;
  or?: Array<ModelCallQualificationConditionInput | null> | null;
  not?: ModelCallQualificationConditionInput | null;
};

export type UpdateCallQualificationInput = {
  id: string;
  type?: string | null;
  name?: string | null;
  position?: number | null;
};

export type DeleteCallQualificationInput = {
  id: string;
};

export type CreateCallReasonInput = {
  id?: string | null;
  name: string;
  type: string;
  position: number;
};

export type ModelCallReasonConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  position?: ModelIntInput | null;
  and?: Array<ModelCallReasonConditionInput | null> | null;
  or?: Array<ModelCallReasonConditionInput | null> | null;
  not?: ModelCallReasonConditionInput | null;
};

export type UpdateCallReasonInput = {
  id: string;
  name?: string | null;
  type?: string | null;
  position?: number | null;
};

export type DeleteCallReasonInput = {
  id: string;
};

export type CreateUserInput = {
  id?: string | null;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null;
  admin?: ModelBooleanInput | null;
  slackID?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  workerSid?: ModelStringInput | null;
  activitySid?: ModelStringInput | null;
  available?: ModelBooleanInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  gender?: ModelGenderInput | null;
  voicemailMessage?: ModelStringInput | null;
  taskQueueSid?: ModelStringInput | null;
  workflowSid?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelGenderInput = {
  eq?: Gender | null;
  ne?: Gender | null;
};

export type UpdateUserInput = {
  id: string;
  username?: string | null;
  admin?: boolean | null;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateInteractiveVoiceResponseInput = {
  id?: string | null;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  name: string;
  playText?: string | null;
};

export type ModelInteractiveVoiceResponseConditionInput = {
  play?: ModelStringInput | null;
  digit?: ModelIntInput | null;
  taskQueueSid?: ModelStringInput | null;
  workflowSid?: ModelStringInput | null;
  callReasonID?: ModelIDInput | null;
  parentID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  playText?: ModelStringInput | null;
  and?: Array<ModelInteractiveVoiceResponseConditionInput | null> | null;
  or?: Array<ModelInteractiveVoiceResponseConditionInput | null> | null;
  not?: ModelInteractiveVoiceResponseConditionInput | null;
};

export type UpdateInteractiveVoiceResponseInput = {
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  name?: string | null;
  playText?: string | null;
};

export type DeleteInteractiveVoiceResponseInput = {
  id: string;
};

export type CreateInteractiveVoiceResponseUserInput = {
  id?: string | null;
  userID: string;
  interactiveVoiceResponseID: string;
};

export type ModelInteractiveVoiceResponseUserConditionInput = {
  userID?: ModelIDInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  and?: Array<ModelInteractiveVoiceResponseUserConditionInput | null> | null;
  or?: Array<ModelInteractiveVoiceResponseUserConditionInput | null> | null;
  not?: ModelInteractiveVoiceResponseUserConditionInput | null;
};

export type UpdateInteractiveVoiceResponseUserInput = {
  id: string;
  userID?: string | null;
  interactiveVoiceResponseID?: string | null;
};

export type DeleteInteractiveVoiceResponseUserInput = {
  id: string;
};

export type CreateCallCampaignInput = {
  id?: string | null;
  name: string;
  callScriptID?: string | null;
  callWidgets?: Array<CallWidgetInput | null> | null;
};

export type CallWidgetInput = {
  type: string;
  params?: string | null;
};

export type ModelCallCampaignConditionInput = {
  name?: ModelStringInput | null;
  callScriptID?: ModelIDInput | null;
  and?: Array<ModelCallCampaignConditionInput | null> | null;
  or?: Array<ModelCallCampaignConditionInput | null> | null;
  not?: ModelCallCampaignConditionInput | null;
};

export type UpdateCallCampaignInput = {
  id: string;
  name?: string | null;
  callScriptID?: string | null;
  callWidgets?: Array<CallWidgetInput | null> | null;
};

export type DeleteCallCampaignInput = {
  id: string;
};

export type CreateCallCampaignCustomerInput = {
  id?: string | null;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
};

export type ModelCallCampaignCustomerConditionInput = {
  callCampaignID?: ModelIDInput | null;
  customerId?: ModelIntInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  plannedAt?: ModelStringInput | null;
  calledAt?: ModelStringInput | null;
  callID?: ModelIDInput | null;
  and?: Array<ModelCallCampaignCustomerConditionInput | null> | null;
  or?: Array<ModelCallCampaignCustomerConditionInput | null> | null;
  not?: ModelCallCampaignCustomerConditionInput | null;
};

export type CallCampaignCustomer = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallCampaignCustomerInput = {
  id: string;
  callCampaignID?: string | null;
  customerId?: number | null;
  name?: string | null;
  status?: string | null;
  phone?: string | null;
  plannedAt?: string | null;
  calledAt?: string | null;
  callID?: string | null;
};

export type DeleteCallCampaignCustomerInput = {
  id: string;
};

export type CreateCallObjectionInput = {
  id?: string | null;
  callCampaignID: string;
  title: string;
  answer: string;
};

export type ModelCallObjectionConditionInput = {
  callCampaignID?: ModelIDInput | null;
  title?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  and?: Array<ModelCallObjectionConditionInput | null> | null;
  or?: Array<ModelCallObjectionConditionInput | null> | null;
  not?: ModelCallObjectionConditionInput | null;
};

export type UpdateCallObjectionInput = {
  id: string;
  callCampaignID?: string | null;
  title?: string | null;
  answer?: string | null;
};

export type DeleteCallObjectionInput = {
  id: string;
};

export type CreateCallScriptInput = {
  id?: string | null;
  name: string;
  text: string;
  callCampaignID: string;
};

export type ModelCallScriptConditionInput = {
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  callCampaignID?: ModelIDInput | null;
  and?: Array<ModelCallScriptConditionInput | null> | null;
  or?: Array<ModelCallScriptConditionInput | null> | null;
  not?: ModelCallScriptConditionInput | null;
};

export type UpdateCallScriptInput = {
  id: string;
  name?: string | null;
  text?: string | null;
  callCampaignID?: string | null;
};

export type DeleteCallScriptInput = {
  id: string;
};

export type CreateCallScriptRelationInput = {
  id?: string | null;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
};

export type ModelCallScriptRelationConditionInput = {
  answer?: ModelStringInput | null;
  sourceCallScriptID?: ModelIDInput | null;
  targetCallScriptID?: ModelIDInput | null;
  and?: Array<ModelCallScriptRelationConditionInput | null> | null;
  or?: Array<ModelCallScriptRelationConditionInput | null> | null;
  not?: ModelCallScriptRelationConditionInput | null;
};

export type CallScriptRelation = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallScriptRelationInput = {
  id: string;
  answer?: string | null;
  sourceCallScriptID?: string | null;
  targetCallScriptID?: string | null;
};

export type DeleteCallScriptRelationInput = {
  id: string;
};

export type CreateContactInput = {
  id?: string | null;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
};

export type ModelContactConditionInput = {
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  search?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelContactConditionInput | null> | null;
  or?: Array<ModelContactConditionInput | null> | null;
  not?: ModelContactConditionInput | null;
};

export type Contact = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactInput = {
  id: string;
  firstname?: string | null;
  lastname?: string | null;
  search?: string | null;
  email?: string | null;
  phone?: string | null;
  userID?: string | null;
};

export type DeleteContactInput = {
  id: string;
};

export type CreateTicketBridgeInput = {
  id?: string | null;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
};

export type ModelTicketBridgeConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  password?: ModelStringInput | null;
  host?: ModelStringInput | null;
  port?: ModelIntInput | null;
  connectorType?: ModelStringInput | null;
  connectorId?: ModelStringInput | null;
  and?: Array<ModelTicketBridgeConditionInput | null> | null;
  or?: Array<ModelTicketBridgeConditionInput | null> | null;
  not?: ModelTicketBridgeConditionInput | null;
};

export type TicketBridge = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketBridgeInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  password?: string | null;
  host?: string | null;
  port?: number | null;
  connectorType?: string | null;
  connectorId?: string | null;
};

export type DeleteTicketBridgeInput = {
  id: string;
};

export type CreateTicketInput = {
  id?: string | null;
  email: string;
  subject?: string | null;
};

export type ModelTicketConditionInput = {
  email?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  and?: Array<ModelTicketConditionInput | null> | null;
  or?: Array<ModelTicketConditionInput | null> | null;
  not?: ModelTicketConditionInput | null;
};

export type Ticket = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: ModelTicketMessageConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelTicketMessageConnection = {
  __typename: "ModelTicketMessageConnection";
  items: Array<TicketMessage>;
  nextToken?: string | null;
};

export type TicketMessage = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketInput = {
  id: string;
  email?: string | null;
  subject?: string | null;
};

export type DeleteTicketInput = {
  id: string;
};

export type CreateTicketQuickAnswerInput = {
  id?: string | null;
  name: string;
  message: string;
};

export type ModelTicketQuickAnswerConditionInput = {
  name?: ModelStringInput | null;
  message?: ModelStringInput | null;
  and?: Array<ModelTicketQuickAnswerConditionInput | null> | null;
  or?: Array<ModelTicketQuickAnswerConditionInput | null> | null;
  not?: ModelTicketQuickAnswerConditionInput | null;
};

export type TicketQuickAnswer = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketQuickAnswerInput = {
  id: string;
  name?: string | null;
  message?: string | null;
};

export type DeleteTicketQuickAnswerInput = {
  id: string;
};

export type CreateTicketMessageInput = {
  id?: string | null;
  ticketID: string;
  email: string;
  text: string;
  html: string;
};

export type ModelTicketMessageConditionInput = {
  ticketID?: ModelIDInput | null;
  email?: ModelStringInput | null;
  text?: ModelStringInput | null;
  html?: ModelStringInput | null;
  and?: Array<ModelTicketMessageConditionInput | null> | null;
  or?: Array<ModelTicketMessageConditionInput | null> | null;
  not?: ModelTicketMessageConditionInput | null;
};

export type UpdateTicketMessageInput = {
  id: string;
  ticketID?: string | null;
  email?: string | null;
  text?: string | null;
  html?: string | null;
};

export type DeleteTicketMessageInput = {
  id: string;
};

export type CreateTicketDomainInput = {
  id?: string | null;
  name: string;
};

export type ModelTicketDomainConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelTicketDomainConditionInput | null> | null;
  or?: Array<ModelTicketDomainConditionInput | null> | null;
  not?: ModelTicketDomainConditionInput | null;
};

export type TicketDomain = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: ModelTicketSubDomainConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelTicketSubDomainConnection = {
  __typename: "ModelTicketSubDomainConnection";
  items: Array<TicketSubDomain>;
  nextToken?: string | null;
};

export type TicketSubDomain = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: ModelTicketQuickAnswerTicketSubDomainConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelTicketQuickAnswerTicketSubDomainConnection = {
  __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
  items: Array<TicketQuickAnswerTicketSubDomain>;
  nextToken?: string | null;
};

export type TicketQuickAnswerTicketSubDomain = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: TicketQuickAnswer;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketDomainInput = {
  id: string;
  name?: string | null;
};

export type DeleteTicketDomainInput = {
  id: string;
};

export type CreateTicketSubDomainInput = {
  id?: string | null;
  ticketDomainID: string;
  name: string;
};

export type ModelTicketSubDomainConditionInput = {
  ticketDomainID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelTicketSubDomainConditionInput | null> | null;
  or?: Array<ModelTicketSubDomainConditionInput | null> | null;
  not?: ModelTicketSubDomainConditionInput | null;
};

export type UpdateTicketSubDomainInput = {
  id: string;
  ticketDomainID?: string | null;
  name?: string | null;
};

export type DeleteTicketSubDomainInput = {
  id: string;
};

export type CreateTicketQuickAnswerTicketSubDomainInput = {
  id?: string | null;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
};

export type ModelTicketQuickAnswerTicketSubDomainConditionInput = {
  ticketQuickAnswerID?: ModelIDInput | null;
  ticketSubDomainID?: ModelIDInput | null;
  and?: Array<ModelTicketQuickAnswerTicketSubDomainConditionInput | null> | null;
  or?: Array<ModelTicketQuickAnswerTicketSubDomainConditionInput | null> | null;
  not?: ModelTicketQuickAnswerTicketSubDomainConditionInput | null;
};

export type UpdateTicketQuickAnswerTicketSubDomainInput = {
  id: string;
  ticketQuickAnswerID?: string | null;
  ticketSubDomainID?: string | null;
};

export type DeleteTicketQuickAnswerTicketSubDomainInput = {
  id: string;
};

export type CreateTicketActionInput = {
  id?: string | null;
  name: string;
};

export type ModelTicketActionConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelTicketActionConditionInput | null> | null;
  or?: Array<ModelTicketActionConditionInput | null> | null;
  not?: ModelTicketActionConditionInput | null;
};

export type TicketAction = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketActionInput = {
  id: string;
  name?: string | null;
};

export type DeleteTicketActionInput = {
  id: string;
};

export type ModelSwitchboardFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  logo?: ModelStringInput | null;
  welcomeMessage?: ModelStringInput | null;
  hasSeasonalWelcomeMessage?: ModelBooleanInput | null;
  winterWelcomeMessage?: ModelStringInput | null;
  springWelcomeMessage?: ModelStringInput | null;
  automnWelcomeMessage?: ModelStringInput | null;
  summerWelcomeMessage?: ModelStringInput | null;
  waitMusic?: ModelStringInput | null;
  recordingMessage?: ModelStringInput | null;
  closeMessage?: ModelStringInput | null;
  openTime?: ModelStringInput | null;
  closeTime?: ModelStringInput | null;
  connectorType?: ModelStringInput | null;
  connectorId?: ModelStringInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  position?: ModelIntInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelSwitchboardFilterInput | null> | null;
  or?: Array<ModelSwitchboardFilterInput | null> | null;
  not?: ModelSwitchboardFilterInput | null;
};

export type ModelSwitchboardConnection = {
  __typename: "ModelSwitchboardConnection";
  items: Array<Switchboard>;
  nextToken?: string | null;
};

export type ModelCallFilterInput = {
  id?: ModelIDInput | null;
  callSid?: ModelStringInput | null;
  from?: ModelStringInput | null;
  to?: ModelStringInput | null;
  direction?: ModelStringInput | null;
  switchboardID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  conferenceName?: ModelStringInput | null;
  status?: ModelStringInput | null;
  pickupAt?: ModelStringInput | null;
  hangupAt?: ModelStringInput | null;
  waitingAt?: ModelStringInput | null;
  completeAt?: ModelStringInput | null;
  callQualificationID?: ModelIDInput | null;
  callReasonID?: ModelIDInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  callCampaignID?: ModelIDInput | null;
  recording?: ModelBooleanInput | null;
  recordingSid?: ModelStringInput | null;
  recordingUrl?: ModelStringInput | null;
  voicemail?: ModelBooleanInput | null;
  voicemailRead?: ModelBooleanInput | null;
  turnOver?: ModelFloatInput | null;
  orderId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCallFilterInput | null> | null;
  or?: Array<ModelCallFilterInput | null> | null;
  not?: ModelCallFilterInput | null;
};

export type ModelCallParticipantFilterInput = {
  id?: ModelIDInput | null;
  callID?: ModelIDInput | null;
  callSid?: ModelStringInput | null;
  status?: ModelStringInput | null;
  mode?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelCallParticipantFilterInput | null> | null;
  or?: Array<ModelCallParticipantFilterInput | null> | null;
  not?: ModelCallParticipantFilterInput | null;
};

export type ModelCallQualificationFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  name?: ModelStringInput | null;
  position?: ModelIntInput | null;
  and?: Array<ModelCallQualificationFilterInput | null> | null;
  or?: Array<ModelCallQualificationFilterInput | null> | null;
  not?: ModelCallQualificationFilterInput | null;
};

export type ModelCallQualificationConnection = {
  __typename: "ModelCallQualificationConnection";
  items: Array<CallQualification>;
  nextToken?: string | null;
};

export type ModelCallReasonFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  position?: ModelIntInput | null;
  and?: Array<ModelCallReasonFilterInput | null> | null;
  or?: Array<ModelCallReasonFilterInput | null> | null;
  not?: ModelCallReasonFilterInput | null;
};

export type ModelCallReasonConnection = {
  __typename: "ModelCallReasonConnection";
  items: Array<CallReason>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  admin?: ModelBooleanInput | null;
  slackID?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  workerSid?: ModelStringInput | null;
  activitySid?: ModelStringInput | null;
  available?: ModelBooleanInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  avatar?: ModelStringInput | null;
  gender?: ModelGenderInput | null;
  voicemailMessage?: ModelStringInput | null;
  taskQueueSid?: ModelStringInput | null;
  workflowSid?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User>;
  nextToken?: string | null;
};

export type ModelInteractiveVoiceResponseFilterInput = {
  id?: ModelIDInput | null;
  play?: ModelStringInput | null;
  digit?: ModelIntInput | null;
  taskQueueSid?: ModelStringInput | null;
  workflowSid?: ModelStringInput | null;
  callReasonID?: ModelIDInput | null;
  parentID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  playText?: ModelStringInput | null;
  and?: Array<ModelInteractiveVoiceResponseFilterInput | null> | null;
  or?: Array<ModelInteractiveVoiceResponseFilterInput | null> | null;
  not?: ModelInteractiveVoiceResponseFilterInput | null;
};

export type ModelInteractiveVoiceResponseConnection = {
  __typename: "ModelInteractiveVoiceResponseConnection";
  items: Array<InteractiveVoiceResponse>;
  nextToken?: string | null;
};

export type ModelInteractiveVoiceResponseUserFilterInput = {
  id?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  interactiveVoiceResponseID?: ModelIDInput | null;
  and?: Array<ModelInteractiveVoiceResponseUserFilterInput | null> | null;
  or?: Array<ModelInteractiveVoiceResponseUserFilterInput | null> | null;
  not?: ModelInteractiveVoiceResponseUserFilterInput | null;
};

export type ModelCallCampaignFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  callScriptID?: ModelIDInput | null;
  and?: Array<ModelCallCampaignFilterInput | null> | null;
  or?: Array<ModelCallCampaignFilterInput | null> | null;
  not?: ModelCallCampaignFilterInput | null;
};

export type ModelCallCampaignConnection = {
  __typename: "ModelCallCampaignConnection";
  items: Array<CallCampaign>;
  nextToken?: string | null;
};

export type ModelCallCampaignCustomerFilterInput = {
  id?: ModelIDInput | null;
  callCampaignID?: ModelIDInput | null;
  customerId?: ModelIntInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  plannedAt?: ModelStringInput | null;
  calledAt?: ModelStringInput | null;
  callID?: ModelIDInput | null;
  and?: Array<ModelCallCampaignCustomerFilterInput | null> | null;
  or?: Array<ModelCallCampaignCustomerFilterInput | null> | null;
  not?: ModelCallCampaignCustomerFilterInput | null;
};

export type ModelCallCampaignCustomerConnection = {
  __typename: "ModelCallCampaignCustomerConnection";
  items: Array<CallCampaignCustomer>;
  nextToken?: string | null;
};

export type ModelCallObjectionFilterInput = {
  id?: ModelIDInput | null;
  callCampaignID?: ModelIDInput | null;
  title?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  and?: Array<ModelCallObjectionFilterInput | null> | null;
  or?: Array<ModelCallObjectionFilterInput | null> | null;
  not?: ModelCallObjectionFilterInput | null;
};

export type ModelCallScriptFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  callCampaignID?: ModelIDInput | null;
  and?: Array<ModelCallScriptFilterInput | null> | null;
  or?: Array<ModelCallScriptFilterInput | null> | null;
  not?: ModelCallScriptFilterInput | null;
};

export type ModelCallScriptConnection = {
  __typename: "ModelCallScriptConnection";
  items: Array<CallScript>;
  nextToken?: string | null;
};

export type ModelCallScriptRelationFilterInput = {
  id?: ModelIDInput | null;
  answer?: ModelStringInput | null;
  sourceCallScriptID?: ModelIDInput | null;
  targetCallScriptID?: ModelIDInput | null;
  and?: Array<ModelCallScriptRelationFilterInput | null> | null;
  or?: Array<ModelCallScriptRelationFilterInput | null> | null;
  not?: ModelCallScriptRelationFilterInput | null;
};

export type ModelCallScriptRelationConnection = {
  __typename: "ModelCallScriptRelationConnection";
  items: Array<CallScriptRelation>;
  nextToken?: string | null;
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  search?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelContactFilterInput | null> | null;
  or?: Array<ModelContactFilterInput | null> | null;
  not?: ModelContactFilterInput | null;
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection";
  items: Array<Contact>;
  nextToken?: string | null;
};

export type ModelTicketBridgeFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  password?: ModelStringInput | null;
  host?: ModelStringInput | null;
  port?: ModelIntInput | null;
  connectorType?: ModelStringInput | null;
  connectorId?: ModelStringInput | null;
  and?: Array<ModelTicketBridgeFilterInput | null> | null;
  or?: Array<ModelTicketBridgeFilterInput | null> | null;
  not?: ModelTicketBridgeFilterInput | null;
};

export type ModelTicketBridgeConnection = {
  __typename: "ModelTicketBridgeConnection";
  items: Array<TicketBridge>;
  nextToken?: string | null;
};

export type ModelTicketFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  and?: Array<ModelTicketFilterInput | null> | null;
  or?: Array<ModelTicketFilterInput | null> | null;
  not?: ModelTicketFilterInput | null;
};

export type ModelTicketConnection = {
  __typename: "ModelTicketConnection";
  items: Array<Ticket>;
  nextToken?: string | null;
};

export type ModelTicketQuickAnswerFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  message?: ModelStringInput | null;
  and?: Array<ModelTicketQuickAnswerFilterInput | null> | null;
  or?: Array<ModelTicketQuickAnswerFilterInput | null> | null;
  not?: ModelTicketQuickAnswerFilterInput | null;
};

export type ModelTicketQuickAnswerConnection = {
  __typename: "ModelTicketQuickAnswerConnection";
  items: Array<TicketQuickAnswer>;
  nextToken?: string | null;
};

export type ModelTicketMessageFilterInput = {
  id?: ModelIDInput | null;
  ticketID?: ModelIDInput | null;
  email?: ModelStringInput | null;
  text?: ModelStringInput | null;
  html?: ModelStringInput | null;
  and?: Array<ModelTicketMessageFilterInput | null> | null;
  or?: Array<ModelTicketMessageFilterInput | null> | null;
  not?: ModelTicketMessageFilterInput | null;
};

export type ModelTicketDomainFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelTicketDomainFilterInput | null> | null;
  or?: Array<ModelTicketDomainFilterInput | null> | null;
  not?: ModelTicketDomainFilterInput | null;
};

export type ModelTicketDomainConnection = {
  __typename: "ModelTicketDomainConnection";
  items: Array<TicketDomain>;
  nextToken?: string | null;
};

export type ModelTicketSubDomainFilterInput = {
  id?: ModelIDInput | null;
  ticketDomainID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelTicketSubDomainFilterInput | null> | null;
  or?: Array<ModelTicketSubDomainFilterInput | null> | null;
  not?: ModelTicketSubDomainFilterInput | null;
};

export type ModelTicketQuickAnswerTicketSubDomainFilterInput = {
  id?: ModelIDInput | null;
  ticketQuickAnswerID?: ModelIDInput | null;
  ticketSubDomainID?: ModelIDInput | null;
  and?: Array<ModelTicketQuickAnswerTicketSubDomainFilterInput | null> | null;
  or?: Array<ModelTicketQuickAnswerTicketSubDomainFilterInput | null> | null;
  not?: ModelTicketQuickAnswerTicketSubDomainFilterInput | null;
};

export type ModelTicketActionFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelTicketActionFilterInput | null> | null;
  or?: Array<ModelTicketActionFilterInput | null> | null;
  not?: ModelTicketActionFilterInput | null;
};

export type ModelTicketActionConnection = {
  __typename: "ModelTicketActionConnection";
  items: Array<TicketAction>;
  nextToken?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ListCallsBisQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
  }>;
};

export type CreateSwitchboardMutation = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSwitchboardMutation = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSwitchboardMutation = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallMutation = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateCallMutation = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type DeleteCallMutation = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type CreateCallParticipantMutation = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallParticipantMutation = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallParticipantMutation = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallQualificationMutation = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallQualificationMutation = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallQualificationMutation = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallReasonMutation = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallReasonMutation = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallReasonMutation = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateInteractiveVoiceResponseMutation = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateInteractiveVoiceResponseMutation = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteInteractiveVoiceResponseMutation = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateInteractiveVoiceResponseUserMutation = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type UpdateInteractiveVoiceResponseUserMutation = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DeleteInteractiveVoiceResponseUserMutation = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type CreateCallCampaignMutation = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallCampaignMutation = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallCampaignMutation = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallCampaignCustomerMutation = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallCampaignCustomerMutation = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallCampaignCustomerMutation = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallObjectionMutation = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallObjectionMutation = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallObjectionMutation = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallScriptMutation = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallScriptMutation = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallScriptMutation = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallScriptRelationMutation = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCallScriptRelationMutation = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCallScriptRelationMutation = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateContactMutation = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactMutation = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteContactMutation = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketBridgeMutation = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketBridgeMutation = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketBridgeMutation = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketMutation = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketMutation = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketMutation = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketQuickAnswerMutation = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketQuickAnswerMutation = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketQuickAnswerMutation = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketMessageMutation = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketMessageMutation = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketMessageMutation = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketDomainMutation = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketDomainMutation = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketDomainMutation = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketSubDomainMutation = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketSubDomainMutation = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketSubDomainMutation = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketQuickAnswerTicketSubDomainMutation = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketQuickAnswerTicketSubDomainMutation = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketQuickAnswerTicketSubDomainMutation = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type CreateTicketActionMutation = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTicketActionMutation = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTicketActionMutation = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type GetSwitchboardQuery = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type ListSwitchboardsQuery = {
  __typename: "ModelSwitchboardConnection";
  items: Array<{
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallQuery = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type ListCallsQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    callSid: string;
    from: string;
    to: string;
    direction: string;
    switchboardID?: string | null;
    switchboard?: {
      __typename: "Switchboard";
      id: string;
      name: string;
      phone: string;
      logo?: string | null;
      welcomeMessage?: string | null;
      hasSeasonalWelcomeMessage?: boolean | null;
      winterWelcomeMessage?: string | null;
      springWelcomeMessage?: string | null;
      automnWelcomeMessage?: string | null;
      summerWelcomeMessage?: string | null;
      waitMusic?: string | null;
      recordingMessage?: string | null;
      closeMessage?: string | null;
      openTime?: string | null;
      closeTime?: string | null;
      connectorType?: string | null;
      connectorId?: string | null;
      interactiveVoiceResponseID?: string | null;
      interactiveVoiceResponse?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      position: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    conferenceName?: string | null;
    status?: string | null;
    customer?: {
      __typename: "Customer";
      id: number;
      firstname: string;
      lastname: string;
      gender: string;
      email: string;
      blocked: boolean;
      badge: string;
      pareto: string;
      addresses?: Array<{
        __typename: "Address";
        id: number;
        firstname: string;
        lastname: string;
        prefix?: string | null;
        street: string;
        street2?: string | null;
        zipcode: string;
        city: string;
        country: string;
        society?: string | null;
        shippingInformations?: string | null;
        phone: string;
        mobilePhone?: string | null;
      } | null> | null;
      createdAt: string;
    } | null;
    pickupAt?: string | null;
    hangupAt?: string | null;
    waitingAt?: string | null;
    completeAt?: string | null;
    orders?: Array<{
      __typename: "Order";
      id: number;
      incrementId: string;
      totalAmount: number;
      status: string;
      marketplace?: string | null;
      orderProducts?: Array<{
        __typename: "OrderProduct";
        name: string;
        qty: number;
      } | null> | null;
      createdAt: string;
    } | null> | null;
    shippings?: Array<{
      __typename: "Shipping";
      id: number;
      status: string;
      createdAt: string;
      tracking?: string | null;
      shippingMethod: string;
    } | null> | null;
    cart?: {
      __typename: "Cart";
      id: number;
      totalAmount: number;
      cartProducts?: Array<{
        __typename: "CartProduct";
        name: string;
        qty: number;
        totalAmount: number;
      } | null> | null;
    } | null;
    callParticipants?: {
      __typename: "ModelCallParticipantConnection";
      items: Array<{
        __typename: "CallParticipant";
        id: string;
        callID: string;
        callSid: string;
        status: string;
        mode: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callQualificationID?: string | null;
    callQualification?: {
      __typename: "CallQualification";
      id: string;
      type: string;
      name: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    callReasonID?: string | null;
    callReason?: {
      __typename: "CallReason";
      id: string;
      name: string;
      type: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    callCampaignID?: string | null;
    callCampaign?: {
      __typename: "CallCampaign";
      id: string;
      name: string;
      objections?: {
        __typename: "ModelCallObjectionConnection";
        nextToken?: string | null;
      } | null;
      callScriptID?: string | null;
      callScript?: {
        __typename: "CallScript";
        id: string;
        name: string;
        text: string;
        callCampaignID: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      callWidgets?: Array<{
        __typename: "CallWidget";
        type: string;
        params?: string | null;
      } | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    recording?: boolean | null;
    recordingSid?: string | null;
    recordingUrl?: string | null;
    voicemail?: boolean | null;
    voicemailRead?: boolean | null;
    turnOver?: number | null;
    orderId?: string | null;
    date?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallParticipantQuery = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCallParticipantsQuery = {
  __typename: "ModelCallParticipantConnection";
  items: Array<{
    __typename: "CallParticipant";
    id: string;
    callID: string;
    callSid: string;
    status: string;
    mode: string;
    userID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallQualificationQuery = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type ListCallQualificationsQuery = {
  __typename: "ModelCallQualificationConnection";
  items: Array<{
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallReasonQuery = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type ListCallReasonsQuery = {
  __typename: "ModelCallReasonConnection";
  items: Array<{
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetInteractiveVoiceResponseQuery = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListInteractiveVoiceResponsesQuery = {
  __typename: "ModelInteractiveVoiceResponseConnection";
  items: Array<{
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetInteractiveVoiceResponseUserQuery = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ListInteractiveVoiceResponseUsersQuery = {
  __typename: "ModelInteractiveVoiceResponseUserConnection";
  items: Array<{
    __typename: "InteractiveVoiceResponseUser";
    id: string;
    userID: string;
    interactiveVoiceResponseID: string;
    user: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    interactiveVoiceResponse: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallCampaignQuery = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCallCampaignsQuery = {
  __typename: "ModelCallCampaignConnection";
  items: Array<{
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallCampaignCustomerQuery = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCallCampaignCustomersQuery = {
  __typename: "ModelCallCampaignCustomerConnection";
  items: Array<{
    __typename: "CallCampaignCustomer";
    id: string;
    callCampaignID: string;
    customerId: number;
    name: string;
    status: string;
    phone: string;
    plannedAt: string;
    calledAt?: string | null;
    callID?: string | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallObjectionQuery = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCallObjectionsQuery = {
  __typename: "ModelCallObjectionConnection";
  items: Array<{
    __typename: "CallObjection";
    id: string;
    callCampaignID: string;
    title: string;
    answer: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallScriptQuery = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCallScriptsQuery = {
  __typename: "ModelCallScriptConnection";
  items: Array<{
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetCallScriptRelationQuery = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCallScriptRelationsQuery = {
  __typename: "ModelCallScriptRelationConnection";
  items: Array<{
    __typename: "CallScriptRelation";
    id: string;
    answer?: string | null;
    sourceCallScriptID: string;
    targetCallScriptID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetContactQuery = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type ListContactsQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    firstname: string;
    lastname: string;
    search: string;
    email?: string | null;
    phone?: string | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketBridgeQuery = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketBridgesQuery = {
  __typename: "ModelTicketBridgeConnection";
  items: Array<{
    __typename: "TicketBridge";
    id: string;
    name: string;
    email: string;
    password: string;
    host: string;
    port: number;
    connectorType?: string | null;
    connectorId?: string | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketQuery = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketsQuery = {
  __typename: "ModelTicketConnection";
  items: Array<{
    __typename: "Ticket";
    id: string;
    email: string;
    subject?: string | null;
    messages?: {
      __typename: "ModelTicketMessageConnection";
      items: Array<{
        __typename: "TicketMessage";
        id: string;
        ticketID: string;
        email: string;
        text: string;
        html: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketQuickAnswerQuery = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketQuickAnswersQuery = {
  __typename: "ModelTicketQuickAnswerConnection";
  items: Array<{
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketMessageQuery = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketMessagesQuery = {
  __typename: "ModelTicketMessageConnection";
  items: Array<{
    __typename: "TicketMessage";
    id: string;
    ticketID: string;
    email: string;
    text: string;
    html: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketDomainQuery = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketDomainsQuery = {
  __typename: "ModelTicketDomainConnection";
  items: Array<{
    __typename: "TicketDomain";
    id: string;
    name: string;
    subdomains?: {
      __typename: "ModelTicketSubDomainConnection";
      items: Array<{
        __typename: "TicketSubDomain";
        id: string;
        ticketDomainID: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketSubDomainQuery = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketSubDomainsQuery = {
  __typename: "ModelTicketSubDomainConnection";
  items: Array<{
    __typename: "TicketSubDomain";
    id: string;
    ticketDomainID: string;
    name: string;
    ticketQuickAnswers?: {
      __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
      items: Array<{
        __typename: "TicketQuickAnswerTicketSubDomain";
        id: string;
        ticketQuickAnswerID: string;
        ticketSubDomainID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketQuickAnswerTicketSubDomainQuery = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ListTicketQuickAnswerTicketSubDomainsQuery = {
  __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
  items: Array<{
    __typename: "TicketQuickAnswerTicketSubDomain";
    id: string;
    ticketQuickAnswerID: string;
    ticketSubDomainID: string;
    ticketQuickAnswer: {
      __typename: "TicketQuickAnswer";
      id: string;
      name: string;
      message: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type GetTicketActionQuery = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTicketActionsQuery = {
  __typename: "ModelTicketActionConnection";
  items: Array<{
    __typename: "TicketAction";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type SwitchboardByPhoneQuery = {
  __typename: "ModelSwitchboardConnection";
  items: Array<{
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type SwitchboardByPositionQuery = {
  __typename: "ModelSwitchboardConnection";
  items: Array<{
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallByCallSidQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    callSid: string;
    from: string;
    to: string;
    direction: string;
    switchboardID?: string | null;
    switchboard?: {
      __typename: "Switchboard";
      id: string;
      name: string;
      phone: string;
      logo?: string | null;
      welcomeMessage?: string | null;
      hasSeasonalWelcomeMessage?: boolean | null;
      winterWelcomeMessage?: string | null;
      springWelcomeMessage?: string | null;
      automnWelcomeMessage?: string | null;
      summerWelcomeMessage?: string | null;
      waitMusic?: string | null;
      recordingMessage?: string | null;
      closeMessage?: string | null;
      openTime?: string | null;
      closeTime?: string | null;
      connectorType?: string | null;
      connectorId?: string | null;
      interactiveVoiceResponseID?: string | null;
      interactiveVoiceResponse?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      position: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    conferenceName?: string | null;
    status?: string | null;
    customer?: {
      __typename: "Customer";
      id: number;
      firstname: string;
      lastname: string;
      gender: string;
      email: string;
      blocked: boolean;
      badge: string;
      pareto: string;
      addresses?: Array<{
        __typename: "Address";
        id: number;
        firstname: string;
        lastname: string;
        prefix?: string | null;
        street: string;
        street2?: string | null;
        zipcode: string;
        city: string;
        country: string;
        society?: string | null;
        shippingInformations?: string | null;
        phone: string;
        mobilePhone?: string | null;
      } | null> | null;
      createdAt: string;
    } | null;
    pickupAt?: string | null;
    hangupAt?: string | null;
    waitingAt?: string | null;
    completeAt?: string | null;
    orders?: Array<{
      __typename: "Order";
      id: number;
      incrementId: string;
      totalAmount: number;
      status: string;
      marketplace?: string | null;
      orderProducts?: Array<{
        __typename: "OrderProduct";
        name: string;
        qty: number;
      } | null> | null;
      createdAt: string;
    } | null> | null;
    shippings?: Array<{
      __typename: "Shipping";
      id: number;
      status: string;
      createdAt: string;
      tracking?: string | null;
      shippingMethod: string;
    } | null> | null;
    cart?: {
      __typename: "Cart";
      id: number;
      totalAmount: number;
      cartProducts?: Array<{
        __typename: "CartProduct";
        name: string;
        qty: number;
        totalAmount: number;
      } | null> | null;
    } | null;
    callParticipants?: {
      __typename: "ModelCallParticipantConnection";
      items: Array<{
        __typename: "CallParticipant";
        id: string;
        callID: string;
        callSid: string;
        status: string;
        mode: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callQualificationID?: string | null;
    callQualification?: {
      __typename: "CallQualification";
      id: string;
      type: string;
      name: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    callReasonID?: string | null;
    callReason?: {
      __typename: "CallReason";
      id: string;
      name: string;
      type: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    callCampaignID?: string | null;
    callCampaign?: {
      __typename: "CallCampaign";
      id: string;
      name: string;
      objections?: {
        __typename: "ModelCallObjectionConnection";
        nextToken?: string | null;
      } | null;
      callScriptID?: string | null;
      callScript?: {
        __typename: "CallScript";
        id: string;
        name: string;
        text: string;
        callCampaignID: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      callWidgets?: Array<{
        __typename: "CallWidget";
        type: string;
        params?: string | null;
      } | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    recording?: boolean | null;
    recordingSid?: string | null;
    recordingUrl?: string | null;
    voicemail?: boolean | null;
    voicemailRead?: boolean | null;
    turnOver?: number | null;
    orderId?: string | null;
    date?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallByStatusAndUserQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    callSid: string;
    from: string;
    to: string;
    direction: string;
    switchboardID?: string | null;
    switchboard?: {
      __typename: "Switchboard";
      id: string;
      name: string;
      phone: string;
      logo?: string | null;
      welcomeMessage?: string | null;
      hasSeasonalWelcomeMessage?: boolean | null;
      winterWelcomeMessage?: string | null;
      springWelcomeMessage?: string | null;
      automnWelcomeMessage?: string | null;
      summerWelcomeMessage?: string | null;
      waitMusic?: string | null;
      recordingMessage?: string | null;
      closeMessage?: string | null;
      openTime?: string | null;
      closeTime?: string | null;
      connectorType?: string | null;
      connectorId?: string | null;
      interactiveVoiceResponseID?: string | null;
      interactiveVoiceResponse?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      position: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    conferenceName?: string | null;
    status?: string | null;
    customer?: {
      __typename: "Customer";
      id: number;
      firstname: string;
      lastname: string;
      gender: string;
      email: string;
      blocked: boolean;
      badge: string;
      pareto: string;
      addresses?: Array<{
        __typename: "Address";
        id: number;
        firstname: string;
        lastname: string;
        prefix?: string | null;
        street: string;
        street2?: string | null;
        zipcode: string;
        city: string;
        country: string;
        society?: string | null;
        shippingInformations?: string | null;
        phone: string;
        mobilePhone?: string | null;
      } | null> | null;
      createdAt: string;
    } | null;
    pickupAt?: string | null;
    hangupAt?: string | null;
    waitingAt?: string | null;
    completeAt?: string | null;
    orders?: Array<{
      __typename: "Order";
      id: number;
      incrementId: string;
      totalAmount: number;
      status: string;
      marketplace?: string | null;
      orderProducts?: Array<{
        __typename: "OrderProduct";
        name: string;
        qty: number;
      } | null> | null;
      createdAt: string;
    } | null> | null;
    shippings?: Array<{
      __typename: "Shipping";
      id: number;
      status: string;
      createdAt: string;
      tracking?: string | null;
      shippingMethod: string;
    } | null> | null;
    cart?: {
      __typename: "Cart";
      id: number;
      totalAmount: number;
      cartProducts?: Array<{
        __typename: "CartProduct";
        name: string;
        qty: number;
        totalAmount: number;
      } | null> | null;
    } | null;
    callParticipants?: {
      __typename: "ModelCallParticipantConnection";
      items: Array<{
        __typename: "CallParticipant";
        id: string;
        callID: string;
        callSid: string;
        status: string;
        mode: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callQualificationID?: string | null;
    callQualification?: {
      __typename: "CallQualification";
      id: string;
      type: string;
      name: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    callReasonID?: string | null;
    callReason?: {
      __typename: "CallReason";
      id: string;
      name: string;
      type: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    callCampaignID?: string | null;
    callCampaign?: {
      __typename: "CallCampaign";
      id: string;
      name: string;
      objections?: {
        __typename: "ModelCallObjectionConnection";
        nextToken?: string | null;
      } | null;
      callScriptID?: string | null;
      callScript?: {
        __typename: "CallScript";
        id: string;
        name: string;
        text: string;
        callCampaignID: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      callWidgets?: Array<{
        __typename: "CallWidget";
        type: string;
        params?: string | null;
      } | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    recording?: boolean | null;
    recordingSid?: string | null;
    recordingUrl?: string | null;
    voicemail?: boolean | null;
    voicemailRead?: boolean | null;
    turnOver?: number | null;
    orderId?: string | null;
    date?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallByConferenceNameQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    callSid: string;
    from: string;
    to: string;
    direction: string;
    switchboardID?: string | null;
    switchboard?: {
      __typename: "Switchboard";
      id: string;
      name: string;
      phone: string;
      logo?: string | null;
      welcomeMessage?: string | null;
      hasSeasonalWelcomeMessage?: boolean | null;
      winterWelcomeMessage?: string | null;
      springWelcomeMessage?: string | null;
      automnWelcomeMessage?: string | null;
      summerWelcomeMessage?: string | null;
      waitMusic?: string | null;
      recordingMessage?: string | null;
      closeMessage?: string | null;
      openTime?: string | null;
      closeTime?: string | null;
      connectorType?: string | null;
      connectorId?: string | null;
      interactiveVoiceResponseID?: string | null;
      interactiveVoiceResponse?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      position: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    conferenceName?: string | null;
    status?: string | null;
    customer?: {
      __typename: "Customer";
      id: number;
      firstname: string;
      lastname: string;
      gender: string;
      email: string;
      blocked: boolean;
      badge: string;
      pareto: string;
      addresses?: Array<{
        __typename: "Address";
        id: number;
        firstname: string;
        lastname: string;
        prefix?: string | null;
        street: string;
        street2?: string | null;
        zipcode: string;
        city: string;
        country: string;
        society?: string | null;
        shippingInformations?: string | null;
        phone: string;
        mobilePhone?: string | null;
      } | null> | null;
      createdAt: string;
    } | null;
    pickupAt?: string | null;
    hangupAt?: string | null;
    waitingAt?: string | null;
    completeAt?: string | null;
    orders?: Array<{
      __typename: "Order";
      id: number;
      incrementId: string;
      totalAmount: number;
      status: string;
      marketplace?: string | null;
      orderProducts?: Array<{
        __typename: "OrderProduct";
        name: string;
        qty: number;
      } | null> | null;
      createdAt: string;
    } | null> | null;
    shippings?: Array<{
      __typename: "Shipping";
      id: number;
      status: string;
      createdAt: string;
      tracking?: string | null;
      shippingMethod: string;
    } | null> | null;
    cart?: {
      __typename: "Cart";
      id: number;
      totalAmount: number;
      cartProducts?: Array<{
        __typename: "CartProduct";
        name: string;
        qty: number;
        totalAmount: number;
      } | null> | null;
    } | null;
    callParticipants?: {
      __typename: "ModelCallParticipantConnection";
      items: Array<{
        __typename: "CallParticipant";
        id: string;
        callID: string;
        callSid: string;
        status: string;
        mode: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callQualificationID?: string | null;
    callQualification?: {
      __typename: "CallQualification";
      id: string;
      type: string;
      name: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    callReasonID?: string | null;
    callReason?: {
      __typename: "CallReason";
      id: string;
      name: string;
      type: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    callCampaignID?: string | null;
    callCampaign?: {
      __typename: "CallCampaign";
      id: string;
      name: string;
      objections?: {
        __typename: "ModelCallObjectionConnection";
        nextToken?: string | null;
      } | null;
      callScriptID?: string | null;
      callScript?: {
        __typename: "CallScript";
        id: string;
        name: string;
        text: string;
        callCampaignID: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      callWidgets?: Array<{
        __typename: "CallWidget";
        type: string;
        params?: string | null;
      } | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    recording?: boolean | null;
    recordingSid?: string | null;
    recordingUrl?: string | null;
    voicemail?: boolean | null;
    voicemailRead?: boolean | null;
    turnOver?: number | null;
    orderId?: string | null;
    date?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallByDateQuery = {
  __typename: "ModelCallConnection";
  items: Array<{
    __typename: "Call";
    id: string;
    callSid: string;
    from: string;
    to: string;
    direction: string;
    switchboardID?: string | null;
    switchboard?: {
      __typename: "Switchboard";
      id: string;
      name: string;
      phone: string;
      logo?: string | null;
      welcomeMessage?: string | null;
      hasSeasonalWelcomeMessage?: boolean | null;
      winterWelcomeMessage?: string | null;
      springWelcomeMessage?: string | null;
      automnWelcomeMessage?: string | null;
      summerWelcomeMessage?: string | null;
      waitMusic?: string | null;
      recordingMessage?: string | null;
      closeMessage?: string | null;
      openTime?: string | null;
      closeTime?: string | null;
      connectorType?: string | null;
      connectorId?: string | null;
      interactiveVoiceResponseID?: string | null;
      interactiveVoiceResponse?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      position: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      username: string;
      admin: boolean;
      slackID?: string | null;
      phone?: string | null;
      workerSid?: string | null;
      activitySid?: string | null;
      available?: boolean | null;
      firstname?: string | null;
      lastname?: string | null;
      email?: string | null;
      avatar?: string | null;
      gender?: Gender | null;
      voicemailMessage?: string | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      interactiveVoiceResponses?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    conferenceName?: string | null;
    status?: string | null;
    customer?: {
      __typename: "Customer";
      id: number;
      firstname: string;
      lastname: string;
      gender: string;
      email: string;
      blocked: boolean;
      badge: string;
      pareto: string;
      addresses?: Array<{
        __typename: "Address";
        id: number;
        firstname: string;
        lastname: string;
        prefix?: string | null;
        street: string;
        street2?: string | null;
        zipcode: string;
        city: string;
        country: string;
        society?: string | null;
        shippingInformations?: string | null;
        phone: string;
        mobilePhone?: string | null;
      } | null> | null;
      createdAt: string;
    } | null;
    pickupAt?: string | null;
    hangupAt?: string | null;
    waitingAt?: string | null;
    completeAt?: string | null;
    orders?: Array<{
      __typename: "Order";
      id: number;
      incrementId: string;
      totalAmount: number;
      status: string;
      marketplace?: string | null;
      orderProducts?: Array<{
        __typename: "OrderProduct";
        name: string;
        qty: number;
      } | null> | null;
      createdAt: string;
    } | null> | null;
    shippings?: Array<{
      __typename: "Shipping";
      id: number;
      status: string;
      createdAt: string;
      tracking?: string | null;
      shippingMethod: string;
    } | null> | null;
    cart?: {
      __typename: "Cart";
      id: number;
      totalAmount: number;
      cartProducts?: Array<{
        __typename: "CartProduct";
        name: string;
        qty: number;
        totalAmount: number;
      } | null> | null;
    } | null;
    callParticipants?: {
      __typename: "ModelCallParticipantConnection";
      items: Array<{
        __typename: "CallParticipant";
        id: string;
        callID: string;
        callSid: string;
        status: string;
        mode: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callQualificationID?: string | null;
    callQualification?: {
      __typename: "CallQualification";
      id: string;
      type: string;
      name: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    callReasonID?: string | null;
    callReason?: {
      __typename: "CallReason";
      id: string;
      name: string;
      type: string;
      position: number;
      createdAt: string;
      updatedAt: string;
    } | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    callCampaignID?: string | null;
    callCampaign?: {
      __typename: "CallCampaign";
      id: string;
      name: string;
      objections?: {
        __typename: "ModelCallObjectionConnection";
        nextToken?: string | null;
      } | null;
      callScriptID?: string | null;
      callScript?: {
        __typename: "CallScript";
        id: string;
        name: string;
        text: string;
        callCampaignID: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      callWidgets?: Array<{
        __typename: "CallWidget";
        type: string;
        params?: string | null;
      } | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    recording?: boolean | null;
    recordingSid?: string | null;
    recordingUrl?: string | null;
    voicemail?: boolean | null;
    voicemailRead?: boolean | null;
    turnOver?: number | null;
    orderId?: string | null;
    date?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallParticipantByCallSidQuery = {
  __typename: "ModelCallParticipantConnection";
  items: Array<{
    __typename: "CallParticipant";
    id: string;
    callID: string;
    callSid: string;
    status: string;
    mode: string;
    userID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallQualificationsByPositionQuery = {
  __typename: "ModelCallQualificationConnection";
  items: Array<{
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type CallReasonsByPositionQuery = {
  __typename: "ModelCallReasonConnection";
  items: Array<{
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type UserByWorkerSidQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type UserByPhoneQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type InteractiveVoiceResponseByParentIDQuery = {
  __typename: "ModelInteractiveVoiceResponseConnection";
  items: Array<{
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type ContactByUserIDQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    firstname: string;
    lastname: string;
    search: string;
    email?: string | null;
    phone?: string | null;
    userID: string;
    createdAt: string;
    updatedAt: string;
  }>;
  nextToken?: string | null;
};

export type OnCreateSwitchboardSubscription = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSwitchboardSubscription = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSwitchboardSubscription = {
  __typename: "Switchboard";
  id: string;
  name: string;
  phone: string;
  logo?: string | null;
  welcomeMessage?: string | null;
  hasSeasonalWelcomeMessage?: boolean | null;
  winterWelcomeMessage?: string | null;
  springWelcomeMessage?: string | null;
  automnWelcomeMessage?: string | null;
  summerWelcomeMessage?: string | null;
  waitMusic?: string | null;
  recordingMessage?: string | null;
  closeMessage?: string | null;
  openTime?: string | null;
  closeTime?: string | null;
  connectorType?: string | null;
  connectorId?: string | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  position: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallSubscription = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnUpdateCallSubscription = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnDeleteCallSubscription = {
  __typename: "Call";
  id: string;
  callSid: string;
  from: string;
  to: string;
  direction: string;
  switchboardID?: string | null;
  switchboard?: {
    __typename: "Switchboard";
    id: string;
    name: string;
    phone: string;
    logo?: string | null;
    welcomeMessage?: string | null;
    hasSeasonalWelcomeMessage?: boolean | null;
    winterWelcomeMessage?: string | null;
    springWelcomeMessage?: string | null;
    automnWelcomeMessage?: string | null;
    summerWelcomeMessage?: string | null;
    waitMusic?: string | null;
    recordingMessage?: string | null;
    closeMessage?: string | null;
    openTime?: string | null;
    closeTime?: string | null;
    connectorType?: string | null;
    connectorId?: string | null;
    interactiveVoiceResponseID?: string | null;
    interactiveVoiceResponse?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    position: number;
    type: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  conferenceName?: string | null;
  status?: string | null;
  customer?: {
    __typename: "Customer";
    id: number;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    blocked: boolean;
    badge: string;
    pareto: string;
    addresses?: Array<{
      __typename: "Address";
      id: number;
      firstname: string;
      lastname: string;
      prefix?: string | null;
      street: string;
      street2?: string | null;
      zipcode: string;
      city: string;
      country: string;
      society?: string | null;
      shippingInformations?: string | null;
      phone: string;
      mobilePhone?: string | null;
    } | null> | null;
    createdAt: string;
  } | null;
  pickupAt?: string | null;
  hangupAt?: string | null;
  waitingAt?: string | null;
  completeAt?: string | null;
  orders?: Array<{
    __typename: "Order";
    id: number;
    incrementId: string;
    totalAmount: number;
    status: string;
    marketplace?: string | null;
    orderProducts?: Array<{
      __typename: "OrderProduct";
      name: string;
      qty: number;
    } | null> | null;
    createdAt: string;
  } | null> | null;
  shippings?: Array<{
    __typename: "Shipping";
    id: number;
    status: string;
    createdAt: string;
    tracking?: string | null;
    shippingMethod: string;
  } | null> | null;
  cart?: {
    __typename: "Cart";
    id: number;
    totalAmount: number;
    cartProducts?: Array<{
      __typename: "CartProduct";
      name: string;
      qty: number;
      totalAmount: number;
    } | null> | null;
  } | null;
  callParticipants?: {
    __typename: "ModelCallParticipantConnection";
    items: Array<{
      __typename: "CallParticipant";
      id: string;
      callID: string;
      callSid: string;
      status: string;
      mode: string;
      userID: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callQualificationID?: string | null;
  callQualification?: {
    __typename: "CallQualification";
    id: string;
    type: string;
    name: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  callReasonID?: string | null;
  callReason?: {
    __typename: "CallReason";
    id: string;
    name: string;
    type: string;
    position: number;
    createdAt: string;
    updatedAt: string;
  } | null;
  interactiveVoiceResponseID?: string | null;
  interactiveVoiceResponse?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  callCampaignID?: string | null;
  callCampaign?: {
    __typename: "CallCampaign";
    id: string;
    name: string;
    objections?: {
      __typename: "ModelCallObjectionConnection";
      items: Array<{
        __typename: "CallObjection";
        id: string;
        callCampaignID: string;
        title: string;
        answer: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    callScriptID?: string | null;
    callScript?: {
      __typename: "CallScript";
      id: string;
      name: string;
      text: string;
      callCampaignID: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    callWidgets?: Array<{
      __typename: "CallWidget";
      type: string;
      params?: string | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  recording?: boolean | null;
  recordingSid?: string | null;
  recordingUrl?: string | null;
  voicemail?: boolean | null;
  voicemailRead?: boolean | null;
  turnOver?: number | null;
  orderId?: string | null;
  date?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnCreateCallParticipantSubscription = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallParticipantSubscription = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallParticipantSubscription = {
  __typename: "CallParticipant";
  id: string;
  callID: string;
  callSid: string;
  status: string;
  mode: string;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallQualificationSubscription = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallQualificationSubscription = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallQualificationSubscription = {
  __typename: "CallQualification";
  id: string;
  type: string;
  name: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallReasonSubscription = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallReasonSubscription = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallReasonSubscription = {
  __typename: "CallReason";
  id: string;
  name: string;
  type: string;
  position: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  admin: boolean;
  slackID?: string | null;
  phone?: string | null;
  workerSid?: string | null;
  activitySid?: string | null;
  available?: boolean | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  avatar?: string | null;
  gender?: Gender | null;
  voicemailMessage?: string | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  interactiveVoiceResponses?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateInteractiveVoiceResponseSubscription = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateInteractiveVoiceResponseSubscription = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteInteractiveVoiceResponseSubscription = {
  __typename: "InteractiveVoiceResponse";
  id: string;
  play?: string | null;
  digit?: number | null;
  taskQueueSid?: string | null;
  workflowSid?: string | null;
  callReasonID?: string | null;
  parentID?: string | null;
  parent?: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  name: string;
  playText?: string | null;
  users?: {
    __typename: "ModelInteractiveVoiceResponseUserConnection";
    items: Array<{
      __typename: "InteractiveVoiceResponseUser";
      id: string;
      userID: string;
      interactiveVoiceResponseID: string;
      user: {
        __typename: "User";
        id: string;
        username: string;
        admin: boolean;
        slackID?: string | null;
        phone?: string | null;
        workerSid?: string | null;
        activitySid?: string | null;
        available?: boolean | null;
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
        avatar?: string | null;
        gender?: Gender | null;
        voicemailMessage?: string | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      interactiveVoiceResponse: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateInteractiveVoiceResponseUserSubscription = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateInteractiveVoiceResponseUserSubscription = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteInteractiveVoiceResponseUserSubscription = {
  __typename: "InteractiveVoiceResponseUser";
  id: string;
  userID: string;
  interactiveVoiceResponseID: string;
  user: {
    __typename: "User";
    id: string;
    username: string;
    admin: boolean;
    slackID?: string | null;
    phone?: string | null;
    workerSid?: string | null;
    activitySid?: string | null;
    available?: boolean | null;
    firstname?: string | null;
    lastname?: string | null;
    email?: string | null;
    avatar?: string | null;
    gender?: Gender | null;
    voicemailMessage?: string | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    interactiveVoiceResponses?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  interactiveVoiceResponse: {
    __typename: "InteractiveVoiceResponse";
    id: string;
    play?: string | null;
    digit?: number | null;
    taskQueueSid?: string | null;
    workflowSid?: string | null;
    callReasonID?: string | null;
    parentID?: string | null;
    parent?: {
      __typename: "InteractiveVoiceResponse";
      id: string;
      play?: string | null;
      digit?: number | null;
      taskQueueSid?: string | null;
      workflowSid?: string | null;
      callReasonID?: string | null;
      parentID?: string | null;
      parent?: {
        __typename: "InteractiveVoiceResponse";
        id: string;
        play?: string | null;
        digit?: number | null;
        taskQueueSid?: string | null;
        workflowSid?: string | null;
        callReasonID?: string | null;
        parentID?: string | null;
        name: string;
        playText?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      playText?: string | null;
      users?: {
        __typename: "ModelInteractiveVoiceResponseUserConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    name: string;
    playText?: string | null;
    users?: {
      __typename: "ModelInteractiveVoiceResponseUserConnection";
      items: Array<{
        __typename: "InteractiveVoiceResponseUser";
        id: string;
        userID: string;
        interactiveVoiceResponseID: string;
        createdAt: string;
        updatedAt: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallCampaignSubscription = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallCampaignSubscription = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallCampaignSubscription = {
  __typename: "CallCampaign";
  id: string;
  name: string;
  objections?: {
    __typename: "ModelCallObjectionConnection";
    items: Array<{
      __typename: "CallObjection";
      id: string;
      callCampaignID: string;
      title: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  callScriptID?: string | null;
  callScript?: {
    __typename: "CallScript";
    id: string;
    name: string;
    text: string;
    callCampaignID: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  callWidgets?: Array<{
    __typename: "CallWidget";
    type: string;
    params?: string | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallCampaignCustomerSubscription = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallCampaignCustomerSubscription = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallCampaignCustomerSubscription = {
  __typename: "CallCampaignCustomer";
  id: string;
  callCampaignID: string;
  customerId: number;
  name: string;
  status: string;
  phone: string;
  plannedAt: string;
  calledAt?: string | null;
  callID?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallObjectionSubscription = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallObjectionSubscription = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallObjectionSubscription = {
  __typename: "CallObjection";
  id: string;
  callCampaignID: string;
  title: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallScriptSubscription = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallScriptSubscription = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallScriptSubscription = {
  __typename: "CallScript";
  id: string;
  name: string;
  text: string;
  callCampaignID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallScriptRelationSubscription = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCallScriptRelationSubscription = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCallScriptRelationSubscription = {
  __typename: "CallScriptRelation";
  id: string;
  answer?: string | null;
  sourceCallScriptID: string;
  targetCallScriptID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateContactSubscription = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateContactSubscription = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteContactSubscription = {
  __typename: "Contact";
  id: string;
  firstname: string;
  lastname: string;
  search: string;
  email?: string | null;
  phone?: string | null;
  userID: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketBridgeSubscription = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketBridgeSubscription = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketBridgeSubscription = {
  __typename: "TicketBridge";
  id: string;
  name: string;
  email: string;
  password: string;
  host: string;
  port: number;
  connectorType?: string | null;
  connectorId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketSubscription = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketSubscription = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketSubscription = {
  __typename: "Ticket";
  id: string;
  email: string;
  subject?: string | null;
  messages?: {
    __typename: "ModelTicketMessageConnection";
    items: Array<{
      __typename: "TicketMessage";
      id: string;
      ticketID: string;
      email: string;
      text: string;
      html: string;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketQuickAnswerSubscription = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketQuickAnswerSubscription = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketQuickAnswerSubscription = {
  __typename: "TicketQuickAnswer";
  id: string;
  name: string;
  message: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketMessageSubscription = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketMessageSubscription = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketMessageSubscription = {
  __typename: "TicketMessage";
  id: string;
  ticketID: string;
  email: string;
  text: string;
  html: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketDomainSubscription = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketDomainSubscription = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketDomainSubscription = {
  __typename: "TicketDomain";
  id: string;
  name: string;
  subdomains?: {
    __typename: "ModelTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketSubDomain";
      id: string;
      ticketDomainID: string;
      name: string;
      ticketQuickAnswers?: {
        __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketSubDomainSubscription = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketSubDomainSubscription = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketSubDomainSubscription = {
  __typename: "TicketSubDomain";
  id: string;
  ticketDomainID: string;
  name: string;
  ticketQuickAnswers?: {
    __typename: "ModelTicketQuickAnswerTicketSubDomainConnection";
    items: Array<{
      __typename: "TicketQuickAnswerTicketSubDomain";
      id: string;
      ticketQuickAnswerID: string;
      ticketSubDomainID: string;
      ticketQuickAnswer: {
        __typename: "TicketQuickAnswer";
        id: string;
        name: string;
        message: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketQuickAnswerTicketSubDomainSubscription = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketQuickAnswerTicketSubDomainSubscription = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketQuickAnswerTicketSubDomainSubscription = {
  __typename: "TicketQuickAnswerTicketSubDomain";
  id: string;
  ticketQuickAnswerID: string;
  ticketSubDomainID: string;
  ticketQuickAnswer: {
    __typename: "TicketQuickAnswer";
    id: string;
    name: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTicketActionSubscription = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTicketActionSubscription = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTicketActionSubscription = {
  __typename: "TicketAction";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async ListCallsBis(): Promise<ListCallsBisQuery> {
    const statement = `query ListCallsBis {
        listCalls {
          __typename
          items {
            __typename
            id
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <ListCallsBisQuery>response.data.listCalls;
  }
  async CreateSwitchboard(
    input: CreateSwitchboardInput,
    condition?: ModelSwitchboardConditionInput
  ): Promise<CreateSwitchboardMutation> {
    const statement = `mutation CreateSwitchboard($input: CreateSwitchboardInput!, $condition: ModelSwitchboardConditionInput) {
        createSwitchboard(input: $input, condition: $condition) {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSwitchboardMutation>response.data.createSwitchboard;
  }
  async UpdateSwitchboard(
    input: UpdateSwitchboardInput,
    condition?: ModelSwitchboardConditionInput
  ): Promise<UpdateSwitchboardMutation> {
    const statement = `mutation UpdateSwitchboard($input: UpdateSwitchboardInput!, $condition: ModelSwitchboardConditionInput) {
        updateSwitchboard(input: $input, condition: $condition) {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSwitchboardMutation>response.data.updateSwitchboard;
  }
  async DeleteSwitchboard(
    input: DeleteSwitchboardInput,
    condition?: ModelSwitchboardConditionInput
  ): Promise<DeleteSwitchboardMutation> {
    const statement = `mutation DeleteSwitchboard($input: DeleteSwitchboardInput!, $condition: ModelSwitchboardConditionInput) {
        deleteSwitchboard(input: $input, condition: $condition) {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSwitchboardMutation>response.data.deleteSwitchboard;
  }
  async CreateCall(
    input: CreateCallInput,
    condition?: ModelCallConditionInput
  ): Promise<CreateCallMutation> {
    const statement = `mutation CreateCall($input: CreateCallInput!, $condition: ModelCallConditionInput) {
        createCall(input: $input, condition: $condition) {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallMutation>response.data.createCall;
  }
  async UpdateCall(
    input: UpdateCallInput,
    condition?: ModelCallConditionInput
  ): Promise<UpdateCallMutation> {
    const statement = `mutation UpdateCall($input: UpdateCallInput!, $condition: ModelCallConditionInput) {
        updateCall(input: $input, condition: $condition) {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallMutation>response.data.updateCall;
  }
  async DeleteCall(
    input: DeleteCallInput,
    condition?: ModelCallConditionInput
  ): Promise<DeleteCallMutation> {
    const statement = `mutation DeleteCall($input: DeleteCallInput!, $condition: ModelCallConditionInput) {
        deleteCall(input: $input, condition: $condition) {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallMutation>response.data.deleteCall;
  }
  async CreateCallParticipant(
    input: CreateCallParticipantInput,
    condition?: ModelCallParticipantConditionInput
  ): Promise<CreateCallParticipantMutation> {
    const statement = `mutation CreateCallParticipant($input: CreateCallParticipantInput!, $condition: ModelCallParticipantConditionInput) {
        createCallParticipant(input: $input, condition: $condition) {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallParticipantMutation>response.data.createCallParticipant;
  }
  async UpdateCallParticipant(
    input: UpdateCallParticipantInput,
    condition?: ModelCallParticipantConditionInput
  ): Promise<UpdateCallParticipantMutation> {
    const statement = `mutation UpdateCallParticipant($input: UpdateCallParticipantInput!, $condition: ModelCallParticipantConditionInput) {
        updateCallParticipant(input: $input, condition: $condition) {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallParticipantMutation>response.data.updateCallParticipant;
  }
  async DeleteCallParticipant(
    input: DeleteCallParticipantInput,
    condition?: ModelCallParticipantConditionInput
  ): Promise<DeleteCallParticipantMutation> {
    const statement = `mutation DeleteCallParticipant($input: DeleteCallParticipantInput!, $condition: ModelCallParticipantConditionInput) {
        deleteCallParticipant(input: $input, condition: $condition) {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallParticipantMutation>response.data.deleteCallParticipant;
  }
  async CreateCallQualification(
    input: CreateCallQualificationInput,
    condition?: ModelCallQualificationConditionInput
  ): Promise<CreateCallQualificationMutation> {
    const statement = `mutation CreateCallQualification($input: CreateCallQualificationInput!, $condition: ModelCallQualificationConditionInput) {
        createCallQualification(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallQualificationMutation>(
      response.data.createCallQualification
    );
  }
  async UpdateCallQualification(
    input: UpdateCallQualificationInput,
    condition?: ModelCallQualificationConditionInput
  ): Promise<UpdateCallQualificationMutation> {
    const statement = `mutation UpdateCallQualification($input: UpdateCallQualificationInput!, $condition: ModelCallQualificationConditionInput) {
        updateCallQualification(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallQualificationMutation>(
      response.data.updateCallQualification
    );
  }
  async DeleteCallQualification(
    input: DeleteCallQualificationInput,
    condition?: ModelCallQualificationConditionInput
  ): Promise<DeleteCallQualificationMutation> {
    const statement = `mutation DeleteCallQualification($input: DeleteCallQualificationInput!, $condition: ModelCallQualificationConditionInput) {
        deleteCallQualification(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallQualificationMutation>(
      response.data.deleteCallQualification
    );
  }
  async CreateCallReason(
    input: CreateCallReasonInput,
    condition?: ModelCallReasonConditionInput
  ): Promise<CreateCallReasonMutation> {
    const statement = `mutation CreateCallReason($input: CreateCallReasonInput!, $condition: ModelCallReasonConditionInput) {
        createCallReason(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallReasonMutation>response.data.createCallReason;
  }
  async UpdateCallReason(
    input: UpdateCallReasonInput,
    condition?: ModelCallReasonConditionInput
  ): Promise<UpdateCallReasonMutation> {
    const statement = `mutation UpdateCallReason($input: UpdateCallReasonInput!, $condition: ModelCallReasonConditionInput) {
        updateCallReason(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallReasonMutation>response.data.updateCallReason;
  }
  async DeleteCallReason(
    input: DeleteCallReasonInput,
    condition?: ModelCallReasonConditionInput
  ): Promise<DeleteCallReasonMutation> {
    const statement = `mutation DeleteCallReason($input: DeleteCallReasonInput!, $condition: ModelCallReasonConditionInput) {
        deleteCallReason(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallReasonMutation>response.data.deleteCallReason;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateInteractiveVoiceResponse(
    input: CreateInteractiveVoiceResponseInput,
    condition?: ModelInteractiveVoiceResponseConditionInput
  ): Promise<CreateInteractiveVoiceResponseMutation> {
    const statement = `mutation CreateInteractiveVoiceResponse($input: CreateInteractiveVoiceResponseInput!, $condition: ModelInteractiveVoiceResponseConditionInput) {
        createInteractiveVoiceResponse(input: $input, condition: $condition) {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInteractiveVoiceResponseMutation>(
      response.data.createInteractiveVoiceResponse
    );
  }
  async UpdateInteractiveVoiceResponse(
    input: UpdateInteractiveVoiceResponseInput,
    condition?: ModelInteractiveVoiceResponseConditionInput
  ): Promise<UpdateInteractiveVoiceResponseMutation> {
    const statement = `mutation UpdateInteractiveVoiceResponse($input: UpdateInteractiveVoiceResponseInput!, $condition: ModelInteractiveVoiceResponseConditionInput) {
        updateInteractiveVoiceResponse(input: $input, condition: $condition) {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInteractiveVoiceResponseMutation>(
      response.data.updateInteractiveVoiceResponse
    );
  }
  async DeleteInteractiveVoiceResponse(
    input: DeleteInteractiveVoiceResponseInput,
    condition?: ModelInteractiveVoiceResponseConditionInput
  ): Promise<DeleteInteractiveVoiceResponseMutation> {
    const statement = `mutation DeleteInteractiveVoiceResponse($input: DeleteInteractiveVoiceResponseInput!, $condition: ModelInteractiveVoiceResponseConditionInput) {
        deleteInteractiveVoiceResponse(input: $input, condition: $condition) {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInteractiveVoiceResponseMutation>(
      response.data.deleteInteractiveVoiceResponse
    );
  }
  async CreateInteractiveVoiceResponseUser(
    input: CreateInteractiveVoiceResponseUserInput,
    condition?: ModelInteractiveVoiceResponseUserConditionInput
  ): Promise<CreateInteractiveVoiceResponseUserMutation> {
    const statement = `mutation CreateInteractiveVoiceResponseUser($input: CreateInteractiveVoiceResponseUserInput!, $condition: ModelInteractiveVoiceResponseUserConditionInput) {
        createInteractiveVoiceResponseUser(input: $input, condition: $condition) {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInteractiveVoiceResponseUserMutation>(
      response.data.createInteractiveVoiceResponseUser
    );
  }
  async UpdateInteractiveVoiceResponseUser(
    input: UpdateInteractiveVoiceResponseUserInput,
    condition?: ModelInteractiveVoiceResponseUserConditionInput
  ): Promise<UpdateInteractiveVoiceResponseUserMutation> {
    const statement = `mutation UpdateInteractiveVoiceResponseUser($input: UpdateInteractiveVoiceResponseUserInput!, $condition: ModelInteractiveVoiceResponseUserConditionInput) {
        updateInteractiveVoiceResponseUser(input: $input, condition: $condition) {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInteractiveVoiceResponseUserMutation>(
      response.data.updateInteractiveVoiceResponseUser
    );
  }
  async DeleteInteractiveVoiceResponseUser(
    input: DeleteInteractiveVoiceResponseUserInput,
    condition?: ModelInteractiveVoiceResponseUserConditionInput
  ): Promise<DeleteInteractiveVoiceResponseUserMutation> {
    const statement = `mutation DeleteInteractiveVoiceResponseUser($input: DeleteInteractiveVoiceResponseUserInput!, $condition: ModelInteractiveVoiceResponseUserConditionInput) {
        deleteInteractiveVoiceResponseUser(input: $input, condition: $condition) {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInteractiveVoiceResponseUserMutation>(
      response.data.deleteInteractiveVoiceResponseUser
    );
  }
  async CreateCallCampaign(
    input: CreateCallCampaignInput,
    condition?: ModelCallCampaignConditionInput
  ): Promise<CreateCallCampaignMutation> {
    const statement = `mutation CreateCallCampaign($input: CreateCallCampaignInput!, $condition: ModelCallCampaignConditionInput) {
        createCallCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallCampaignMutation>response.data.createCallCampaign;
  }
  async UpdateCallCampaign(
    input: UpdateCallCampaignInput,
    condition?: ModelCallCampaignConditionInput
  ): Promise<UpdateCallCampaignMutation> {
    const statement = `mutation UpdateCallCampaign($input: UpdateCallCampaignInput!, $condition: ModelCallCampaignConditionInput) {
        updateCallCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallCampaignMutation>response.data.updateCallCampaign;
  }
  async DeleteCallCampaign(
    input: DeleteCallCampaignInput,
    condition?: ModelCallCampaignConditionInput
  ): Promise<DeleteCallCampaignMutation> {
    const statement = `mutation DeleteCallCampaign($input: DeleteCallCampaignInput!, $condition: ModelCallCampaignConditionInput) {
        deleteCallCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallCampaignMutation>response.data.deleteCallCampaign;
  }
  async CreateCallCampaignCustomer(
    input: CreateCallCampaignCustomerInput,
    condition?: ModelCallCampaignCustomerConditionInput
  ): Promise<CreateCallCampaignCustomerMutation> {
    const statement = `mutation CreateCallCampaignCustomer($input: CreateCallCampaignCustomerInput!, $condition: ModelCallCampaignCustomerConditionInput) {
        createCallCampaignCustomer(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallCampaignCustomerMutation>(
      response.data.createCallCampaignCustomer
    );
  }
  async UpdateCallCampaignCustomer(
    input: UpdateCallCampaignCustomerInput,
    condition?: ModelCallCampaignCustomerConditionInput
  ): Promise<UpdateCallCampaignCustomerMutation> {
    const statement = `mutation UpdateCallCampaignCustomer($input: UpdateCallCampaignCustomerInput!, $condition: ModelCallCampaignCustomerConditionInput) {
        updateCallCampaignCustomer(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallCampaignCustomerMutation>(
      response.data.updateCallCampaignCustomer
    );
  }
  async DeleteCallCampaignCustomer(
    input: DeleteCallCampaignCustomerInput,
    condition?: ModelCallCampaignCustomerConditionInput
  ): Promise<DeleteCallCampaignCustomerMutation> {
    const statement = `mutation DeleteCallCampaignCustomer($input: DeleteCallCampaignCustomerInput!, $condition: ModelCallCampaignCustomerConditionInput) {
        deleteCallCampaignCustomer(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallCampaignCustomerMutation>(
      response.data.deleteCallCampaignCustomer
    );
  }
  async CreateCallObjection(
    input: CreateCallObjectionInput,
    condition?: ModelCallObjectionConditionInput
  ): Promise<CreateCallObjectionMutation> {
    const statement = `mutation CreateCallObjection($input: CreateCallObjectionInput!, $condition: ModelCallObjectionConditionInput) {
        createCallObjection(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallObjectionMutation>response.data.createCallObjection;
  }
  async UpdateCallObjection(
    input: UpdateCallObjectionInput,
    condition?: ModelCallObjectionConditionInput
  ): Promise<UpdateCallObjectionMutation> {
    const statement = `mutation UpdateCallObjection($input: UpdateCallObjectionInput!, $condition: ModelCallObjectionConditionInput) {
        updateCallObjection(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallObjectionMutation>response.data.updateCallObjection;
  }
  async DeleteCallObjection(
    input: DeleteCallObjectionInput,
    condition?: ModelCallObjectionConditionInput
  ): Promise<DeleteCallObjectionMutation> {
    const statement = `mutation DeleteCallObjection($input: DeleteCallObjectionInput!, $condition: ModelCallObjectionConditionInput) {
        deleteCallObjection(input: $input, condition: $condition) {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallObjectionMutation>response.data.deleteCallObjection;
  }
  async CreateCallScript(
    input: CreateCallScriptInput,
    condition?: ModelCallScriptConditionInput
  ): Promise<CreateCallScriptMutation> {
    const statement = `mutation CreateCallScript($input: CreateCallScriptInput!, $condition: ModelCallScriptConditionInput) {
        createCallScript(input: $input, condition: $condition) {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallScriptMutation>response.data.createCallScript;
  }
  async UpdateCallScript(
    input: UpdateCallScriptInput,
    condition?: ModelCallScriptConditionInput
  ): Promise<UpdateCallScriptMutation> {
    const statement = `mutation UpdateCallScript($input: UpdateCallScriptInput!, $condition: ModelCallScriptConditionInput) {
        updateCallScript(input: $input, condition: $condition) {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallScriptMutation>response.data.updateCallScript;
  }
  async DeleteCallScript(
    input: DeleteCallScriptInput,
    condition?: ModelCallScriptConditionInput
  ): Promise<DeleteCallScriptMutation> {
    const statement = `mutation DeleteCallScript($input: DeleteCallScriptInput!, $condition: ModelCallScriptConditionInput) {
        deleteCallScript(input: $input, condition: $condition) {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallScriptMutation>response.data.deleteCallScript;
  }
  async CreateCallScriptRelation(
    input: CreateCallScriptRelationInput,
    condition?: ModelCallScriptRelationConditionInput
  ): Promise<CreateCallScriptRelationMutation> {
    const statement = `mutation CreateCallScriptRelation($input: CreateCallScriptRelationInput!, $condition: ModelCallScriptRelationConditionInput) {
        createCallScriptRelation(input: $input, condition: $condition) {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallScriptRelationMutation>(
      response.data.createCallScriptRelation
    );
  }
  async UpdateCallScriptRelation(
    input: UpdateCallScriptRelationInput,
    condition?: ModelCallScriptRelationConditionInput
  ): Promise<UpdateCallScriptRelationMutation> {
    const statement = `mutation UpdateCallScriptRelation($input: UpdateCallScriptRelationInput!, $condition: ModelCallScriptRelationConditionInput) {
        updateCallScriptRelation(input: $input, condition: $condition) {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallScriptRelationMutation>(
      response.data.updateCallScriptRelation
    );
  }
  async DeleteCallScriptRelation(
    input: DeleteCallScriptRelationInput,
    condition?: ModelCallScriptRelationConditionInput
  ): Promise<DeleteCallScriptRelationMutation> {
    const statement = `mutation DeleteCallScriptRelation($input: DeleteCallScriptRelationInput!, $condition: ModelCallScriptRelationConditionInput) {
        deleteCallScriptRelation(input: $input, condition: $condition) {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallScriptRelationMutation>(
      response.data.deleteCallScriptRelation
    );
  }
  async CreateContact(
    input: CreateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<CreateContactMutation> {
    const statement = `mutation CreateContact($input: CreateContactInput!, $condition: ModelContactConditionInput) {
        createContact(input: $input, condition: $condition) {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactMutation>response.data.createContact;
  }
  async UpdateContact(
    input: UpdateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<UpdateContactMutation> {
    const statement = `mutation UpdateContact($input: UpdateContactInput!, $condition: ModelContactConditionInput) {
        updateContact(input: $input, condition: $condition) {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactMutation>response.data.updateContact;
  }
  async DeleteContact(
    input: DeleteContactInput,
    condition?: ModelContactConditionInput
  ): Promise<DeleteContactMutation> {
    const statement = `mutation DeleteContact($input: DeleteContactInput!, $condition: ModelContactConditionInput) {
        deleteContact(input: $input, condition: $condition) {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactMutation>response.data.deleteContact;
  }
  async CreateTicketBridge(
    input: CreateTicketBridgeInput,
    condition?: ModelTicketBridgeConditionInput
  ): Promise<CreateTicketBridgeMutation> {
    const statement = `mutation CreateTicketBridge($input: CreateTicketBridgeInput!, $condition: ModelTicketBridgeConditionInput) {
        createTicketBridge(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketBridgeMutation>response.data.createTicketBridge;
  }
  async UpdateTicketBridge(
    input: UpdateTicketBridgeInput,
    condition?: ModelTicketBridgeConditionInput
  ): Promise<UpdateTicketBridgeMutation> {
    const statement = `mutation UpdateTicketBridge($input: UpdateTicketBridgeInput!, $condition: ModelTicketBridgeConditionInput) {
        updateTicketBridge(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketBridgeMutation>response.data.updateTicketBridge;
  }
  async DeleteTicketBridge(
    input: DeleteTicketBridgeInput,
    condition?: ModelTicketBridgeConditionInput
  ): Promise<DeleteTicketBridgeMutation> {
    const statement = `mutation DeleteTicketBridge($input: DeleteTicketBridgeInput!, $condition: ModelTicketBridgeConditionInput) {
        deleteTicketBridge(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketBridgeMutation>response.data.deleteTicketBridge;
  }
  async CreateTicket(
    input: CreateTicketInput,
    condition?: ModelTicketConditionInput
  ): Promise<CreateTicketMutation> {
    const statement = `mutation CreateTicket($input: CreateTicketInput!, $condition: ModelTicketConditionInput) {
        createTicket(input: $input, condition: $condition) {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketMutation>response.data.createTicket;
  }
  async UpdateTicket(
    input: UpdateTicketInput,
    condition?: ModelTicketConditionInput
  ): Promise<UpdateTicketMutation> {
    const statement = `mutation UpdateTicket($input: UpdateTicketInput!, $condition: ModelTicketConditionInput) {
        updateTicket(input: $input, condition: $condition) {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketMutation>response.data.updateTicket;
  }
  async DeleteTicket(
    input: DeleteTicketInput,
    condition?: ModelTicketConditionInput
  ): Promise<DeleteTicketMutation> {
    const statement = `mutation DeleteTicket($input: DeleteTicketInput!, $condition: ModelTicketConditionInput) {
        deleteTicket(input: $input, condition: $condition) {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketMutation>response.data.deleteTicket;
  }
  async CreateTicketQuickAnswer(
    input: CreateTicketQuickAnswerInput,
    condition?: ModelTicketQuickAnswerConditionInput
  ): Promise<CreateTicketQuickAnswerMutation> {
    const statement = `mutation CreateTicketQuickAnswer($input: CreateTicketQuickAnswerInput!, $condition: ModelTicketQuickAnswerConditionInput) {
        createTicketQuickAnswer(input: $input, condition: $condition) {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketQuickAnswerMutation>(
      response.data.createTicketQuickAnswer
    );
  }
  async UpdateTicketQuickAnswer(
    input: UpdateTicketQuickAnswerInput,
    condition?: ModelTicketQuickAnswerConditionInput
  ): Promise<UpdateTicketQuickAnswerMutation> {
    const statement = `mutation UpdateTicketQuickAnswer($input: UpdateTicketQuickAnswerInput!, $condition: ModelTicketQuickAnswerConditionInput) {
        updateTicketQuickAnswer(input: $input, condition: $condition) {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketQuickAnswerMutation>(
      response.data.updateTicketQuickAnswer
    );
  }
  async DeleteTicketQuickAnswer(
    input: DeleteTicketQuickAnswerInput,
    condition?: ModelTicketQuickAnswerConditionInput
  ): Promise<DeleteTicketQuickAnswerMutation> {
    const statement = `mutation DeleteTicketQuickAnswer($input: DeleteTicketQuickAnswerInput!, $condition: ModelTicketQuickAnswerConditionInput) {
        deleteTicketQuickAnswer(input: $input, condition: $condition) {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketQuickAnswerMutation>(
      response.data.deleteTicketQuickAnswer
    );
  }
  async CreateTicketMessage(
    input: CreateTicketMessageInput,
    condition?: ModelTicketMessageConditionInput
  ): Promise<CreateTicketMessageMutation> {
    const statement = `mutation CreateTicketMessage($input: CreateTicketMessageInput!, $condition: ModelTicketMessageConditionInput) {
        createTicketMessage(input: $input, condition: $condition) {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketMessageMutation>response.data.createTicketMessage;
  }
  async UpdateTicketMessage(
    input: UpdateTicketMessageInput,
    condition?: ModelTicketMessageConditionInput
  ): Promise<UpdateTicketMessageMutation> {
    const statement = `mutation UpdateTicketMessage($input: UpdateTicketMessageInput!, $condition: ModelTicketMessageConditionInput) {
        updateTicketMessage(input: $input, condition: $condition) {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketMessageMutation>response.data.updateTicketMessage;
  }
  async DeleteTicketMessage(
    input: DeleteTicketMessageInput,
    condition?: ModelTicketMessageConditionInput
  ): Promise<DeleteTicketMessageMutation> {
    const statement = `mutation DeleteTicketMessage($input: DeleteTicketMessageInput!, $condition: ModelTicketMessageConditionInput) {
        deleteTicketMessage(input: $input, condition: $condition) {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketMessageMutation>response.data.deleteTicketMessage;
  }
  async CreateTicketDomain(
    input: CreateTicketDomainInput,
    condition?: ModelTicketDomainConditionInput
  ): Promise<CreateTicketDomainMutation> {
    const statement = `mutation CreateTicketDomain($input: CreateTicketDomainInput!, $condition: ModelTicketDomainConditionInput) {
        createTicketDomain(input: $input, condition: $condition) {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketDomainMutation>response.data.createTicketDomain;
  }
  async UpdateTicketDomain(
    input: UpdateTicketDomainInput,
    condition?: ModelTicketDomainConditionInput
  ): Promise<UpdateTicketDomainMutation> {
    const statement = `mutation UpdateTicketDomain($input: UpdateTicketDomainInput!, $condition: ModelTicketDomainConditionInput) {
        updateTicketDomain(input: $input, condition: $condition) {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketDomainMutation>response.data.updateTicketDomain;
  }
  async DeleteTicketDomain(
    input: DeleteTicketDomainInput,
    condition?: ModelTicketDomainConditionInput
  ): Promise<DeleteTicketDomainMutation> {
    const statement = `mutation DeleteTicketDomain($input: DeleteTicketDomainInput!, $condition: ModelTicketDomainConditionInput) {
        deleteTicketDomain(input: $input, condition: $condition) {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketDomainMutation>response.data.deleteTicketDomain;
  }
  async CreateTicketSubDomain(
    input: CreateTicketSubDomainInput,
    condition?: ModelTicketSubDomainConditionInput
  ): Promise<CreateTicketSubDomainMutation> {
    const statement = `mutation CreateTicketSubDomain($input: CreateTicketSubDomainInput!, $condition: ModelTicketSubDomainConditionInput) {
        createTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketSubDomainMutation>response.data.createTicketSubDomain;
  }
  async UpdateTicketSubDomain(
    input: UpdateTicketSubDomainInput,
    condition?: ModelTicketSubDomainConditionInput
  ): Promise<UpdateTicketSubDomainMutation> {
    const statement = `mutation UpdateTicketSubDomain($input: UpdateTicketSubDomainInput!, $condition: ModelTicketSubDomainConditionInput) {
        updateTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketSubDomainMutation>response.data.updateTicketSubDomain;
  }
  async DeleteTicketSubDomain(
    input: DeleteTicketSubDomainInput,
    condition?: ModelTicketSubDomainConditionInput
  ): Promise<DeleteTicketSubDomainMutation> {
    const statement = `mutation DeleteTicketSubDomain($input: DeleteTicketSubDomainInput!, $condition: ModelTicketSubDomainConditionInput) {
        deleteTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketSubDomainMutation>response.data.deleteTicketSubDomain;
  }
  async CreateTicketQuickAnswerTicketSubDomain(
    input: CreateTicketQuickAnswerTicketSubDomainInput,
    condition?: ModelTicketQuickAnswerTicketSubDomainConditionInput
  ): Promise<CreateTicketQuickAnswerTicketSubDomainMutation> {
    const statement = `mutation CreateTicketQuickAnswerTicketSubDomain($input: CreateTicketQuickAnswerTicketSubDomainInput!, $condition: ModelTicketQuickAnswerTicketSubDomainConditionInput) {
        createTicketQuickAnswerTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketQuickAnswerTicketSubDomainMutation>(
      response.data.createTicketQuickAnswerTicketSubDomain
    );
  }
  async UpdateTicketQuickAnswerTicketSubDomain(
    input: UpdateTicketQuickAnswerTicketSubDomainInput,
    condition?: ModelTicketQuickAnswerTicketSubDomainConditionInput
  ): Promise<UpdateTicketQuickAnswerTicketSubDomainMutation> {
    const statement = `mutation UpdateTicketQuickAnswerTicketSubDomain($input: UpdateTicketQuickAnswerTicketSubDomainInput!, $condition: ModelTicketQuickAnswerTicketSubDomainConditionInput) {
        updateTicketQuickAnswerTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketQuickAnswerTicketSubDomainMutation>(
      response.data.updateTicketQuickAnswerTicketSubDomain
    );
  }
  async DeleteTicketQuickAnswerTicketSubDomain(
    input: DeleteTicketQuickAnswerTicketSubDomainInput,
    condition?: ModelTicketQuickAnswerTicketSubDomainConditionInput
  ): Promise<DeleteTicketQuickAnswerTicketSubDomainMutation> {
    const statement = `mutation DeleteTicketQuickAnswerTicketSubDomain($input: DeleteTicketQuickAnswerTicketSubDomainInput!, $condition: ModelTicketQuickAnswerTicketSubDomainConditionInput) {
        deleteTicketQuickAnswerTicketSubDomain(input: $input, condition: $condition) {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketQuickAnswerTicketSubDomainMutation>(
      response.data.deleteTicketQuickAnswerTicketSubDomain
    );
  }
  async CreateTicketAction(
    input: CreateTicketActionInput,
    condition?: ModelTicketActionConditionInput
  ): Promise<CreateTicketActionMutation> {
    const statement = `mutation CreateTicketAction($input: CreateTicketActionInput!, $condition: ModelTicketActionConditionInput) {
        createTicketAction(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketActionMutation>response.data.createTicketAction;
  }
  async UpdateTicketAction(
    input: UpdateTicketActionInput,
    condition?: ModelTicketActionConditionInput
  ): Promise<UpdateTicketActionMutation> {
    const statement = `mutation UpdateTicketAction($input: UpdateTicketActionInput!, $condition: ModelTicketActionConditionInput) {
        updateTicketAction(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketActionMutation>response.data.updateTicketAction;
  }
  async DeleteTicketAction(
    input: DeleteTicketActionInput,
    condition?: ModelTicketActionConditionInput
  ): Promise<DeleteTicketActionMutation> {
    const statement = `mutation DeleteTicketAction($input: DeleteTicketActionInput!, $condition: ModelTicketActionConditionInput) {
        deleteTicketAction(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketActionMutation>response.data.deleteTicketAction;
  }
  async GetSwitchboard(id: string): Promise<GetSwitchboardQuery> {
    const statement = `query GetSwitchboard($id: ID!) {
        getSwitchboard(id: $id) {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSwitchboardQuery>response.data.getSwitchboard;
  }
  async ListSwitchboards(
    filter?: ModelSwitchboardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSwitchboardsQuery> {
    const statement = `query ListSwitchboards($filter: ModelSwitchboardFilterInput, $limit: Int, $nextToken: String) {
        listSwitchboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSwitchboardsQuery>response.data.listSwitchboards;
  }
  async GetCall(id: string): Promise<GetCallQuery> {
    const statement = `query GetCall($id: ID!) {
        getCall(id: $id) {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallQuery>response.data.getCall;
  }
  async ListCalls(
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallsQuery> {
    const statement = `query ListCalls($filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callSid
            from
            to
            direction
            switchboardID
            switchboard {
              __typename
              id
              name
              phone
              logo
              welcomeMessage
              hasSeasonalWelcomeMessage
              winterWelcomeMessage
              springWelcomeMessage
              automnWelcomeMessage
              summerWelcomeMessage
              waitMusic
              recordingMessage
              closeMessage
              openTime
              closeTime
              connectorType
              connectorId
              interactiveVoiceResponseID
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              position
              type
              createdAt
              updatedAt
            }
            userID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            conferenceName
            status
            customer {
              __typename
              id
              firstname
              lastname
              gender
              email
              blocked
              badge
              pareto
              addresses {
                __typename
                id
                firstname
                lastname
                prefix
                street
                street2
                zipcode
                city
                country
                society
                shippingInformations
                phone
                mobilePhone
              }
              createdAt
            }
            pickupAt
            hangupAt
            waitingAt
            completeAt
            orders {
              __typename
              id
              incrementId
              totalAmount
              status
              marketplace
              orderProducts {
                __typename
                name
                qty
              }
              createdAt
            }
            shippings {
              __typename
              id
              status
              createdAt
              tracking
              shippingMethod
            }
            cart {
              __typename
              id
              totalAmount
              cartProducts {
                __typename
                name
                qty
                totalAmount
              }
            }
            callParticipants {
              __typename
              items {
                __typename
                id
                callID
                callSid
                status
                mode
                userID
                createdAt
                updatedAt
              }
              nextToken
            }
            callQualificationID
            callQualification {
              __typename
              id
              type
              name
              position
              createdAt
              updatedAt
            }
            callReasonID
            callReason {
              __typename
              id
              name
              type
              position
              createdAt
              updatedAt
            }
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            callCampaignID
            callCampaign {
              __typename
              id
              name
              objections {
                __typename
                nextToken
              }
              callScriptID
              callScript {
                __typename
                id
                name
                text
                callCampaignID
                createdAt
                updatedAt
              }
              callWidgets {
                __typename
                type
                params
              }
              createdAt
              updatedAt
            }
            recording
            recordingSid
            recordingUrl
            voicemail
            voicemailRead
            turnOver
            orderId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallsQuery>response.data.listCalls;
  }
  async GetCallParticipant(id: string): Promise<GetCallParticipantQuery> {
    const statement = `query GetCallParticipant($id: ID!) {
        getCallParticipant(id: $id) {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallParticipantQuery>response.data.getCallParticipant;
  }
  async ListCallParticipants(
    filter?: ModelCallParticipantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallParticipantsQuery> {
    const statement = `query ListCallParticipants($filter: ModelCallParticipantFilterInput, $limit: Int, $nextToken: String) {
        listCallParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callID
            callSid
            status
            mode
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallParticipantsQuery>response.data.listCallParticipants;
  }
  async GetCallQualification(id: string): Promise<GetCallQualificationQuery> {
    const statement = `query GetCallQualification($id: ID!) {
        getCallQualification(id: $id) {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallQualificationQuery>response.data.getCallQualification;
  }
  async ListCallQualifications(
    filter?: ModelCallQualificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallQualificationsQuery> {
    const statement = `query ListCallQualifications($filter: ModelCallQualificationFilterInput, $limit: Int, $nextToken: String) {
        listCallQualifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallQualificationsQuery>response.data.listCallQualifications;
  }
  async GetCallReason(id: string): Promise<GetCallReasonQuery> {
    const statement = `query GetCallReason($id: ID!) {
        getCallReason(id: $id) {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallReasonQuery>response.data.getCallReason;
  }
  async ListCallReasons(
    filter?: ModelCallReasonFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallReasonsQuery> {
    const statement = `query ListCallReasons($filter: ModelCallReasonFilterInput, $limit: Int, $nextToken: String) {
        listCallReasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallReasonsQuery>response.data.listCallReasons;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetInteractiveVoiceResponse(
    id: string
  ): Promise<GetInteractiveVoiceResponseQuery> {
    const statement = `query GetInteractiveVoiceResponse($id: ID!) {
        getInteractiveVoiceResponse(id: $id) {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInteractiveVoiceResponseQuery>(
      response.data.getInteractiveVoiceResponse
    );
  }
  async ListInteractiveVoiceResponses(
    filter?: ModelInteractiveVoiceResponseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInteractiveVoiceResponsesQuery> {
    const statement = `query ListInteractiveVoiceResponses($filter: ModelInteractiveVoiceResponseFilterInput, $limit: Int, $nextToken: String) {
        listInteractiveVoiceResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInteractiveVoiceResponsesQuery>(
      response.data.listInteractiveVoiceResponses
    );
  }
  async GetInteractiveVoiceResponseUser(
    id: string
  ): Promise<GetInteractiveVoiceResponseUserQuery> {
    const statement = `query GetInteractiveVoiceResponseUser($id: ID!) {
        getInteractiveVoiceResponseUser(id: $id) {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInteractiveVoiceResponseUserQuery>(
      response.data.getInteractiveVoiceResponseUser
    );
  }
  async ListInteractiveVoiceResponseUsers(
    filter?: ModelInteractiveVoiceResponseUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInteractiveVoiceResponseUsersQuery> {
    const statement = `query ListInteractiveVoiceResponseUsers($filter: ModelInteractiveVoiceResponseUserFilterInput, $limit: Int, $nextToken: String) {
        listInteractiveVoiceResponseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userID
            interactiveVoiceResponseID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInteractiveVoiceResponseUsersQuery>(
      response.data.listInteractiveVoiceResponseUsers
    );
  }
  async GetCallCampaign(id: string): Promise<GetCallCampaignQuery> {
    const statement = `query GetCallCampaign($id: ID!) {
        getCallCampaign(id: $id) {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallCampaignQuery>response.data.getCallCampaign;
  }
  async ListCallCampaigns(
    filter?: ModelCallCampaignFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallCampaignsQuery> {
    const statement = `query ListCallCampaigns($filter: ModelCallCampaignFilterInput, $limit: Int, $nextToken: String) {
        listCallCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallCampaignsQuery>response.data.listCallCampaigns;
  }
  async GetCallCampaignCustomer(
    id: string
  ): Promise<GetCallCampaignCustomerQuery> {
    const statement = `query GetCallCampaignCustomer($id: ID!) {
        getCallCampaignCustomer(id: $id) {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallCampaignCustomerQuery>response.data.getCallCampaignCustomer;
  }
  async ListCallCampaignCustomers(
    filter?: ModelCallCampaignCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallCampaignCustomersQuery> {
    const statement = `query ListCallCampaignCustomers($filter: ModelCallCampaignCustomerFilterInput, $limit: Int, $nextToken: String) {
        listCallCampaignCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callCampaignID
            customerId
            name
            status
            phone
            plannedAt
            calledAt
            callID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallCampaignCustomersQuery>(
      response.data.listCallCampaignCustomers
    );
  }
  async GetCallObjection(id: string): Promise<GetCallObjectionQuery> {
    const statement = `query GetCallObjection($id: ID!) {
        getCallObjection(id: $id) {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallObjectionQuery>response.data.getCallObjection;
  }
  async ListCallObjections(
    filter?: ModelCallObjectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallObjectionsQuery> {
    const statement = `query ListCallObjections($filter: ModelCallObjectionFilterInput, $limit: Int, $nextToken: String) {
        listCallObjections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callCampaignID
            title
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallObjectionsQuery>response.data.listCallObjections;
  }
  async GetCallScript(id: string): Promise<GetCallScriptQuery> {
    const statement = `query GetCallScript($id: ID!) {
        getCallScript(id: $id) {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallScriptQuery>response.data.getCallScript;
  }
  async ListCallScripts(
    filter?: ModelCallScriptFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallScriptsQuery> {
    const statement = `query ListCallScripts($filter: ModelCallScriptFilterInput, $limit: Int, $nextToken: String) {
        listCallScripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallScriptsQuery>response.data.listCallScripts;
  }
  async GetCallScriptRelation(id: string): Promise<GetCallScriptRelationQuery> {
    const statement = `query GetCallScriptRelation($id: ID!) {
        getCallScriptRelation(id: $id) {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallScriptRelationQuery>response.data.getCallScriptRelation;
  }
  async ListCallScriptRelations(
    filter?: ModelCallScriptRelationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallScriptRelationsQuery> {
    const statement = `query ListCallScriptRelations($filter: ModelCallScriptRelationFilterInput, $limit: Int, $nextToken: String) {
        listCallScriptRelations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            answer
            sourceCallScriptID
            targetCallScriptID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallScriptRelationsQuery>response.data.listCallScriptRelations;
  }
  async GetContact(id: string): Promise<GetContactQuery> {
    const statement = `query GetContact($id: ID!) {
        getContact(id: $id) {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactQuery>response.data.getContact;
  }
  async ListContacts(
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactsQuery> {
    const statement = `query ListContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            firstname
            lastname
            search
            email
            phone
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactsQuery>response.data.listContacts;
  }
  async GetTicketBridge(id: string): Promise<GetTicketBridgeQuery> {
    const statement = `query GetTicketBridge($id: ID!) {
        getTicketBridge(id: $id) {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketBridgeQuery>response.data.getTicketBridge;
  }
  async ListTicketBridges(
    filter?: ModelTicketBridgeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketBridgesQuery> {
    const statement = `query ListTicketBridges($filter: ModelTicketBridgeFilterInput, $limit: Int, $nextToken: String) {
        listTicketBridges(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            password
            host
            port
            connectorType
            connectorId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketBridgesQuery>response.data.listTicketBridges;
  }
  async GetTicket(id: string): Promise<GetTicketQuery> {
    const statement = `query GetTicket($id: ID!) {
        getTicket(id: $id) {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketQuery>response.data.getTicket;
  }
  async ListTickets(
    filter?: ModelTicketFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketsQuery> {
    const statement = `query ListTickets($filter: ModelTicketFilterInput, $limit: Int, $nextToken: String) {
        listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            subject
            messages {
              __typename
              items {
                __typename
                id
                ticketID
                email
                text
                html
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketsQuery>response.data.listTickets;
  }
  async GetTicketQuickAnswer(id: string): Promise<GetTicketQuickAnswerQuery> {
    const statement = `query GetTicketQuickAnswer($id: ID!) {
        getTicketQuickAnswer(id: $id) {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketQuickAnswerQuery>response.data.getTicketQuickAnswer;
  }
  async ListTicketQuickAnswers(
    filter?: ModelTicketQuickAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketQuickAnswersQuery> {
    const statement = `query ListTicketQuickAnswers($filter: ModelTicketQuickAnswerFilterInput, $limit: Int, $nextToken: String) {
        listTicketQuickAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketQuickAnswersQuery>response.data.listTicketQuickAnswers;
  }
  async GetTicketMessage(id: string): Promise<GetTicketMessageQuery> {
    const statement = `query GetTicketMessage($id: ID!) {
        getTicketMessage(id: $id) {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketMessageQuery>response.data.getTicketMessage;
  }
  async ListTicketMessages(
    filter?: ModelTicketMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketMessagesQuery> {
    const statement = `query ListTicketMessages($filter: ModelTicketMessageFilterInput, $limit: Int, $nextToken: String) {
        listTicketMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ticketID
            email
            text
            html
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketMessagesQuery>response.data.listTicketMessages;
  }
  async GetTicketDomain(id: string): Promise<GetTicketDomainQuery> {
    const statement = `query GetTicketDomain($id: ID!) {
        getTicketDomain(id: $id) {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketDomainQuery>response.data.getTicketDomain;
  }
  async ListTicketDomains(
    filter?: ModelTicketDomainFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketDomainsQuery> {
    const statement = `query ListTicketDomains($filter: ModelTicketDomainFilterInput, $limit: Int, $nextToken: String) {
        listTicketDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            subdomains {
              __typename
              items {
                __typename
                id
                ticketDomainID
                name
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketDomainsQuery>response.data.listTicketDomains;
  }
  async GetTicketSubDomain(id: string): Promise<GetTicketSubDomainQuery> {
    const statement = `query GetTicketSubDomain($id: ID!) {
        getTicketSubDomain(id: $id) {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketSubDomainQuery>response.data.getTicketSubDomain;
  }
  async ListTicketSubDomains(
    filter?: ModelTicketSubDomainFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketSubDomainsQuery> {
    const statement = `query ListTicketSubDomains($filter: ModelTicketSubDomainFilterInput, $limit: Int, $nextToken: String) {
        listTicketSubDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ticketDomainID
            name
            ticketQuickAnswers {
              __typename
              items {
                __typename
                id
                ticketQuickAnswerID
                ticketSubDomainID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketSubDomainsQuery>response.data.listTicketSubDomains;
  }
  async GetTicketQuickAnswerTicketSubDomain(
    id: string
  ): Promise<GetTicketQuickAnswerTicketSubDomainQuery> {
    const statement = `query GetTicketQuickAnswerTicketSubDomain($id: ID!) {
        getTicketQuickAnswerTicketSubDomain(id: $id) {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketQuickAnswerTicketSubDomainQuery>(
      response.data.getTicketQuickAnswerTicketSubDomain
    );
  }
  async ListTicketQuickAnswerTicketSubDomains(
    filter?: ModelTicketQuickAnswerTicketSubDomainFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketQuickAnswerTicketSubDomainsQuery> {
    const statement = `query ListTicketQuickAnswerTicketSubDomains($filter: ModelTicketQuickAnswerTicketSubDomainFilterInput, $limit: Int, $nextToken: String) {
        listTicketQuickAnswerTicketSubDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ticketQuickAnswerID
            ticketSubDomainID
            ticketQuickAnswer {
              __typename
              id
              name
              message
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketQuickAnswerTicketSubDomainsQuery>(
      response.data.listTicketQuickAnswerTicketSubDomains
    );
  }
  async GetTicketAction(id: string): Promise<GetTicketActionQuery> {
    const statement = `query GetTicketAction($id: ID!) {
        getTicketAction(id: $id) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketActionQuery>response.data.getTicketAction;
  }
  async ListTicketActions(
    filter?: ModelTicketActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketActionsQuery> {
    const statement = `query ListTicketActions($filter: ModelTicketActionFilterInput, $limit: Int, $nextToken: String) {
        listTicketActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketActionsQuery>response.data.listTicketActions;
  }
  async SwitchboardByPhone(
    phone?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSwitchboardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SwitchboardByPhoneQuery> {
    const statement = `query SwitchboardByPhone($phone: String, $sortDirection: ModelSortDirection, $filter: ModelSwitchboardFilterInput, $limit: Int, $nextToken: String) {
        switchboardByPhone(phone: $phone, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (phone) {
      gqlAPIServiceArguments.phone = phone;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SwitchboardByPhoneQuery>response.data.switchboardByPhone;
  }
  async SwitchboardByPosition(
    type?: string,
    position?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSwitchboardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SwitchboardByPositionQuery> {
    const statement = `query SwitchboardByPosition($type: String, $position: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSwitchboardFilterInput, $limit: Int, $nextToken: String) {
        switchboardByPosition(type: $type, position: $position, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (position) {
      gqlAPIServiceArguments.position = position;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SwitchboardByPositionQuery>response.data.switchboardByPosition;
  }
  async CallByCallSid(
    callSid?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallByCallSidQuery> {
    const statement = `query CallByCallSid($callSid: String, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callByCallSid(callSid: $callSid, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callSid
            from
            to
            direction
            switchboardID
            switchboard {
              __typename
              id
              name
              phone
              logo
              welcomeMessage
              hasSeasonalWelcomeMessage
              winterWelcomeMessage
              springWelcomeMessage
              automnWelcomeMessage
              summerWelcomeMessage
              waitMusic
              recordingMessage
              closeMessage
              openTime
              closeTime
              connectorType
              connectorId
              interactiveVoiceResponseID
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              position
              type
              createdAt
              updatedAt
            }
            userID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            conferenceName
            status
            customer {
              __typename
              id
              firstname
              lastname
              gender
              email
              blocked
              badge
              pareto
              addresses {
                __typename
                id
                firstname
                lastname
                prefix
                street
                street2
                zipcode
                city
                country
                society
                shippingInformations
                phone
                mobilePhone
              }
              createdAt
            }
            pickupAt
            hangupAt
            waitingAt
            completeAt
            orders {
              __typename
              id
              incrementId
              totalAmount
              status
              marketplace
              orderProducts {
                __typename
                name
                qty
              }
              createdAt
            }
            shippings {
              __typename
              id
              status
              createdAt
              tracking
              shippingMethod
            }
            cart {
              __typename
              id
              totalAmount
              cartProducts {
                __typename
                name
                qty
                totalAmount
              }
            }
            callParticipants {
              __typename
              items {
                __typename
                id
                callID
                callSid
                status
                mode
                userID
                createdAt
                updatedAt
              }
              nextToken
            }
            callQualificationID
            callQualification {
              __typename
              id
              type
              name
              position
              createdAt
              updatedAt
            }
            callReasonID
            callReason {
              __typename
              id
              name
              type
              position
              createdAt
              updatedAt
            }
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            callCampaignID
            callCampaign {
              __typename
              id
              name
              objections {
                __typename
                nextToken
              }
              callScriptID
              callScript {
                __typename
                id
                name
                text
                callCampaignID
                createdAt
                updatedAt
              }
              callWidgets {
                __typename
                type
                params
              }
              createdAt
              updatedAt
            }
            recording
            recordingSid
            recordingUrl
            voicemail
            voicemailRead
            turnOver
            orderId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (callSid) {
      gqlAPIServiceArguments.callSid = callSid;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallByCallSidQuery>response.data.callByCallSid;
  }
  async CallByStatusAndUser(
    status?: string,
    userID?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallByStatusAndUserQuery> {
    const statement = `query CallByStatusAndUser($status: String, $userID: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callByStatusAndUser(status: $status, userID: $userID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callSid
            from
            to
            direction
            switchboardID
            switchboard {
              __typename
              id
              name
              phone
              logo
              welcomeMessage
              hasSeasonalWelcomeMessage
              winterWelcomeMessage
              springWelcomeMessage
              automnWelcomeMessage
              summerWelcomeMessage
              waitMusic
              recordingMessage
              closeMessage
              openTime
              closeTime
              connectorType
              connectorId
              interactiveVoiceResponseID
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              position
              type
              createdAt
              updatedAt
            }
            userID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            conferenceName
            status
            customer {
              __typename
              id
              firstname
              lastname
              gender
              email
              blocked
              badge
              pareto
              addresses {
                __typename
                id
                firstname
                lastname
                prefix
                street
                street2
                zipcode
                city
                country
                society
                shippingInformations
                phone
                mobilePhone
              }
              createdAt
            }
            pickupAt
            hangupAt
            waitingAt
            completeAt
            orders {
              __typename
              id
              incrementId
              totalAmount
              status
              marketplace
              orderProducts {
                __typename
                name
                qty
              }
              createdAt
            }
            shippings {
              __typename
              id
              status
              createdAt
              tracking
              shippingMethod
            }
            cart {
              __typename
              id
              totalAmount
              cartProducts {
                __typename
                name
                qty
                totalAmount
              }
            }
            callParticipants {
              __typename
              items {
                __typename
                id
                callID
                callSid
                status
                mode
                userID
                createdAt
                updatedAt
              }
              nextToken
            }
            callQualificationID
            callQualification {
              __typename
              id
              type
              name
              position
              createdAt
              updatedAt
            }
            callReasonID
            callReason {
              __typename
              id
              name
              type
              position
              createdAt
              updatedAt
            }
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            callCampaignID
            callCampaign {
              __typename
              id
              name
              objections {
                __typename
                nextToken
              }
              callScriptID
              callScript {
                __typename
                id
                name
                text
                callCampaignID
                createdAt
                updatedAt
              }
              callWidgets {
                __typename
                type
                params
              }
              createdAt
              updatedAt
            }
            recording
            recordingSid
            recordingUrl
            voicemail
            voicemailRead
            turnOver
            orderId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (userID) {
      gqlAPIServiceArguments.userID = userID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallByStatusAndUserQuery>response.data.callByStatusAndUser;
  }
  async CallByConferenceName(
    conferenceName?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallByConferenceNameQuery> {
    const statement = `query CallByConferenceName($conferenceName: String, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callByConferenceName(conferenceName: $conferenceName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callSid
            from
            to
            direction
            switchboardID
            switchboard {
              __typename
              id
              name
              phone
              logo
              welcomeMessage
              hasSeasonalWelcomeMessage
              winterWelcomeMessage
              springWelcomeMessage
              automnWelcomeMessage
              summerWelcomeMessage
              waitMusic
              recordingMessage
              closeMessage
              openTime
              closeTime
              connectorType
              connectorId
              interactiveVoiceResponseID
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              position
              type
              createdAt
              updatedAt
            }
            userID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            conferenceName
            status
            customer {
              __typename
              id
              firstname
              lastname
              gender
              email
              blocked
              badge
              pareto
              addresses {
                __typename
                id
                firstname
                lastname
                prefix
                street
                street2
                zipcode
                city
                country
                society
                shippingInformations
                phone
                mobilePhone
              }
              createdAt
            }
            pickupAt
            hangupAt
            waitingAt
            completeAt
            orders {
              __typename
              id
              incrementId
              totalAmount
              status
              marketplace
              orderProducts {
                __typename
                name
                qty
              }
              createdAt
            }
            shippings {
              __typename
              id
              status
              createdAt
              tracking
              shippingMethod
            }
            cart {
              __typename
              id
              totalAmount
              cartProducts {
                __typename
                name
                qty
                totalAmount
              }
            }
            callParticipants {
              __typename
              items {
                __typename
                id
                callID
                callSid
                status
                mode
                userID
                createdAt
                updatedAt
              }
              nextToken
            }
            callQualificationID
            callQualification {
              __typename
              id
              type
              name
              position
              createdAt
              updatedAt
            }
            callReasonID
            callReason {
              __typename
              id
              name
              type
              position
              createdAt
              updatedAt
            }
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            callCampaignID
            callCampaign {
              __typename
              id
              name
              objections {
                __typename
                nextToken
              }
              callScriptID
              callScript {
                __typename
                id
                name
                text
                callCampaignID
                createdAt
                updatedAt
              }
              callWidgets {
                __typename
                type
                params
              }
              createdAt
              updatedAt
            }
            recording
            recordingSid
            recordingUrl
            voicemail
            voicemailRead
            turnOver
            orderId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (conferenceName) {
      gqlAPIServiceArguments.conferenceName = conferenceName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallByConferenceNameQuery>response.data.callByConferenceName;
  }
  async CallByDate(
    date?: string,
    id?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallByDateQuery> {
    const statement = `query CallByDate($date: String, $id: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallFilterInput, $limit: Int, $nextToken: String) {
        callByDate(date: $date, id: $id, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callSid
            from
            to
            direction
            switchboardID
            switchboard {
              __typename
              id
              name
              phone
              logo
              welcomeMessage
              hasSeasonalWelcomeMessage
              winterWelcomeMessage
              springWelcomeMessage
              automnWelcomeMessage
              summerWelcomeMessage
              waitMusic
              recordingMessage
              closeMessage
              openTime
              closeTime
              connectorType
              connectorId
              interactiveVoiceResponseID
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              position
              type
              createdAt
              updatedAt
            }
            userID
            user {
              __typename
              id
              username
              admin
              slackID
              phone
              workerSid
              activitySid
              available
              firstname
              lastname
              email
              avatar
              gender
              voicemailMessage
              taskQueueSid
              workflowSid
              interactiveVoiceResponses {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            conferenceName
            status
            customer {
              __typename
              id
              firstname
              lastname
              gender
              email
              blocked
              badge
              pareto
              addresses {
                __typename
                id
                firstname
                lastname
                prefix
                street
                street2
                zipcode
                city
                country
                society
                shippingInformations
                phone
                mobilePhone
              }
              createdAt
            }
            pickupAt
            hangupAt
            waitingAt
            completeAt
            orders {
              __typename
              id
              incrementId
              totalAmount
              status
              marketplace
              orderProducts {
                __typename
                name
                qty
              }
              createdAt
            }
            shippings {
              __typename
              id
              status
              createdAt
              tracking
              shippingMethod
            }
            cart {
              __typename
              id
              totalAmount
              cartProducts {
                __typename
                name
                qty
                totalAmount
              }
            }
            callParticipants {
              __typename
              items {
                __typename
                id
                callID
                callSid
                status
                mode
                userID
                createdAt
                updatedAt
              }
              nextToken
            }
            callQualificationID
            callQualification {
              __typename
              id
              type
              name
              position
              createdAt
              updatedAt
            }
            callReasonID
            callReason {
              __typename
              id
              name
              type
              position
              createdAt
              updatedAt
            }
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            callCampaignID
            callCampaign {
              __typename
              id
              name
              objections {
                __typename
                nextToken
              }
              callScriptID
              callScript {
                __typename
                id
                name
                text
                callCampaignID
                createdAt
                updatedAt
              }
              callWidgets {
                __typename
                type
                params
              }
              createdAt
              updatedAt
            }
            recording
            recordingSid
            recordingUrl
            voicemail
            voicemailRead
            turnOver
            orderId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallByDateQuery>response.data.callByDate;
  }
  async CallParticipantByCallSid(
    callSid?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallParticipantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallParticipantByCallSidQuery> {
    const statement = `query CallParticipantByCallSid($callSid: String, $sortDirection: ModelSortDirection, $filter: ModelCallParticipantFilterInput, $limit: Int, $nextToken: String) {
        callParticipantByCallSid(callSid: $callSid, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callID
            callSid
            status
            mode
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (callSid) {
      gqlAPIServiceArguments.callSid = callSid;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallParticipantByCallSidQuery>(
      response.data.callParticipantByCallSid
    );
  }
  async CallQualificationsByPosition(
    type?: string,
    position?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallQualificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallQualificationsByPositionQuery> {
    const statement = `query CallQualificationsByPosition($type: String, $position: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallQualificationFilterInput, $limit: Int, $nextToken: String) {
        callQualificationsByPosition(type: $type, position: $position, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (position) {
      gqlAPIServiceArguments.position = position;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallQualificationsByPositionQuery>(
      response.data.callQualificationsByPosition
    );
  }
  async CallReasonsByPosition(
    type?: string,
    position?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallReasonFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallReasonsByPositionQuery> {
    const statement = `query CallReasonsByPosition($type: String, $position: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallReasonFilterInput, $limit: Int, $nextToken: String) {
        callReasonsByPosition(type: $type, position: $position, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (position) {
      gqlAPIServiceArguments.position = position;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallReasonsByPositionQuery>response.data.callReasonsByPosition;
  }
  async UserByWorkerSid(
    workerSid?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByWorkerSidQuery> {
    const statement = `query UserByWorkerSid($workerSid: String, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByWorkerSid(workerSid: $workerSid, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (workerSid) {
      gqlAPIServiceArguments.workerSid = workerSid;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByWorkerSidQuery>response.data.userByWorkerSid;
  }
  async UserByPhone(
    phone?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByPhoneQuery> {
    const statement = `query UserByPhone($phone: String, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByPhone(phone: $phone, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (phone) {
      gqlAPIServiceArguments.phone = phone;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByPhoneQuery>response.data.userByPhone;
  }
  async InteractiveVoiceResponseByParentID(
    parentID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInteractiveVoiceResponseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InteractiveVoiceResponseByParentIDQuery> {
    const statement = `query InteractiveVoiceResponseByParentID($parentID: ID, $sortDirection: ModelSortDirection, $filter: ModelInteractiveVoiceResponseFilterInput, $limit: Int, $nextToken: String) {
        interactiveVoiceResponseByParentID(parentID: $parentID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (parentID) {
      gqlAPIServiceArguments.parentID = parentID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InteractiveVoiceResponseByParentIDQuery>(
      response.data.interactiveVoiceResponseByParentID
    );
  }
  async ContactByUserID(
    userID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ContactByUserIDQuery> {
    const statement = `query ContactByUserID($userID: ID, $sortDirection: ModelSortDirection, $filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        contactByUserID(userID: $userID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            firstname
            lastname
            search
            email
            phone
            userID
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userID) {
      gqlAPIServiceArguments.userID = userID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ContactByUserIDQuery>response.data.contactByUserID;
  }
  OnCreateSwitchboardListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSwitchboard">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSwitchboard {
        onCreateSwitchboard {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSwitchboard">>
  >;

  OnUpdateSwitchboardListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSwitchboard">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSwitchboard {
        onUpdateSwitchboard {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSwitchboard">>
  >;

  OnDeleteSwitchboardListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSwitchboard">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSwitchboard {
        onDeleteSwitchboard {
          __typename
          id
          name
          phone
          logo
          welcomeMessage
          hasSeasonalWelcomeMessage
          winterWelcomeMessage
          springWelcomeMessage
          automnWelcomeMessage
          summerWelcomeMessage
          waitMusic
          recordingMessage
          closeMessage
          openTime
          closeTime
          connectorType
          connectorId
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          position
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSwitchboard">>
  >;

  OnCreateCallListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCall">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCall {
        onCreateCall {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCall">>
  >;

  OnUpdateCallListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCall">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCall {
        onUpdateCall {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCall">>
  >;

  OnDeleteCallListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCall">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCall {
        onDeleteCall {
          __typename
          id
          callSid
          from
          to
          direction
          switchboardID
          switchboard {
            __typename
            id
            name
            phone
            logo
            welcomeMessage
            hasSeasonalWelcomeMessage
            winterWelcomeMessage
            springWelcomeMessage
            automnWelcomeMessage
            summerWelcomeMessage
            waitMusic
            recordingMessage
            closeMessage
            openTime
            closeTime
            connectorType
            connectorId
            interactiveVoiceResponseID
            interactiveVoiceResponse {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            position
            type
            createdAt
            updatedAt
          }
          userID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          conferenceName
          status
          customer {
            __typename
            id
            firstname
            lastname
            gender
            email
            blocked
            badge
            pareto
            addresses {
              __typename
              id
              firstname
              lastname
              prefix
              street
              street2
              zipcode
              city
              country
              society
              shippingInformations
              phone
              mobilePhone
            }
            createdAt
          }
          pickupAt
          hangupAt
          waitingAt
          completeAt
          orders {
            __typename
            id
            incrementId
            totalAmount
            status
            marketplace
            orderProducts {
              __typename
              name
              qty
            }
            createdAt
          }
          shippings {
            __typename
            id
            status
            createdAt
            tracking
            shippingMethod
          }
          cart {
            __typename
            id
            totalAmount
            cartProducts {
              __typename
              name
              qty
              totalAmount
            }
          }
          callParticipants {
            __typename
            items {
              __typename
              id
              callID
              callSid
              status
              mode
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          callQualificationID
          callQualification {
            __typename
            id
            type
            name
            position
            createdAt
            updatedAt
          }
          callReasonID
          callReason {
            __typename
            id
            name
            type
            position
            createdAt
            updatedAt
          }
          interactiveVoiceResponseID
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          callCampaignID
          callCampaign {
            __typename
            id
            name
            objections {
              __typename
              items {
                __typename
                id
                callCampaignID
                title
                answer
                createdAt
                updatedAt
              }
              nextToken
            }
            callScriptID
            callScript {
              __typename
              id
              name
              text
              callCampaignID
              createdAt
              updatedAt
            }
            callWidgets {
              __typename
              type
              params
            }
            createdAt
            updatedAt
          }
          recording
          recordingSid
          recordingUrl
          voicemail
          voicemailRead
          turnOver
          orderId
          date
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCall">>
  >;

  OnCreateCallParticipantListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallParticipant">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallParticipant {
        onCreateCallParticipant {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallParticipant">
    >
  >;

  OnUpdateCallParticipantListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallParticipant">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallParticipant {
        onUpdateCallParticipant {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallParticipant">
    >
  >;

  OnDeleteCallParticipantListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallParticipant">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallParticipant {
        onDeleteCallParticipant {
          __typename
          id
          callID
          callSid
          status
          mode
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallParticipant">
    >
  >;

  OnCreateCallQualificationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallQualification">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallQualification {
        onCreateCallQualification {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallQualification">
    >
  >;

  OnUpdateCallQualificationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallQualification">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallQualification {
        onUpdateCallQualification {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallQualification">
    >
  >;

  OnDeleteCallQualificationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallQualification">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallQualification {
        onDeleteCallQualification {
          __typename
          id
          type
          name
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallQualification">
    >
  >;

  OnCreateCallReasonListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallReason">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallReason {
        onCreateCallReason {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallReason">>
  >;

  OnUpdateCallReasonListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallReason">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallReason {
        onUpdateCallReason {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallReason">>
  >;

  OnDeleteCallReasonListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallReason">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallReason {
        onDeleteCallReason {
          __typename
          id
          name
          type
          position
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallReason">>
  >;

  OnCreateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  >;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  >;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          username
          admin
          slackID
          phone
          workerSid
          activitySid
          available
          firstname
          lastname
          email
          avatar
          gender
          voicemailMessage
          taskQueueSid
          workflowSid
          interactiveVoiceResponses {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  >;

  OnCreateInteractiveVoiceResponseListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInteractiveVoiceResponse">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInteractiveVoiceResponse {
        onCreateInteractiveVoiceResponse {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInteractiveVoiceResponse">
    >
  >;

  OnUpdateInteractiveVoiceResponseListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInteractiveVoiceResponse">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInteractiveVoiceResponse {
        onUpdateInteractiveVoiceResponse {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInteractiveVoiceResponse">
    >
  >;

  OnDeleteInteractiveVoiceResponseListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInteractiveVoiceResponse">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInteractiveVoiceResponse {
        onDeleteInteractiveVoiceResponse {
          __typename
          id
          play
          digit
          taskQueueSid
          workflowSid
          callReasonID
          parentID
          parent {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          name
          playText
          users {
            __typename
            items {
              __typename
              id
              userID
              interactiveVoiceResponseID
              user {
                __typename
                id
                username
                admin
                slackID
                phone
                workerSid
                activitySid
                available
                firstname
                lastname
                email
                avatar
                gender
                voicemailMessage
                taskQueueSid
                workflowSid
                createdAt
                updatedAt
              }
              interactiveVoiceResponse {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInteractiveVoiceResponse">
    >
  >;

  OnCreateInteractiveVoiceResponseUserListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInteractiveVoiceResponseUser">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInteractiveVoiceResponseUser {
        onCreateInteractiveVoiceResponseUser {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInteractiveVoiceResponseUser">
    >
  >;

  OnUpdateInteractiveVoiceResponseUserListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInteractiveVoiceResponseUser">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInteractiveVoiceResponseUser {
        onUpdateInteractiveVoiceResponseUser {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInteractiveVoiceResponseUser">
    >
  >;

  OnDeleteInteractiveVoiceResponseUserListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInteractiveVoiceResponseUser">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInteractiveVoiceResponseUser {
        onDeleteInteractiveVoiceResponseUser {
          __typename
          id
          userID
          interactiveVoiceResponseID
          user {
            __typename
            id
            username
            admin
            slackID
            phone
            workerSid
            activitySid
            available
            firstname
            lastname
            email
            avatar
            gender
            voicemailMessage
            taskQueueSid
            workflowSid
            interactiveVoiceResponses {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          interactiveVoiceResponse {
            __typename
            id
            play
            digit
            taskQueueSid
            workflowSid
            callReasonID
            parentID
            parent {
              __typename
              id
              play
              digit
              taskQueueSid
              workflowSid
              callReasonID
              parentID
              parent {
                __typename
                id
                play
                digit
                taskQueueSid
                workflowSid
                callReasonID
                parentID
                name
                playText
                createdAt
                updatedAt
              }
              name
              playText
              users {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            name
            playText
            users {
              __typename
              items {
                __typename
                id
                userID
                interactiveVoiceResponseID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInteractiveVoiceResponseUser">
    >
  >;

  OnCreateCallCampaignListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallCampaign">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallCampaign {
        onCreateCallCampaign {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallCampaign">>
  >;

  OnUpdateCallCampaignListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallCampaign">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallCampaign {
        onUpdateCallCampaign {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallCampaign">>
  >;

  OnDeleteCallCampaignListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallCampaign">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallCampaign {
        onDeleteCallCampaign {
          __typename
          id
          name
          objections {
            __typename
            items {
              __typename
              id
              callCampaignID
              title
              answer
              createdAt
              updatedAt
            }
            nextToken
          }
          callScriptID
          callScript {
            __typename
            id
            name
            text
            callCampaignID
            createdAt
            updatedAt
          }
          callWidgets {
            __typename
            type
            params
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallCampaign">>
  >;

  OnCreateCallCampaignCustomerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallCampaignCustomer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallCampaignCustomer {
        onCreateCallCampaignCustomer {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallCampaignCustomer">
    >
  >;

  OnUpdateCallCampaignCustomerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallCampaignCustomer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallCampaignCustomer {
        onUpdateCallCampaignCustomer {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallCampaignCustomer">
    >
  >;

  OnDeleteCallCampaignCustomerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallCampaignCustomer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallCampaignCustomer {
        onDeleteCallCampaignCustomer {
          __typename
          id
          callCampaignID
          customerId
          name
          status
          phone
          plannedAt
          calledAt
          callID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallCampaignCustomer">
    >
  >;

  OnCreateCallObjectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallObjection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallObjection {
        onCreateCallObjection {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallObjection">>
  >;

  OnUpdateCallObjectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallObjection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallObjection {
        onUpdateCallObjection {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallObjection">>
  >;

  OnDeleteCallObjectionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallObjection">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallObjection {
        onDeleteCallObjection {
          __typename
          id
          callCampaignID
          title
          answer
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallObjection">>
  >;

  OnCreateCallScriptListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallScript">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallScript {
        onCreateCallScript {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallScript">>
  >;

  OnUpdateCallScriptListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallScript">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallScript {
        onUpdateCallScript {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallScript">>
  >;

  OnDeleteCallScriptListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallScript">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallScript {
        onDeleteCallScript {
          __typename
          id
          name
          text
          callCampaignID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallScript">>
  >;

  OnCreateCallScriptRelationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallScriptRelation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallScriptRelation {
        onCreateCallScriptRelation {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCallScriptRelation">
    >
  >;

  OnUpdateCallScriptRelationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallScriptRelation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallScriptRelation {
        onUpdateCallScriptRelation {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCallScriptRelation">
    >
  >;

  OnDeleteCallScriptRelationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallScriptRelation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallScriptRelation {
        onDeleteCallScriptRelation {
          __typename
          id
          answer
          sourceCallScriptID
          targetCallScriptID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCallScriptRelation">
    >
  >;

  OnCreateContactListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateContact {
        onCreateContact {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
  >;

  OnUpdateContactListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateContact {
        onUpdateContact {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
  >;

  OnDeleteContactListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteContact {
        onDeleteContact {
          __typename
          id
          firstname
          lastname
          search
          email
          phone
          userID
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
  >;

  OnCreateTicketBridgeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketBridge">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketBridge {
        onCreateTicketBridge {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketBridge">>
  >;

  OnUpdateTicketBridgeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketBridge">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketBridge {
        onUpdateTicketBridge {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketBridge">>
  >;

  OnDeleteTicketBridgeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketBridge">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketBridge {
        onDeleteTicketBridge {
          __typename
          id
          name
          email
          password
          host
          port
          connectorType
          connectorId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketBridge">>
  >;

  OnCreateTicketListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicket">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicket {
        onCreateTicket {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicket">>
  >;

  OnUpdateTicketListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicket">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicket {
        onUpdateTicket {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicket">>
  >;

  OnDeleteTicketListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicket">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicket {
        onDeleteTicket {
          __typename
          id
          email
          subject
          messages {
            __typename
            items {
              __typename
              id
              ticketID
              email
              text
              html
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicket">>
  >;

  OnCreateTicketQuickAnswerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketQuickAnswer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketQuickAnswer {
        onCreateTicketQuickAnswer {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketQuickAnswer">
    >
  >;

  OnUpdateTicketQuickAnswerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketQuickAnswer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketQuickAnswer {
        onUpdateTicketQuickAnswer {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketQuickAnswer">
    >
  >;

  OnDeleteTicketQuickAnswerListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketQuickAnswer">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketQuickAnswer {
        onDeleteTicketQuickAnswer {
          __typename
          id
          name
          message
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketQuickAnswer">
    >
  >;

  OnCreateTicketMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketMessage {
        onCreateTicketMessage {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketMessage">>
  >;

  OnUpdateTicketMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketMessage {
        onUpdateTicketMessage {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketMessage">>
  >;

  OnDeleteTicketMessageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketMessage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketMessage {
        onDeleteTicketMessage {
          __typename
          id
          ticketID
          email
          text
          html
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketMessage">>
  >;

  OnCreateTicketDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketDomain {
        onCreateTicketDomain {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketDomain">>
  >;

  OnUpdateTicketDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketDomain {
        onUpdateTicketDomain {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketDomain">>
  >;

  OnDeleteTicketDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketDomain {
        onDeleteTicketDomain {
          __typename
          id
          name
          subdomains {
            __typename
            items {
              __typename
              id
              ticketDomainID
              name
              ticketQuickAnswers {
                __typename
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketDomain">>
  >;

  OnCreateTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketSubDomain {
        onCreateTicketSubDomain {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketSubDomain">
    >
  >;

  OnUpdateTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketSubDomain {
        onUpdateTicketSubDomain {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketSubDomain">
    >
  >;

  OnDeleteTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketSubDomain {
        onDeleteTicketSubDomain {
          __typename
          id
          ticketDomainID
          name
          ticketQuickAnswers {
            __typename
            items {
              __typename
              id
              ticketQuickAnswerID
              ticketSubDomainID
              ticketQuickAnswer {
                __typename
                id
                name
                message
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketSubDomain">
    >
  >;

  OnCreateTicketQuickAnswerTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketQuickAnswerTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketQuickAnswerTicketSubDomain {
        onCreateTicketQuickAnswerTicketSubDomain {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTicketQuickAnswerTicketSubDomain">
    >
  >;

  OnUpdateTicketQuickAnswerTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketQuickAnswerTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketQuickAnswerTicketSubDomain {
        onUpdateTicketQuickAnswerTicketSubDomain {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTicketQuickAnswerTicketSubDomain">
    >
  >;

  OnDeleteTicketQuickAnswerTicketSubDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketQuickAnswerTicketSubDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketQuickAnswerTicketSubDomain {
        onDeleteTicketQuickAnswerTicketSubDomain {
          __typename
          id
          ticketQuickAnswerID
          ticketSubDomainID
          ticketQuickAnswer {
            __typename
            id
            name
            message
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTicketQuickAnswerTicketSubDomain">
    >
  >;

  OnCreateTicketActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTicketAction {
        onCreateTicketAction {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketAction">>
  >;

  OnUpdateTicketActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTicketAction {
        onUpdateTicketAction {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketAction">>
  >;

  OnDeleteTicketActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTicketAction {
        onDeleteTicketAction {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketAction">>
  >;
}
