<ng-container *ngIf="selectCallVia == false; else selectCallViaTemplate">    
    <form autocomplete="off" [formGroup]="keypadForm" *ngIf="keypadForm">

        <div class="d-flex justify-content-between">
            <div class="form-group flex-row-fluid">
                <input class="form-control" autocomplete="off" formControlName="number"
                    placeholder="Nom de contact ou numéro...">
            </div>
        </div>

        <div class="row mb-4" *ngIf="contacts.length > 0">
            <div class="col-4" *ngFor="let contact of contacts; let i = index;">
                <div class="text-center my-2 pointer" (click)="selectContact(contact)">
                    <div class="symbol" [ngClass]="{
                        'symbol-light-success': i % 5 == 0,
                        'symbol-light-primary': i % 5 == 1,
                        'symbol-light-danger': i % 5 == 2,
                        'symbol-light-warning': i % 5 == 3,
                        'symbol-light-info': i % 5 == 4
                    }">
                        <span class="symbol-label">
                            <span class="symbol-label font-size-h5">{{ contact.firstname.substring(0,1) }}</span>
                        </span>
                    </div>
                    <div class="text-dark mt-3">{{ contact.firstname }} {{ contact.lastname }}</div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <app-multiselect [parentForm]="keypadForm" [isDisabled]="false" [options]="callReasons" key="callReasonId"
                optionLabel="name" optionValue="id" placeholder="Sélectionnez un motif d'appel" [multiple]="false">
            </app-multiselect>
        </div>

        <div class="d-flex align-items-center justify-content-between rounded bordered p-3 mb-4 pointer"
            (click)="selectCallVia = true">
            <div class="text-muted mr-3">
                APPELLER<br>
                VIA
            </div>
            <div class="d-flex align-items-center justify-content-between flex-row-fluid mr-3">
                <ng-container *ngIf="callVia.type == 'user'">
                    <div class="symbol symbol-50 symbol-light-success mr-3">
                        <span class="symbol-label">
                            <app-user-avatar [user]="callVia.model"></app-user-avatar>
                        </span>
                    </div>
                    <div class="flex-row-fluid mr-3">
                        <span class="font-size-lg text-dark">{{ callVia.model.firstname }} {{ callVia.model.lastname
                            }}</span><br>
                        <span class="text-muted">{{ callVia.model.phone }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="callVia.type == 'switchboard'">
                    <div class="symbol symbol-50 symbol-light-success mr-3">
                        <span class="symbol-label">
                            <app-switchboard-logo [switchboard]="callVia.model"></app-switchboard-logo>
                        </span>
                    </div>
                    <div class="flex-row-fluid mr-3">
                        <span class="font-size-lg text-dark">{{ callVia.model.name }}</span><br>
                        <span class="text-muted">{{ callVia.model.phone }}</span>
                    </div>
                </ng-container>
                <div>
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-icon btn-lg btn-circle btn-primary" (click)="compose()">
                <fa-icon [icon]="faPhone"></fa-icon>
            </button>
        </div>

    </form>
</ng-container>

<ng-template #selectCallViaTemplate>
    <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="font-size-h2">Choisir un numéro</span>
        <button class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="selectCallVia = false">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>

    <div class="d-flex align-items-center justify-content-between rounded bordered p-3 mb-3 pointer"
        (click)="selectCallViaUser(user)">
        <div class="text-muted mr-3">
            APPELLER<br>
            VIA
        </div>
        <div class="d-flex align-items-center justify-content-between flex-row-fluid mr-3">
            <div class="symbol symbol-50 symbol-light-success mr-3">
                <span class="symbol-label">
                    <app-user-avatar [user]="user"></app-user-avatar>
                </span>
            </div>
            <div class="flex-row-fluid  mr-3">
                <span class="font-size-lg text-dark">{{ user.firstname }} {{ user.lastname }}</span><br>
                <span class="text-muted">{{ user.phone }}</span>
            </div>
            <div>
                <fa-icon [icon]="faChevronRight"></fa-icon>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-between rounded bordered p-3 mb-3 pointer"
        *ngFor="let switchboard of switchboards" (click)="selectCallViaSwitchboard(switchboard)">
        <div class="text-muted mr-3">
            APPELLER<br>
            VIA
        </div>
        <div class="d-flex align-items-center justify-content-between flex-row-fluid mr-3">
            <div class="symbol symbol-50 symbol-light-success mr-3">
                <span class="symbol-label">
                    <app-switchboard-logo [switchboard]="switchboard"></app-switchboard-logo>
                </span>
            </div>
            <div class="flex-row-fluid mr-3">
                <span class="font-size-lg text-dark">{{ switchboard.name }}</span><br>
                <span class="text-muted">{{ switchboard.phone }}</span>
            </div>
            <div>
                <fa-icon [icon]="faChevronRight"></fa-icon>
            </div>
        </div>
    </div>
</ng-template>