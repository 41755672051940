<div class="d-flex justify-content-between mb-3">
    <div>
        <div class="mb-2 font-weight-bolder text-dark" [ngSwitch]="call.direction">
            <span *ngSwitchCase="'inbound'">
                <fa-icon [icon]="faArrowCircleRight"></fa-icon> Appel entrant
            </span>
            <span *ngSwitchCase="'outbound'">
                <fa-icon [icon]="faArrowCircleLeft"></fa-icon> Appel sortant
            </span>
        </div>
        <div *ngIf="call.interactiveVoiceResponse" class="mb-2 font-weight-bolder text-dark">{{
            call.interactiveVoiceResponse.name }}</div>
        <app-chronometer [start]="date"></app-chronometer>
    </div>

    <div>
        <button *ngIf="call.callCampaignID" [routerLink]="['/telephony/call-campaigns/' + call.callCampaignID + '/call/' + call.id]" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3">
            <fa-icon [icon]="faComments"></fa-icon>
        </button>

        <button [routerLink]="['/telephony/calls/' + call.id]" class="btn btn-icon btn-light btn-hover-primary btn-sm">
            <fa-icon [icon]="faInfo"></fa-icon>
        </button>
    </div>


</div>

<div class="separator separator-solid mb-3"></div>

<div class="mb-3">
    <div class="font-weight-bolder text-dark font-size-lg">
        <ng-container *ngIf="call.customer; else noCustomer">{{ call.customer.firstname }} {{ call.customer.lastname }}
        </ng-container>
        <ng-template #noCustomer>Inconnu</ng-template>
    </div>
    <div class="text-muted font-size-md">{{ call.direction == 'inbound' ? call.from : call.to }}</div>
</div>

<div class="d-flex align-items-center justify-content-between rounded bordered p-3 mb-3" *ngIf="call.switchboard || call.user">
    <div class="text-muted mr-3">
        APPEL<br>
        VIA
    </div>
    <div class="d-flex align-items-center justify-content-center mr-3">
        <div class="symbol symbol-50 symbol-light mr-3">
            <span class="symbol-label">
                <app-switchboard-logo *ngIf="call.switchboard;" [switchboard]="call.switchboard"></app-switchboard-logo>
                <app-user-avatar *ngIf="!call.switchboard && call.user" [user]="call.user"></app-user-avatar>
            </span>
        </div>
        <div>
            <ng-container *ngIf="call.switchboard; else callViaUser">
                <span class="text-dark">{{ call.switchboard.name }}</span><br>
                <span class="text-muted">{{ call.switchboard.phone }}</span>
            </ng-container>
            <ng-template #callViaUser>
                <span class="text-dark">{{ call.user.firstname }} {{ call.user.lastname }}</span><br>
                <span class="text-muted">{{ call.user.phone }}</span>
            </ng-template>
        </div>
    </div>
</div>