<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <app-navbar [toggled]="toggled"></app-navbar>
        <div class="d-flex flex-column flex-row-fluid wrapper">
            <app-header (navbarToggled)="toggled = !toggled"></app-header>
            <div class="content d-flex flex-column flex-column-fluid">
                <div class="container">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="isLoading"></app-loader>
<app-alert></app-alert>
<app-modal></app-modal>