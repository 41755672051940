import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify'
import { APIService, User } from 'src/app/API.service';
import { TwilioService } from 'src/app/services/twilio.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-assigned-call',
  templateUrl: './assigned-call.component.html',
  styleUrls: ['./assigned-call.component.scss']
})
export class AssignedCallComponent implements OnInit, OnDestroy {
  @Input() reservation: any;
  @Input() user: User;
  faPhone = faPhone;
  faPhoneSlash = faPhoneSlash;
  endpoint: string;
  taskAttributes: any;
  show: boolean = true;
  transferUser: User = null;
  transferMode: string = null;
  incomingAudio: HTMLAudioElement;
  constructor(
    private api: APIService,
    private twilioService: TwilioService
  ) { }

  async ngOnInit() {
    this.endpoint = await API.endpoint('calls');
    this.taskAttributes = this.reservation.task.attributes;

    // IF IT IS A INCOMING TRANSFER
    if (this.reservation.task.transfers.incoming) {
      this.transferMode = this.reservation.task.transfers.incoming.mode;
      this.api.UserByWorkerSid(this.reservation.task.transfers.incoming.workerSid).then((event: any) => {
        this.transferUser = event.items[0];
      });
    }

    if (this.taskAttributes.mode == 'outbound' && !this.reservation.task.transfers.incoming) {
      // CALL IS AUTO ACCEPTED
      this.acceptCallAssignment();
    } else if (this.taskAttributes.mode == 'coaching') {
      this.coach();
    } else {
      this.incomingAudio = new Audio('assets/mp3/incoming.mp3');
      this.incomingAudio.play();
      this.incomingAudio.onended = () => {
        this.incomingAudio.play();
      }
    }
  }

  ngOnDestroy() {
    if(this.incomingAudio) {
      this.incomingAudio.pause();
    }
  }

  async coach() {
    this.show = false;
    this.reservation.call(this.taskAttributes.from,
      this.endpoint + '/calls/coach?conferenceName=' + this.taskAttributes.conferenceName + (this.taskAttributes.coach ? '&coach=' + this.taskAttributes.coach : ''),
      {
        accept: true,
        to: 'client:' + this.user.username +
          '?id=' + this.taskAttributes.id +
          '&mode=' + this.taskAttributes.mode
      });
  }

  rejectCallAssignment() {
    this.show = false
    this.reservation.reject({
      activitySid: environment.offlineSid
    });
  }

  joinConference() {
    this.reservation.call(this.taskAttributes.from,
      this.endpoint + '/calls/coach?conferenceName=' + this.taskAttributes.conferenceName,
      {
        accept: true,
        to: 'client:' + this.user.username +
          '?id=' + this.taskAttributes.id +
          '&mode=' + this.taskAttributes.mode
      });
  }

  async acceptCallAssignment() {
    if (this.taskAttributes.mode == 'joinConference') {
      this.joinConference();
    } else {
      let queryString = '';
      this.show = false;

      if (this.taskAttributes.mode == 'outbound') {
        queryString += '?mode=' + this.taskAttributes.mode +
          '&from=' + this.taskAttributes.from +
          '&to=' + this.taskAttributes.outbound_to +
          '&websiteId=' + this.taskAttributes.websiteId +
          '&switchboardID=' + this.taskAttributes.switchboardID +
          '&taskSid=' + this.reservation.task.sid +
          '&callReasonID=' + this.taskAttributes.callReasonID;

        if (this.taskAttributes.id) {
          queryString += '&id=' + this.taskAttributes.id;
        }

        if (this.taskAttributes.customerId) {
          queryString += '&customerId=' + this.taskAttributes.customerId;
        }

        if (this.taskAttributes.callCampaignID) {
          queryString += '&callCampaignID=' + this.taskAttributes.callCampaignID;
        }

      } else if (this.taskAttributes.mode == 'directInbound' || this.taskAttributes.mode == 'inbound') {
        queryString += '?mode=' + this.taskAttributes.mode +
          '&taskSid=' + this.reservation.task.sid +
          '&callSid=' + this.taskAttributes.call_sid;
      }

      const data: any = {
        to: 'client:' + this.user.username + (queryString.length > 0 ? queryString : ''),
        startConferenceOnEnter: true,
        beepOnCustomerEntrance: false,
        beep: false,
        endConferenceOnCustomerExit: true
      };

      if (this.taskAttributes.recording === true) {
        data.conferenceRecordingStatusCallback = this.endpoint + '/calls/recording';
        data.conferenceRecord = true;
      }

      this.reservation.conference(data);
    }
  }

  closeConnection() {
    this.twilioService.disconnect();
  }

}
