import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { APIService, User } from 'src/app/API.service';
@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
  @Input() user: User;
  users: User[] = [];
  filteredUsers: User[] = [];
  contactForm: FormGroup;
  selectedUser: User = null;
  faTimes = faTimes;

  @Output() canceled: EventEmitter<boolean> = new EventEmitter(false);
  @Output() warmTransfered: EventEmitter<User> = new EventEmitter(null);
  @Output() coldTransfered: EventEmitter<User> = new EventEmitter(null);

  constructor(
    private api: APIService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.api.ListUsers({
      id: {
        ne: this.user.id
      }
    }).then((event: any) => {
      this.users = event.items;
      this.filteredUsers = event.items.slice(0, 5);
    });

    this.contactForm = this.fb.group({
      search: ['', Validators.required],
    });

    this.contactForm.valueChanges.subscribe((value) => {
      this.selectedUser = null;
      const search = value.search;
      this.filteredUsers = this.users.filter((user) => {
        return user.username.toLowerCase().includes(search.toLowerCase());
      }).slice(0, 5);
    });
  }

  toggleSelectedUser(user) {
    if (this.selectedUser && this.selectedUser.id === user.id) {
      this.selectedUser = null;
    } else {
      this.selectedUser = user;
    }
  }

  warmTransfer() {
    this.warmTransfered.emit(this.selectedUser);
  }

  coldTransfer() {
    this.coldTransfered.emit(this.selectedUser);
  }

  cancel() {
    this.canceled.emit(true);
  }
}
