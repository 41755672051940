import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert: {
    type: string,
    message: string
  } = null;

  private emitChangeSource = new BehaviorSubject<any>(this.alert);
  changeEmitted$ = this.emitChangeSource.asObservable();

  constructor() { }

  emitChange(change: {
    type: string,
    message: string
  }) {
    this.emitChangeSource.next(change);
    setTimeout(() => this.emitChangeSource.next(null), 6000);
  }
}
