<div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1" 
    [class.pulse]="pulse"
    (click)="toggled = !toggled">
    <span class="svg-icon svg-icon-xl svg-icon-primary">
        <fa-icon [icon]="faHeadset"></fa-icon>
    </span>
    <span class="pulse-ring" *ngIf="pulse"></span>
</div>
<div *ngIf="toggled" class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg show">
    <div class="d-flex flex-column flex-center pt-12 bgi-size-cover bgi-no-repeat rounded-top">
        <h4 class="text-white font-weight-bold">Téléphone</h4>
        <app-worker-activity [user]="user"></app-worker-activity>

        <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
            role="tablist">
            <li class="nav-item">
                <span class="nav-link" [class.active]="tab == 'call'" (click)="tab = 'call'">
                    <fa-icon [icon]="faPhone"></fa-icon>&nbsp;Appel
                </span>
            </li>
            <li class="nav-item">
                <span class="nav-link" [class.active]="tab == 'calls'" (click)="tab = 'calls'">
                    <fa-icon [icon]="faTasks"></fa-icon>&nbsp;Liste
                </span>
            </li>
            <li class="nav-item">
                <span class="nav-link" [class.active]="tab == 'voicemail'" (click)="tab = 'voicemail'">
                    <fa-icon [icon]="faVoicemail"></fa-icon>
                    &nbsp;Répondeur
                    <span *ngIf="voicemailCalls.length > 0" class="ml-1 badge badge-warning">{{ voicemailCalls.length }}</span>
                </span>
            </li>
        </ul>
    </div>

    <div class="p-4">
        <app-phone-calls *ngIf="tab == 'calls'" [activeCalls]="activeCalls" [user]="user"></app-phone-calls>

        <ng-container *ngIf="tab == 'voicemail'">
            <app-voicemail-call *ngFor="let voicemailCall of voicemailCalls"
                [call]="voicemailCall"
            ></app-voicemail-call>

            <ng-container *ngIf="voicemailCalls.length == 0">
                <p>Aucun message sur le répondeur...</p>
            </ng-container>
        </ng-container>
        
        
        <ng-container *ngIf="tab == 'call'">
            <app-transfer 
                *ngIf="transferUser"
                [user]="user" 
                (canceled)="transferUser = false" 
                (warmTransfered)="warmTransfer($event)"
                (coldTransfered)="coldTransfer($event)"
            >
            </app-transfer>

            <app-phone-add-member 
                *ngIf="addMember"
                [user]="user" 
                (canceled)="addMember = false" 
                (selected)="callAddMember($event)"
            >
            </app-phone-add-member>

            <app-dialpad *ngIf="showDialpad" (canceled)="showDialpad = false" ></app-dialpad>

            <ng-container *ngIf="!addMember && !transferUser && !showDialpad">
                <app-phone-header *ngIf="call" [call]="call" [date]="call.createdAt"></app-phone-header>
            
                <ng-container *ngIf="reservation">
                    <app-assigned-call *ngIf="reservation.status == 'pending'" 
                        [reservation]="reservation" 
                        [user]="user"
                    ></app-assigned-call>
                    <app-ongoing-call *ngIf="reservation.status == 'accepted'" 
                        [reservation]="reservation" 
                        [callConnection]="callConnection" 
                        [call]="call"
                        [user]="user" 
                        [isHolded]="isHolded"
                        (transfer)="transferUser = true"
                        (addMember)="addMember = true"
                        (showDialpad)="showDialpad = true"
                    ></app-ongoing-call>
                    <app-hangup-call *ngIf="reservation.status == 'wrapping'" 
                        [call]="call" 
                        [reservation]="reservation">
                    </app-hangup-call>
                </ng-container>
    
                <app-keypad *ngIf="!call && !reservation" 
                    [user]="user" 
                    [initNumber]="initNumber" 
                    [switchboards]="switchboards"
                ></app-keypad>
            </ng-container>
            
            
        </ng-container>
    </div>
</div>