import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async getUrl(key: string) {
    const result = await Storage.get(key);
    return result;
  }

  async storeFile(directory: string, name: string, file: File) {
    const result = Storage.put(directory + '/' + name + '.' + this.getExtension(file), file, {
      contentType: file.type,
      level: 'public'
    });
    return result;
  }

  getExtension(file: File) {
    return file.name.substr(file.name.lastIndexOf('.') + 1);
  }
}
