import { Component, Input, OnInit } from '@angular/core';
import { faArrowCircleLeft, faArrowCircleRight, faComments, faEye, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Call } from 'src/app/API.service';
@Component({
  selector: 'app-phone-header',
  templateUrl: './phone-header.component.html',
  styleUrls: ['./phone-header.component.scss']
})
export class PhoneHeaderComponent implements OnInit {
  @Input() call: Call;
  @Input() date: string;
  taskAttributes: any;
  faArrowCircleLeft = faArrowCircleLeft;
  faArrowCircleRight = faArrowCircleRight;
  faInfo = faInfo;
  faComments = faComments;
  constructor() { }

  ngOnInit(): void {
  }

}
