import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TwilioService } from 'src/app/services/twilio.service';

@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.scss']
})
export class DialpadComponent implements OnInit {
  faTimes = faTimes;
  @Output() canceled: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private twilioService: TwilioService
  ) { }

  ngOnInit(): void {
  }

  sendDtmf(digit: string) {
    this.twilioService.sendDtmf(digit);
  }

  cancel() {
    this.canceled.emit(true);
  }

}
