<div class="d-flex align-items-center justify-content-between mb-3">
    <span class="font-size-h2">Clavier</span>
    <button class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="cancel()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>

<div class="row">
    <div class="col-4 mb-4">
        <div class="text-center pointer" (click)="sendDtmf('1')">
            <div class="pointer font-size-h1 text-dark">1</div>
            <div class="text-muted"></div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('2')">
            <div class="pointer font-size-h1 text-dark">2</div>
            <div class="text-muted">ABC</div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('3')">
            <div class="pointer font-size-h1 text-dark">3</div>
            <div class="text-muted">DEF</div>
        </div>
    </div>
    <div class="col-4 mb-4">
        <div class="text-center pointer" (click)="sendDtmf('4')">
            <div class="pointer font-size-h1 text-dark">4</div>
            <div class="text-muted">GHI</div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('5')">
            <div class="pointer font-size-h1 text-dark">5</div>
            <div class="text-muted">JKL</div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('6')">
            <div class="pointer font-size-h1 text-dark">6</div>
            <div class="text-muted">MNO</div>
        </div>
    </div>
    <div class="col-4 mb-4">
        <div class="text-center pointer" (click)="sendDtmf('7')">
            <div class="pointer font-size-h1 text-dark">7</div>
            <div class="text-muted">PQRS</div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('8')">
            <div class="pointer font-size-h1 text-dark">8</div>
            <div class="text-muted">TUV</div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('9')">
            <div class="pointer font-size-h1 text-dark">9</div>
            <div class="text-muted">WXYZ</div>
        </div>
    </div>
    <div class="col-4 mb-4">
        <div class="text-center pointer" (click)="sendDtmf('*')">
            <div class="pointer font-size-h1 text-dark">*</div>
            <div class="text-muted"></div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('0')">
            <div class="pointer font-size-h1 text-dark">0</div>
            <div class="text-muted"></div>
        </div>
    </div>
    <div class="col-4">
        <div class="text-center pointer" (click)="sendDtmf('#')">
            <div class="pointer font-size-h1 text-dark">#</div>
            <div class="text-muted"></div>
        </div>
    </div>
</div>