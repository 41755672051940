import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiselectModule } from '../multiselect/multiselect.module';
import { TextEditorModule } from '../text-editor/text-editor.module';



@NgModule({
  declarations: [
    FormComponent
  ],
  imports: [
    CommonModule,
    MultiselectModule,
    TextEditorModule,
    ReactiveFormsModule
  ],
  exports: [
    FormComponent
  ]
})
export class FormModule { }
