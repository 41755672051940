<div class="header header-fixed">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <div class="header-menu-wrapper header-menu-wrapper-left"></div>
        <div class="topbar">
            <div class="dropdown">
                <a class="topbar-item" [routerLink]="['/tv']">
                    <div class="btn btn-icon btn-clean btn-lg mr-1">
                        <span class="svg-icon svg-icon-xl svg-icon-primary">
                            <fa-icon [icon]="faTv"></fa-icon>
                        </span>
                    </div>
                </a>
                <div class="topbar-item">
                    <app-phone></app-phone>
                </div>
                <div class="topbar-item">
                    <div class="btn btn-icon btn-clean btn-lg mr-1" (click)="signOut()">
                        <span class="svg-icon svg-icon-xl svg-icon-primary">
                            <fa-icon [icon]="faPowerOff"></fa-icon>
                        </span>
                    </div>
                </div>
                <div class="topbar-item d-lg-none">
                    <div class="btn btn-icon btn-clean btn-lg mr-1" (click)="toggleNav()">
                        <span class="svg-icon svg-icon-xl svg-icon-primary">
                            <fa-icon [icon]="faBars"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>