import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoaderComponent } from './loader/loader.component';
import { AlertComponent } from './alert/alert.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { PhoneModule } from '../tools/phone/phone.module';
import { ModalComponent } from './modal/modal.component';
import { FormModule } from '../tools/form/form.module';



@NgModule({
  declarations: [
    HeaderComponent,
    LayoutComponent,
    NavbarComponent,
    LoaderComponent,
    AlertComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormModule,
    RouterModule,
    PhoneModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }
