import { Component, ComponentFactoryResolver, HostBinding, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @HostBinding('class') modalClass = 'd-none';
  faTimes = faTimes;
  modal: any = null;
  modalSubscription: Subscription;
  @ViewChild('modalComponent', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  constructor(
    private modalService: ModalService,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.changeEmitted$.subscribe((data) => {
      this.modal = data;
      if (this.modal) {
        const factory = this.resolver.resolveComponentFactory(data.component);
        const dynamicComponent = this.entry.createComponent(factory).instance;
        Object.keys(data.componentProps).map((key) => {
          dynamicComponent[key] = data.componentProps[key];
        });
        this.modalClass = '';
      } else {
        this.modalClass = 'd-none';
        if (this.entry && this.entry.length > 0) {
          this.entry.clear();
        }
      }
    });
  }

  close() {
    this.modalService.emitChange(null);
  }

  ngOnDestroy() {
    this.entry.clear();
    this.modalSubscription.unsubscribe();
  }

}
