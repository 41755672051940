import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneComponent } from './phone.component';
import { KeypadComponent } from './keypad/keypad.component';
import { PhoneCallsComponent } from './phone-calls/phone-calls.component';
import { PhoneAddMemberComponent } from './phone-add-member/phone-add-member.component';
import { TransferComponent } from './transfer/transfer.component';
import { WorkerActivityComponent } from './worker-activity/worker-activity.component';
import { PhoneHeaderComponent } from './phone-header/phone-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ChronometerComponent } from './chronometer/chronometer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AssignedCallComponent } from './assigned-call/assigned-call.component';
import { OngoingCallComponent } from './ongoing-call/ongoing-call.component';
import { HangupCallComponent } from './hangup-call/hangup-call.component';
import { VoicemailCallComponent } from './voicemail-call/voicemail-call.component';
import { AudioPlayerModule } from '../audio-player/audio-player.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { SwitchboardLogoModule } from '../switchboard-logo/switchboard-logo.module';
import { MultiselectModule } from '../multiselect/multiselect.module';
import { DialpadComponent } from './dialpad/dialpad.component';

@NgModule({
  declarations: [
    PhoneComponent,
    AssignedCallComponent,
    OngoingCallComponent,
    HangupCallComponent,
    PhoneHeaderComponent,
    KeypadComponent,
    PhoneCallsComponent,
    PhoneAddMemberComponent,
    TransferComponent,
    WorkerActivityComponent,
    ChronometerComponent,
    VoicemailCallComponent,
    DialpadComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AudioPlayerModule,
    UserAvatarModule,
    SwitchboardLogoModule,
    MultiselectModule,
    RouterModule
  ],
  exports: [
    PhoneComponent
  ]
})
export class PhoneModule { }
