// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: 'https://api.livepoint.fr',
  client: {
    client_id: 3,
    client_secret: 'yHnPJKNd2EDVOS2jVBW54HzsybKPcyZ41gAB8Wrd',
    grant_type: 'client_credentials'
  },
  accountSid: 'AC521efdefb1e9a22efa35d0d8bbf42220',
  authToken: '03f3c118d9d93f9292a35591b182f2ca',
  workspaceSid: 'WSb138d9227bd2632dc3ac16e7545f1327',
  offlineSid: 'WAdbcbbf3789c8a378bd8ed90c066e371a',
  availableSid: 'WA3f03457f9c3124cdb18d935fbe756954',
  unavailableSid: 'WA1b464de35ee27b7328bcb59fcb3dd6fa',
  shippingMethodTrackings: {
    colissimo: 'http://www.colissimo.fr/portail_colissimo/suivre.do?colispart=#',
    chronopost: 'http://www.colissimo.fr/portail_colissimo/suivre.do?colispart=#',
    colissimo_relay: 'http://www.colissimo.fr/portail_colissimo/suivre.do?colispart=#',
    gls: 'https://gls-group.eu/FR/fr/suivi-colis?match=#',
    mondial_relay: 'http://www.mondialrelay.fr/suivi-de-colis/?numeroExpedition=#',
    tnt: 'http://www.tnt.fr/public/suivi_colis/recherche/visubontransport.do?btnSubmit=&radiochoixrecherche=BT&bonTransport=#',
    dpd: 'http://www.dpd.fr/trace/#',
    dpd_predict: 'http://www.dpd.fr/trace/#',
    dpd_relay: 'http://www.dpd.fr/trace/#',
  },
  pusher: {
    //appKey: "b42b2c48bd2892640459"
    appKey: "37ffb086d7e30e93392b"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
