import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { API } from 'aws-amplify';
import { APIService, Call, CallQualification, CallReason, ModelSortDirection } from 'src/app/API.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Reservation } from 'twilio-taskrouter';

@Component({
  selector: 'app-hangup-call',
  templateUrl: './hangup-call.component.html',
  styleUrls: ['./hangup-call.component.scss']
})
export class HangupCallComponent implements OnInit  {
  @Input() reservation: Reservation = null;
  @Input() call: Call = null;
  callQualifications: CallQualification[];
  callReasons: CallReason[];
  qualifyForm: FormGroup;
  constructor(
    private api: APIService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) { }

  async ngOnInit() {
    this.callQualifications = (await this.api.CallQualificationsByPosition('callQualification', { gt: -1 }, ModelSortDirection.ASC)).items;
    this.callReasons = (await this.api.CallReasonsByPosition('callReason', { gt: -1 }, ModelSortDirection.ASC)).items;
    this.qualifyForm = this.fb.group({
      callReasonID: [this.call ? this.call.callReasonID : null, Validators.required],
      callQualificationID: [null, Validators.required],
      incrementId: [null]
    });
  }

  qualify() {
    const attributes = this.reservation.task.attributes;
    attributes.callQualificationID = this.qualifyForm.value.callQualificationID;
    attributes.callReasonID = this.qualifyForm.value.callReasonID;
    attributes.incrementId = this.qualifyForm.value.incrementId ? this.qualifyForm.value.incrementId + "" : null;
    console.log(attributes);
    this.reservation.task.setAttributes(attributes);
    this.reservation.complete();
  }
}
