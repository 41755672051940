<table class="table table-head-custom table-head-bg table-borderless table-vertical-center"
    *ngIf="activeCalls.length; else noCalls;">
    <thead>
        <tr>
            <th>
                <fa-icon [icon]="faInfo"></fa-icon>
            </th>
            <th>
                <fa-icon [icon]="faUser"></fa-icon>
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let activeCall of activeCalls">
            <td>
                <div *ngIf="activeCall.customer">{{ activeCall.customer.firstname }} {{ activeCall.customer.lastname }}
                </div>
                <div>{{ activeCall.from }}</div>
                <div>
                    <span [ngSwitch]="activeCall.status">
                        <span *ngSwitchCase="'welcome'">Message d'accueil</span>
                        <span *ngSwitchCase="'waiting'">Musique d'attente</span>
                        <span *ngSwitchCase="'assigned'">Assigné</span>
                        <span *ngSwitchCase="'ongoing'">En cours</span>
                        <span *ngSwitchCase="'hangup'">Raccroché</span>
                        <span *ngSwitchCase="'complete'">Terminé</span>
                    </span>
                </div>
            </td>
            <td>
                <ng-container *ngIf="activeCall.user">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="symbol symbol-50 symbol-light-success">
                            <span class="symbol-label">
                                <app-user-avatar [user]="activeCall.user"></app-user-avatar>
                            </span>
                        </div>
                        <div class="ml-3">{{ activeCall.user.firstname }}</div>
                    </div>
                </ng-container>
            </td>
            <td>
                <div class="dropdown dropdown-inline ml-2">
                    <button type="button" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                        (click)="toggleDropdown(activeCall)">
                        <fa-icon [icon]="faEllipsisH"></fa-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" [class.show]="dropdownCallId == activeCall.id">
                        <span [routerLink]="['/telephony/calls/' + activeCall.id]" class="dropdown-item">Plus de détails</span>
                        <span *ngIf="activeCall.status == 'ongoing'" (click)="coach(activeCall)"
                            class="dropdown-item">Coacher</span>
                        <span *ngIf="activeCall.status == 'ongoing'" (click)="listen(activeCall)"
                            class="dropdown-item">Ecouter</span>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #noCalls>
    <p>Aucun appel en cours...</p>
</ng-template>