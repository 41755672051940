<div class="modal fade" [class.show]="modal != null">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ modal ? modal.title : ''}}</h5>
                <button type="button" class="close" (click)="close()">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
            <div class="modal-body">
                <ng-container #modalComponent>
                </ng-container>
            </div>
        </div>
    </div>
</div>