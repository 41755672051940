import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modal: any;
  private emitChangeSource = new BehaviorSubject<any>(null);
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor() { }

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
}
