<form *ngIf="qualifyForm" class="mt-3" autocomplete="off" [formGroup]="qualifyForm" (ngSubmit)="qualify()">
    <div class="form-group">
        <app-multiselect 
            [parentForm]="qualifyForm" 
            [isDisabled]="false" 
            [options]="callReasons" 
            key="callReasonID"
            optionLabel="name" 
            optionValue="id"
            placeholder="Sélectionnez un motif d'appel"
            [multiple]="false"
        ></app-multiselect>
    </div>
    <div class="form-group">
        <app-multiselect 
            [parentForm]="qualifyForm" 
            [isDisabled]="false" 
            [options]="callQualifications" 
            key="callQualificationID"
            optionLabel="name" 
            optionValue="id"
            placeholder="Sélectionnez une qualification d'appel"
            [multiple]="false"
        ></app-multiselect>
    </div>
    <div class="form-group">
        <input type="text" class="form-control" formControlName="incrementId" placeholder="Numéro de commande">
    </div>
    
    <button type="submit" class="btn btn-danger btn-block"ssh>Qualifier</button>
</form>
