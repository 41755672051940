import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';

import {  DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { CookieService } from 'ngx-cookie-service';
import { LayoutModule } from './components/layout/layout.module';
import { HomeComponent } from './components/pages/home/home.component';
import { IconboxModule } from './components/tools/iconbox/iconbox.module';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    LayoutModule,
    IconboxModule
  ],
  providers: [
    CookieService,
    DatePipe,
    AuthGuard,
    {
      provide: LOCALE_ID,
      useValue: 'fr_FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
