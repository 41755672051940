import { Component, Input, OnInit } from '@angular/core';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { APIService, Call } from 'src/app/API.service';
import { TwilioService } from 'src/app/services/twilio.service';
@Component({
  selector: 'app-voicemail-call',
  templateUrl: './voicemail-call.component.html',
  styleUrls: ['./voicemail-call.component.scss']
})
export class VoicemailCallComponent implements OnInit {
  @Input() call: Call;
  show: boolean = false;
  faEllipsisH = faEllipsisH;
  constructor(
    private api: APIService,
    private twilioService: TwilioService
  ) { }

  ngOnInit(): void {
  }

  archive() {
    this.api.UpdateCall({
      id: this.call.id,
      voicemailRead: true
    }).then(() => {
      this.show = false;
    });
  }

  callBack() {
    this.twilioService.toCall.next(this.call.from);
  }
}
