import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/API.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TwilioService } from 'src/app/services/twilio.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-worker-activity',
  templateUrl: './worker-activity.component.html',
  styleUrls: ['./worker-activity.component.scss']
})
export class WorkerActivityComponent implements OnInit {
  show: boolean = false;
  @Input() user: User;
  availableSid: string = environment.availableSid;
  offlineSid: string = environment.offlineSid;
  unavailableSid: string = environment.unavailableSid;
  constructor(
    private twilioService: TwilioService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
  }

  setOffline() {
    this.setActivity(this.offlineSid);
  }

  setUnavailable() {
    this.setActivity(this.unavailableSid);
  }

  setAvailable() {
    this.setActivity(this.availableSid);
  }

  setActivity(activitySid) {
    this.loaderService.emitChange(true);
    const activity = this.twilioService.worker.activities.get(activitySid);
    activity.setAsCurrent().then(() => {
      this.show = false;
      this.loaderService.emitChange(false);
    })
  }

}
