import { Component, Input, OnInit } from '@angular/core';
import { Switchboard } from 'src/app/API.service';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-switchboard-logo',
  templateUrl: './switchboard-logo.component.html',
  styleUrls: ['./switchboard-logo.component.scss']
})
export class SwitchboardLogoComponent implements OnInit {
  @Input() switchboard: Switchboard;
  logo: any = null;
  constructor(
    private storageService: StorageService
  ) { }

  async ngOnInit() {
    if (this.switchboard.logo) {
      this.logo = await this.storageService.getUrl(this.switchboard.logo);
    }
  }

}
