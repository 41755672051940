import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alert: any;

  constructor(
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.alertService.changeEmitted$.subscribe(
      (alert: any) => {
        this.alert = alert;
      }
    );
  }

}
