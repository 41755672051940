import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @Input() options: any[];
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() isDisabled: boolean;
  @Input() parentForm: FormGroup;
  @Input() key: string;
  @Input() placeholder: string = '';
  @Input() multiple: boolean = true;

  faTimes = faTimes;

  filteredOptions: any[] = [];
  search: string = '';
  selectedOptions: any[] = [];
  selectedOption: any;

  showDropdownList: boolean = false;
  constructor(
    private eRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdownList = false;
    }
  }

  ngOnInit() {
    this.filteredOptions = this.options;
    const values = this.parentForm.value;
    if (this.multiple == true) {
      if (values[this.key] && values[this.key].length > 0) {
        const selectedOptions = this.options.filter((option) => {
          return values[this.key].includes(option[this.optionValue]);
        });
        this.selectedOptions = selectedOptions;
      }
    } else {
      if (values[this.key]) {
        this.selectedOption = this.options.find((option) => {
          return values[this.key] === option[this.optionValue];
        });
      }
    }
  }

  toggleDropdown($event) {
    $event.preventDefault();
    this.showDropdownList = !this.showDropdownList;
  }

  isSelected(option) {
    if (this.multiple == true) {
      const found = this.selectedOptions.find((selectedOption) => {
        return selectedOption[this.optionValue] === option[this.optionValue];
      });
      return found === undefined ? false : true;
    } else {
      return this.selectedOption && this.selectedOption[this.optionValue] == option[this.optionValue];
    }
  }

  onOptionClick($event, option, close = false) {
    $event.preventDefault();
    $event.stopPropagation();

    if (close) {
      this.showDropdownList = false;
    }
    const found = this.isSelected(option);
    if (!found) {
      this.addSelected(option);
    } else {
      this.removeSelected(option);
    }
  }

  addSelected(option) {
    if (this.multiple == true) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOption = option;
    }
    this.emittedValue();
  }

  removeSelected(option) {
    if (this.multiple == true) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
    } else {
      this.selectedOption = null;
    }
    this.emittedValue();
  }

  trackByFn(index, option) {
    return option[this.optionValue];
  }

  emittedValue(): any {
    let value = {};
    if (this.multiple == true) {
      const options = this.selectedOptions.reduce((carry, selectedOption) => {
        carry.push(selectedOption[this.optionValue]);
        return carry;
      }, []);
      value[this.key] = options;
    } else {
      const option = this.selectedOption ? this.selectedOption[this.optionValue] : null;
      value[this.key] = option;
    }
    this.parentForm.patchValue(value);
  }

  writeValue(value: any) {
    if (this.multiple == true) {
      if (value !== undefined && value !== null && value.length > 0) {
        this.selectedOptions = this.options.filter((option) => {
          return value.includes(option[this.optionValue]);
        });
      } else {
        this.selectedOptions = [];
      }
    } else {
      if (value !== undefined && value !== null) {
        this.selectedOption = this.options.find((option) => {
          return value === option[this.optionValue];
        });
      } else {
        this.selectedOption = null;
      }
    }

    this.emittedValue();
  }

  searchChanged($event) {
    this.filteredOptions = this.options.filter((option) => {
      return option[this.optionLabel].toLowerCase().includes($event.toLowerCase());
    })
  }


}
