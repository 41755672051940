import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBold, faItalic, faList, faListOl, faListUl, faUnderline } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() key: string;

  faBold = faBold;
  faItalic = faItalic;
  faUnderline = faUnderline;
  faListUl = faListUl;
  faListOl = faListOl;
  selection: Selection;
  @ViewChild('content', { static: true }) content: ElementRef;
  text: string = "";

  constructor() { }

  ngOnInit(): void {
    this.text = this.parentForm.value[this.key];
    this.content.nativeElement.innerHTML = this.text;
    this.parentForm.valueChanges.subscribe((test) => {
      if (this.parentForm.value[this.key] != this.text) {
        this.text = this.parentForm.value[this.key];
        this.content.nativeElement.innerHTML = this.text;
      }
    });
  }

  change($event: any) {
    this.text = $event;
    let value = {};
    value[this.key] = this.text;
    this.parentForm.patchValue(value);
  }

  bold() {
    document.execCommand('bold');
  }

  italic() {
    document.execCommand('italic');
  }

  listOl() {
    document.execCommand('insertOrderedList');
  }

  listUl() {
    document.execCommand('insertUnorderedList');
  }

}
