import { Component, OnInit } from '@angular/core';
import { APIService, Call, ModelSortDirection, Switchboard, User } from 'src/app/API.service';
import { faHeadset, faPhone, faTasks, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { TwilioService } from 'src/app/services/twilio.service';
import * as Twilio from 'twilio-client';
import { Reservation } from 'twilio-taskrouter';

import { AuthService } from 'src/app/services/auth.service';
import { API } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  user: User;
  faHeadset = faHeadset;
  faTasks = faTasks;
  faPhone = faPhone;
  faVoicemail = faVoicemail;
  pulse: boolean = false;
  toggled: boolean = false;

  addMember: boolean = false;
  transferUser: boolean = false;
  showDialpad: boolean = false;
  isHolded: boolean = false;

  switchboards: Switchboard[];

  reservation: Reservation = null;
  callConnection: Twilio.Connection = null;
  call: Call = null;

  voicemailCalls: Call[] = [];
  activeCalls: Call[] = [];

  initNumber: string = null;

  tab: string = 'call';

  constructor(
    private api: APIService,
    private twilioService: TwilioService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // WE INIT THE DEVICE AND WORKER
    this.twilioService.initDeviceAndWorker();
    this.twilioService.manageCalls();

    // WE GET THE LIST OF SWITCHBOARD
    this.api.SwitchboardByPosition('switchboard', { gt: -1 }, ModelSortDirection.ASC).then((event: any) => {
      this.switchboards = event.items;
    });
    
    // WE GET THE CURRENT USER
    this.user = this.authService.getCurrentUser();

    // WE LISTEN FOR UPDATES ON THE CURRENT CALL
    this.api.OnUpdateCallListener.subscribe((event: any) => {
      const call = event.value.data.onUpdateCall;
      if(this.call && this.call.id == call.id) {
        this.call = call;
      }
    });

    // WE LISTEN TO VOICEMAIL CALLS
    this.twilioService.voicemailCalls.subscribe((voicemailCalls) => {
      this.voicemailCalls = voicemailCalls;
    });

    // WE LISTEN TO THE CALL CONNECTION
    this.twilioService.callConnection.subscribe((callConnection) => {
      this.callConnection = callConnection;
      if (this.callConnection) {
        const mode = this.callConnection.customParameters.get('mode');
        if (mode == 'outbound' || mode == 'coaching') {
          this.api.GetCall(this.callConnection.customParameters.get('id')).then((call: any) => {
            this.call = call;
          });
        }

        this.tab = 'call';
        this.toggled = true;
      }
    });

    // WE LISTEN TO THE RESERVATION
    this.twilioService.reservation.subscribe((reservation) => {
      if (reservation) {
        const taskAttributes = reservation.task.attributes
        if (taskAttributes.call_sid) {
          this.api.CallByCallSid(taskAttributes.call_sid).then((event: any) => {
            this.call = event.items[0];
          });
        }

        this.reservation = reservation;
        this.tab = 'call';
        this.toggled = true;
        this.pulse = true;
      } else {
        this.call = null;
        this.reservation = null;
        this.pulse = false;
      }
    });

    // WE LISTEN TO THE ACTIVE CALLS
    this.twilioService.activeCalls.subscribe((activeCalls) => {
      this.activeCalls = activeCalls;
    });

    // WE LISTEN TO THE CALLTO ACTION
    this.twilioService.toCall.subscribe((toCall) => {
      if (toCall) {
        this.initNumber = toCall;
        this.tab = 'call';
        this.toggled = true;
      }
    });

    // WE REFRESH THE USER FOR THE WORKER STATUS
    this.api.OnUpdateUserListener.subscribe(async (event: any) => {
      const user = event.value.data.onUpdateUser;
      if (user.id == this.user.id) {
        this.user = user;
      }
    });
  }

  coldTransfer(user: User) {
    this.reservation.task.transfer(user.workerSid, {
      mode: 'COLD'
    }).then(() => {
      this.transferUser = false;
    });
  }

  warmTransfer(user: User) {
    this.reservation.task.transfer(user.workerSid, {
      mode: 'WARM'
    }).then(() => {
      this.transferUser = false;
      this.isHolded = true;
    });
  }

  callAddMember($event: string | User) {
    if(typeof $event == 'string') {
      API.post('calls','/calls/call-add-participant', {
        body: {
          mode: 'number',
          conferenceName: this.call.conferenceName,
          params: {
            to: $event,
            from: this.user.phone  
          }
        }
      }).then(() => {
        this.addMember = false;
      });
    } else {
      API.post('calls','/calls/call-add-participant', {
        body: {
          mode: 'user',
          workspaceSid: environment.workspaceSid,
          conferenceName: this.call.conferenceName,
          params: {
            workflowSid : $event.workflowSid,
            attributes: {
              call_sid: this.call.callSid,
              mode: 'joinConference',
              from: this.user.phone,
              conferenceName: this.call.conferenceName
            }
          }
        }
      }).then(() => {
        this.addMember = false;
      });
    }
  }
}
