<div class="rounded bordered p-3 mb-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
            <div class="font-size-lg text-dark">{{ call.from }}</div>
            <div class="text-muted">{{ call.completeAt | date:"medium" }}</div>
        </div>
        <div class="dropdown dropdown-inline ml-2">
            <button type="button" 
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
                (click)="show = !show">
                <fa-icon [icon]="faEllipsisH"></fa-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" [class.show]="show">
                <span (click)="archive()" class="dropdown-item">Marquer comme lu</span>
                <span (click)="callBack()" class="dropdown-item">Rapeller</span>
            </div>
        </div> 
    </div>
    <app-audio-player [src]="call.recordingUrl"></app-audio-player>
</div>