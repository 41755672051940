import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from '../API.service';
import { AuthService } from '../services/auth.service';
import { DateUtils } from '@aws-amplify/core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private cookieService: CookieService,
    private api: APIService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const user = this.auth.getCurrentUser();
    if (user) {
      return of(true);
    } else {
      const cognitoId = this.cookieService.get('cognitoId');
      
      Auth.currentSession().then((result: any) => {
        DateUtils.setClockOffset(result.clockDrift * 1000 * -1);
      });

      if (cognitoId) {
        return from(this.api.GetUser(cognitoId)).pipe((
          map((user: any) => {
            this.auth.user.next(user);
            return true;
          })
        ))
      } else {
        this.router.navigate(['/login']);
        return of(false);
      }
    }
  }
}