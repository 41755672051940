<form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row">
        <ng-container *ngFor="let key of keys">
            <div *ngIf="!key.conditionField || form.value[key.conditionField] == key.conditionValue"
                [class]="key.col ? key.col : 'col-12'">
                <div class="form-group" [ngSwitch]="key.type">

                    <label>
                        {{ key.label }}
                        <span *ngIf="key.required" class="text-danger">*</span>
                    </label>

                    <ng-container *ngSwitchCase="'input'">
                        <input class="form-control" [formControlName]="key.name">
                    </ng-container>

                    <ng-container *ngSwitchCase="'password'">
                        <input type="password" class="form-control" [formControlName]="key.name">
                    </ng-container>

                    <ng-container *ngSwitchCase="'month'">
                        <input type="month" class="form-control" [formControlName]="key.name">
                    </ng-container>

                    <ng-container *ngSwitchCase="'time'">
                        <input type="time" class="form-control" [formControlName]="key.name">
                    </ng-container>

                    <ng-container *ngSwitchCase="'date'">
                        <input type="date" class="form-control" [formControlName]="key.name">
                    </ng-container>

                    <ng-container *ngSwitchCase="'select'">
                        <select class="form-control" [formControlName]="key.name">
                            <option *ngFor="let option of key.options" [value]="option.value">{{ option.label }}
                            </option>
                        </select>
                    </ng-container>

                    <ng-container *ngSwitchCase="'textarea'">
                        <textarea class="form-control" rows="3" [formControlName]="key.name"></textarea>
                    </ng-container>

                    <ng-container *ngSwitchCase="'file'">
                        <div></div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" [id]="key.name" [formControlName]="key.name"
                                (change)="onFileChange($event, key)">
                            <label class="custom-file-label" for="customFile">{{ fileKeys[key.name] ? fileKeys[key.name]
                                :
                                'Choisir un fichier' }}</label>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'boolean'">
                        <div class="radio-inline">
                            <label class="radio">
                                <input type="radio" [value]="true" checked [formControlName]="key.name">
                                <span [class]="form.value[key.name] === true ? 'checked' : ''"></span>Oui
                            </label>
                            <label class="radio">
                                <input type="radio" [value]="false" [formControlName]="key.name">
                                <span [class]="form.value[key.name] === false ? 'checked' : ''"></span>Non</label>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'radio'">
                        <div class="radio-inline">
                            <label class="radio" *ngFor="let option of key.options">
                                <input type="radio" [value]="option.value" [formControlName]="key.name">
                                <span [class]="form.value[key.name] === option.value ? 'checked' : ''"></span>{{
                                option.label }}
                            </label>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'multiselect'">
                        <app-multiselect [options]="key.options" [isDisabled]="false" [key]="key.name"
                            [parentForm]="form" [optionLabel]="key.optionLabel" [optionValue]="key.optionValue"
                            [placeholder]="key.placeholder" [multiple]="key.multiple">
                        </app-multiselect>
                    </ng-container>

                    <ng-container *ngSwitchCase="'text-editor'">
                        <app-text-editor [key]="key.name" [parentForm]="form">
                        </app-text-editor>
                    </ng-container>

                    <div class="alert alert-info mt-3" *ngIf="key.help">{{key.help}}</div>
                </div>
            </div>

        </ng-container>
    </div>


    <button *ngIf="hasSubmitButton" class="btn btn-primary" type="submit">Valider</button>
</form>