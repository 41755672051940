import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges {
  @Input() keys: {
    name: string,
    label: string,
    type: string
  }[];
  @Input() model: any;
  @Input() hasSubmitButton: boolean = true;
  form: FormGroup;
  files: {} = {};
  fileKeys: {} = {};

  @Output() submitted: EventEmitter<any> = new EventEmitter(null);
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

    this.form = this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.model && changes.model.firstChange == false) {
      const values = {};
      this.keys.map((key) => {
        values[key.name] = this.getDefaultValue(key);
      });
      this.form.patchValue(values);
    }
  }

  getDefaultValue(key) {
    let defaultValue = null;
    if(key.type != 'file') {
      defaultValue = this.model[key.name] !== null ? this.model[key.name] : null;
    } else {
      this.fileKeys[key.name] = this.model[key.name];
    }
    return defaultValue;
  }

  getValidators(key) {
    const validators = [];
    if(key.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  createFormGroup(): FormGroup {
    const group = {};
    this.keys.map((key) => {
      let defaultValue = this.getDefaultValue(key);
      let validators = this.getValidators(key);
      group[key.name] = [defaultValue, validators];
    });

    return this.formBuilder.group(group);
  }

  async onFileChange($event, key) {
    this.loaderService.emitChange(true);
    const name = ""+ Date.now();
    const result: any = await (await this.storageService.storeFile(key.directory, name, $event.target.files[0]));
    this.fileKeys[key.name] = result.key;
    this.loaderService.emitChange(false);
  }

  onSubmit() {
    if(this.form.status != 'VALID') {
      this.alertService.emitChange({
        type: 'danger',
        message: 'Une erreur de validation s\'est produite. Merci de vérifier que les champs requis sont bien renseignés.'
      });
    } else {
      const value = this.form.value;
      const data = this.keys.reduce((carry, key) => {
        if(key.type === 'file') {
          carry[key.name] = this.fileKeys[key.name];
        } else {
          carry[key.name] = value[key.name];
        }
        return carry;
      }, {});
      this.submitted.emit(data);
    }
    
  }
}
