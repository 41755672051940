import { Component, Input, OnInit } from '@angular/core';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-iconbox',
  templateUrl: './iconbox.component.html',
  styleUrls: ['./iconbox.component.scss']
})
export class IconboxComponent implements OnInit {
  @Input() icon = faHandshake;
  @Input() title: string = 'Titre';
  @Input() text: string = 'Texte';
  @Input() color: string = 'primary';
  @Input() link: string[] = [''];
  constructor() { }

  ngOnInit(): void {
  }

}
