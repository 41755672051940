import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faBars, faHeadset, faPowerOff, faTv } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faHeadset = faHeadset;
  faPowerOff = faPowerOff;
  faBars = faBars;
  faTv = faTv;
  toggled: string = null;
  @Output() navbarToggled: EventEmitter<any> = new EventEmitter(null);
  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void {

  }

  signOut() {
    this.auth.signOut();
  }

  toggle(string: string) {
    if (this.toggled !== string) {
      this.toggled = string;
    } else {
      this.toggled = null;
    }
  }

  toggleNav() {
    this.navbarToggled.emit(true);
  }
}
