<div tabindex="=0" class="multiselect-dropdown">
    <div [class.disabled]="isDisabled">
        <span class="dropdown-btn" [class.open]="showDropdownList" (click)="toggleDropdown($event)">
            <span
                *ngIf="(selectedOptions.length == 0 && multiple == true) || (selectedOption == null && multiple == false); else selected"
                [innerHtml]="placeholder"></span>
            <ng-template #selected>
                <ng-container *ngIf="multiple == true">
                    <span (click)="onOptionClick($event,selectedOption,true)" class="selected-item pointer"
                        *ngFor="let selectedOption of selectedOptions;trackBy: trackByFn;let k = index">
                        {{ selectedOption[optionLabel] }}
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </span>
                </ng-container>
                <ng-container *ngIf="multiple == false">
                    <span [innerHtml]="selectedOption[optionLabel]"></span>
                </ng-container>
            </ng-template>
        </span>
    </div>
    <div class="dropdown-list" [hidden]="!showDropdownList">
        <div class="p-3">
            <input type="text" class="form-control" placeholder="Recherchez..." [(ngModel)]="search" (ngModelChange)="searchChanged($event)">
        </div>
        <ul class="item2">
            <li *ngFor="let option of filteredOptions; let i = index;" class="multiselect-item-checkbox"
                [class.selected]="isSelected(option)" (click)="onOptionClick($event,option,true)">
                <div>{{ option[optionLabel] }}</div>
            </li>
        </ul>
    </div>
</div>