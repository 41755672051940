import { Component, Input, OnInit } from '@angular/core';
import { faCloud, faEllipsisH, faInfo, faUser } from '@fortawesome/free-solid-svg-icons';
import { Call, User } from 'src/app/API.service';
import { TwilioService } from 'src/app/services/twilio.service';
@Component({
  selector: 'app-phone-calls',
  templateUrl: './phone-calls.component.html',
  styleUrls: ['./phone-calls.component.scss']
})
export class PhoneCallsComponent implements OnInit {
  @Input() activeCalls: Call[];
  @Input() user: User;
  faCloud = faCloud;
  faUser = faUser;
  faInfo = faInfo;
  faEllipsisH = faEllipsisH;

  dropdownCallId: string = null;
  constructor(
    private twilioService: TwilioService
  ) { }

  ngOnInit(): void {
  }

  toggleDropdown(activeCall) {
    if (this.dropdownCallId == activeCall.id) {
      this.dropdownCallId = null;
    } else {
      this.dropdownCallId = activeCall.id;
    }
  }

  coach(activeCall) {
    this.twilioService.coach(activeCall);
  }

  listen(activeCall) {
    this.twilioService.listen(activeCall);
  }

}
