<div class="card card-custom wave wave-animate-slow mb-8 mb-lg-0" [class]="'wave-'+ color">
    <div class="card-body">
        <div class="d-flex align-items-center p-5">
            <div class="mr-6">
                <span class="svg-icon " [class]="'svg-icon-'+ color">
                    <fa-icon [icon]="icon" size="3x"></fa-icon>
                </span>
            </div>
            <div class="d-flex flex-column">
                <a [routerLink]="link" class="text-dark font-weight-bold font-size-h4 mb-3" [class]="'text-hover-'+ color">{{ title }}</a>
                <div class="text-dark-75">{{ text }}</div>
            </div>
        </div>
    </div>
</div>