import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { API, Auth } from 'aws-amplify';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APIService, User } from '../API.service';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { CookieService } from 'ngx-cookie-service';
import { DateUtils } from '@aws-amplify/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: BehaviorSubject<User>;

  constructor(
    private router: Router,
    private api: APIService,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {
    this.user = new BehaviorSubject<User>(null);
  }

  public signUp(params): Observable<any> {
    return from(Auth.signUp(params)).pipe(
      map((result) => {
        this.createUser(result, params);
      }),
      catchError((error) => {
        if (error.code == 'InvalidParameterException') {
          this.alertService.emitChange({
            type: 'danger',
            message: 'Le mot de passe doit contenir au moins 8 caractères'
          });
        } else if (error.code == 'UsernameExistsException') {
          this.alertService.emitChange({
            type: 'danger',
            message: 'Le nom d\'utilisateur existe déjà'
          });
        } else {
          this.alertService.emitChange({
            type: 'danger',
            message: 'Tous les champs sont obligatoires'
          });
        }

        return throwError(error.error);
      })
    )
  }

  createUser(result, params) {
    API.post('calls', '/calls/create-worker', {
      body: {
        id: result.userSub,
        username: params.username,
      }
    }).then((response) => {
      this.api.CreateUser({
        id: result.userSub,
        username: params.username,
        email: params.attributes.email,
        workerSid: response.worker.sid,
        taskQueueSid: response.taskQueue.sid,
        workflowSid: response.workflow.sid,
        available: false,
        admin: false,
        activitySid: environment.offlineSid
      });
    });
  }

  public confirmSignUp(username, code): Observable<any> {
    return from(Auth.confirmSignUp(username, code));
  }

  getCurrentUser(): User {
    return this.user.value;
  }

  public getUser(id: string): Observable<boolean> {
    return from(this.api.GetUser(id)).pipe(
      map((user: any) => {
        this.user.next(user);
        return true;
      })
    );
  }

  public signIn(username, password): Observable<boolean> {
    return from(Auth.signIn(username, password))
      .pipe(
        tap((cognitoUser: any) => {
          DateUtils.setClockOffset(cognitoUser.signInUserSession.clockDrift * 1000 * -1);
          this.cookieService.set('cognitoId',cognitoUser.attributes.sub);
          return of(true);
        }),
        catchError((error) => {
          this.alertService.emitChange({
            type: 'danger',
            message: 'Identifiant ou mot de passe incorrect'
          });

          return throwError(error.error);
        })
      );
  }

  public signOut() {
    from(Auth.signOut())
      .subscribe(
        result => {
          this.cookieService.delete('cognitoId');
          this.user.next(null);
          this.router.navigate(['/login']);
        },
      );
  }
}