<ng-container>
    <div class="d-flex align-items-center justify-content-between">
        <div class="mr-3">
            <button *ngIf="!isHolded" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="hold()">
                <fa-icon [icon]="faPause"></fa-icon>
            </button>
            <button *ngIf="isHolded" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="unhold()">
                <fa-icon [icon]="faPlay"></fa-icon>
            </button>
        </div>

        <div class="mr-3">
            <button *ngIf="!isMuted" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="mute()">
                <fa-icon [icon]="faMute"></fa-icon>
            </button>
            <button *ngIf="isMuted" class="btn btn-icon btn-light btn-hover-primary btn-sm" (click)="unmute()">
                <fa-icon [icon]="faUnmute"></fa-icon>
            </button>
        </div>

        <button class="mr-3 btn btn-icon btn-light btn-hover-primary btn-sm" (click)="startShowDialpad()">
            <fa-icon [icon]="faGripHorizontal"></fa-icon>
        </button>

        <button class="mr-3 btn btn-icon btn-light btn-hover-primary btn-sm" (click)="startTransfer()">
            <fa-icon [icon]="faShare"></fa-icon>
        </button>

        <button class="mr-3 btn btn-icon btn-light btn-hover-primary btn-sm" (click)="startAddMember()">
            <fa-icon [icon]="faUserPlus"></fa-icon>
        </button>
    </div>

    <div class="d-flex justify-content-center mt-5">
        <button class="btn btn-icon btn-lg btn-circle btn-danger" (click)="hangup()">
            <fa-icon [icon]="faPhone"></fa-icon>
        </button>
    </div>
</ng-container>