import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chronometer',
  templateUrl: './chronometer.component.html',
  styleUrls: ['./chronometer.component.scss']
})
export class ChronometerComponent implements OnInit {
  @Input() start;
  timeInSeconds: number;
  minutes: string;
  seconds: string;
  constructor() { }

  ngOnInit(): void {
    const date = new Date(this.start);
    const now = new Date();
    this.timeInSeconds = Math.round(Math.abs((date.getTime() - now.getTime()) / 1000));
    this.getChronometer();

    setInterval(() => {
      this.timeInSeconds++;
      this.getChronometer();
    }, 1000);
  }

  getChronometer() {
    let minutes = Math.floor(this.timeInSeconds / 60);
    let seconds = this.timeInSeconds - (minutes * 60);
    this.minutes = minutes.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
    this.seconds = seconds.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
  }

}
