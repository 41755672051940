import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as TwilioClient from 'twilio-client';
import { Worker, Reservation } from 'twilio-taskrouter';
import { API } from 'aws-amplify';
import { APIService, Call, User } from '../API.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  device: TwilioClient.Device = null;
  worker: Worker;
  user: User;

  deviceReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  toCall: BehaviorSubject<string> = new BehaviorSubject(null);
  activeCalls: BehaviorSubject<Call[]> = new BehaviorSubject([]);
  callConnection: BehaviorSubject<TwilioClient.Connection> = new BehaviorSubject(null);
  reservation: BehaviorSubject<Reservation> = new BehaviorSubject(null);
  voicemailCalls: BehaviorSubject<Call[]> = new BehaviorSubject([]);

  constructor(
    private api: APIService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  async listActiveCalls() {
    let nextToken = null;
    let activeCalls = [];
    do {
      let result: any = await this.api.CallByDate(new Date().toISOString().substring(0, 10), null, null, {
        status: {
          ne: 'complete'
        }
      }, 10000, nextToken);
      activeCalls = activeCalls.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken != null);
    this.activeCalls.next(activeCalls);
  }

  async listVoicemailCalls() {
    // let nextToken = null;
    // let voicemailCalls = [];
    // do {
    //   let result: any = await this.api.ListCalls({
    //     userID: { eq: this.user.id },
    //     voicemail: { eq: true },
    //     voicemailRead: { eq: false }
    //   }, 10000, nextToken);
    //   voicemailCalls = voicemailCalls.concat(result.items);
    //   nextToken = result.nextToken;
    // } while (nextToken != null);
    // this.voicemailCalls.next(voicemailCalls);
  }

  manageCalls() {
    // WE INITIALISE THE ACTIVE CALLS WITH THE UNCOMPLETE CALLS
    this.listActiveCalls();

    // WE INITIALISE THE VOICEMAIL CALLS
    this.listVoicemailCalls();

    // WE LISTEN TO CALL CREATION TO FEED THE ACTIVE CALLS
    this.api.OnCreateCallListener.subscribe((event: any) => {
      const call = event.value.data.onCreateCall;
      this.activeCalls.next([...this.activeCalls.getValue(), call]);
    });

    // WE LISTEN TO CALL UPDATES
    this.api.OnUpdateCallListener.subscribe((event: any) => {
      const call = event.value.data.onUpdateCall;

      // WE FIND THE CORRESPONDING ACTIVE CALL
      const activeCalls = this.activeCalls.getValue();
      let updatedCallIndex = activeCalls.findIndex((activeCall) => {
        return activeCall.callSid == call.callSid;
      });

      if (call.status == 'complete') {
        // IF CALL STATUS IS COMPLETE WE REMOVE IT FROM THE LIST OF ACTIVE CALLS
        activeCalls.splice(updatedCallIndex, 1);
        this.activeCalls.next(activeCalls);
      } else {
        // ELSE WE UPDATE THE ACTIVE CALL LIST
        activeCalls[updatedCallIndex] = call;
        this.activeCalls.next(activeCalls);
      }

      // IF IS VOICEMAIL OF THE USER
      if (call.userID == this.user.id && call.voicemail) {
        const voicemailCalls = this.voicemailCalls.getValue();
        let voicemailCallIndex = voicemailCalls.findIndex((voicemailCall) => {
          return voicemailCall.id == call.id;
        });

        if (call.voicemailRead == true) {
          if (voicemailCallIndex !== undefined) {
            voicemailCalls.splice(voicemailCallIndex, 1);
            this.voicemailCalls.next(voicemailCalls);
          }
        } else {
          if (voicemailCallIndex !== undefined) {
            voicemailCalls[voicemailCallIndex] = call;
            this.voicemailCalls.next(voicemailCalls);
          } else {
            this.voicemailCalls.next([...voicemailCalls, call]);
          }
        }
      }
    });
  }

  setUser() {
    this.user = this.authService.getCurrentUser();
  }

  getCapabilityToken(): Promise<any> {
    this.setUser();
    return API.post('calls', '/calls/get-capability-tokens', {
      body: {
        username: this.user.username,
        workerSid: this.user.workerSid,
        workspaceSid: environment.workspaceSid
      }
    });
  }

  updateUserActivity(worker) {
    this.api.UpdateUser({
      id: this.user.id,
      available: worker.activity.available,
      activitySid: worker.activity.sid
    });
  }

  async initDeviceAndWorker() {
    this.getCapabilityToken().then((data) => {
      this.initWorker(data.taskrouterToken);
      this.initDevice(data.token);
    });
  }

  initWorker(token) {
    this.createWorker(token);
    this.registerWorkerEvents();

  }

  initDevice(token) {
    this.device = this.createDevice(token);
    this.registerDeviceEvents();

  }

  createWorker(token) {
    this.worker = new Worker(token, false);
  }

  registerWorkerEvents() {
    this.worker.on('ready', (worker) => {
      // WE INITIALISE THE WORKER ACTIVITY
      if (this.user.activitySid != worker.activity.sid) {
        this.updateUserActivity(worker);
      }

      // WE LISTEN FOR ACTIVITY CHANGES
      this.worker.on('activityUpdated', (worker) => {
        this.updateUserActivity(worker);
      });

      // WE INITIALISE THE RESERVATIONS
      this.worker.reservations.forEach((reservation) => {
        this.registerReservationEvents(reservation);
        this.reservation.next(reservation);
      });
    });

    // WE LISTEN FOR NEW RESERVATIONS
    this.worker.on('reservationCreated', reservation => {
      this.registerReservationEvents(reservation);
      this.reservation.next(reservation);
    });

    this.worker.on('tokenExpired', () => {
      this.getCapabilityToken().then((data) => {
        this.worker.updateToken(data.taskrouterToken);
      });
    });
  }

  createDevice(token) {
    // WE CREATE THE DEVICE
    const device = new TwilioClient.Device(token, {
      edge: 'dublin',
      fakeLocalDTMF: true,
      enableRingingState: true,
      allowIncomingWhileBusy: false,
      closeProtection: true
    });

    return device;
  }

  registerDeviceEvents() {
    // WE LISTEN TO CALL CONNECTIONS
    this.device.on('incoming', (callConnection: TwilioClient.Connection) => {
      const mode = callConnection.customParameters.get('mode');
      const id = callConnection.customParameters.get('id');

      // IT THIS IS AN OUTBOUND CALL CONNECTION
      if (mode == 'outbound') {
        if (id) {
          this.acceptCallConnection(callConnection);
          this.createCallParticipant(id, callConnection.parameters.CallSid, mode);
        } else {
          const to = callConnection.customParameters.get('to').replace(' ', '+');
          const callCampaignID = callConnection.customParameters.get('callCampaignID');
          const customerId = callConnection.customParameters.get('customerId');
          const callReasonID = callConnection.customParameters.get('callReasonID');
          API.post('calls', '/calls/get-customer-info', {
            body: {
              number: to,
              customerId: customerId,
              websiteId: callConnection.customParameters.get('websiteId')
            }
          }).then((result) => {
            this.api.CreateCall({
              callSid: callConnection.parameters.CallSid,
              from: callConnection.customParameters.get('from'),
              to: to,
              direction: 'outbound',
              status: 'ongoing',
              pickupAt: new Date().toISOString(),
              userID: this.user.id,
              orders: result.orders,
              customer: result.customer,
              shippings: result.shippings,
              cart: result.cart,
              switchboardID: callConnection.customParameters.get('switchboardID'),
              conferenceName: callConnection.customParameters.get('taskSid'),
              callReasonID: callReasonID,
              callCampaignID: callCampaignID,
              date: new Date().toISOString().substring(0, 10),
            }).then((result) => {
              const attributes = this.reservation.value.task.attributes;
              attributes.id = result.id;
              attributes.call_sid = callConnection.parameters.CallSid;
              this.reservation.value.task.setAttributes(attributes);
              callConnection.customParameters.set('id', result.id);
              this.acceptCallConnection(callConnection);
              this.createCallParticipant(result.id, callConnection.parameters.CallSid, mode);

              if (callCampaignID) {
                this.router.navigate(['/telephony/call-campaigns/' + result.callCampaignID + '/call/' + result.id])
              }
            });
          });
        }
      } else if (mode == 'directInbound' || mode == 'inbound') {
        this.api.CallByCallSid(callConnection.customParameters.get('callSid')).then((event: any) => {
          const call = event.items[0];
          this.api.UpdateCall({
            id: call.id,
            pickupAt: new Date().toISOString(),
            status: 'ongoing',
            userID: this.user.id,
            conferenceName: callConnection.customParameters.get('taskSid')
          });

          this.createCallParticipant(call.id, callConnection.parameters.CallSid, mode);
        });

        this.acceptCallConnection(callConnection);
      } else {
        this.acceptCallConnection(callConnection);
      }
    });

    this.device.on('ready', () => {
      this.deviceReady.next(true);
    });

    this.device.on('offline', () => {
      this.getCapabilityToken().then((data) => {
        this.initDevice(data.token);
      });
    });
  }

  createCallParticipant(callID, callSid, mode) {
    this.api.CreateCallParticipant({
      callID: callID,
      callSid: callSid,
      status: 'ongoing',
      mode: mode,
      userID: this.user.id
    })
  }

  registerReservationEvents(reservation: Reservation) {
    reservation.on('canceled', () => {
      this.reservation.next(null);
    });

    reservation.on('timeout', () => {
    });

    reservation.on('rejected', () => {
      this.reservation.next(null);
    });

    reservation.on('accepted', () => {
    });

    reservation.on('wrapup', () => {
    });

    reservation.on('completed', (reservation) => {
      console.log(reservation);
      const isOutgoingTransfer = reservation.task.transfers.outgoing != null;
      const isCoaching = reservation.task.attributes.mode == 'coaching';
      const isJoinConference = reservation.task.attributes.mode == 'joinConference';

      // UPDATE CALL TO COMPLETE 
      if (!isOutgoingTransfer && !isCoaching && !isJoinConference) {
        this.api.CallByConferenceName(reservation.task.sid).then((event: any) => {
          const call = event.items[0];
          if (call) {
            if (reservation.task.attributes.incrementId) {
              API.post('calls', '/calls/getOrderInfos', {
                body: {
                  incrementId: reservation.task.attributes.incrementId
                }
              }).then((response) => {
                this.api.UpdateCall({
                  id: call.id,
                  callQualificationID: reservation.task.attributes.callQualificationID,
                  callReasonID: reservation.task.attributes.callReasonID,
                  orderId: response.order.orderId,
                  turnOver: response.order.turnOver,
                  completeAt: new Date().toISOString(),
                  status: 'complete'
                });
              });
            } else {
              this.api.UpdateCall({
                id: call.id,
                callQualificationID: reservation.task.attributes.callQualificationID,
                callReasonID: reservation.task.attributes.callReasonID,
                orderId: null,
                turnOver: 0,
                completeAt: new Date().toISOString(),
                status: 'complete'
              });
            }
          }
        });
      }

      this.reservation.next(null);
    });
  }

  acceptCallConnection(callConnection: TwilioClient.Connection) {
    callConnection.accept();
    this.callConnection.next(callConnection);

    // ON CONNECTION DISCONNECT
    callConnection.on('disconnect', (callConnection: TwilioClient.Connection) => {
      const reservation = this.reservation.value;
      const isOutgoingTransfer = reservation.task.transfers.outgoing != null;
      const isCoaching = reservation.task.attributes.mode == 'coaching';
      const isJoinConference = reservation.task.attributes.mode == 'joinConference';

      if (!isOutgoingTransfer && !isCoaching && !isJoinConference) {
        // WE SET THE CALL STATUS TO HANGUP
        this.api.CallByConferenceName(callConnection.customParameters.get('taskSid')).then((event: any) => {
          const call = event.items[0];
          if (call) {
            this.api.UpdateCall({
              id: call.id,
              hangupAt: new Date().toISOString(),
              status: 'hangup'
            }).then(() => {
              API.post('calls', '/calls/hangup', {
                body: {
                  conferenceName: call.conferenceName
                }
              });
            });
          }
        });
      } else {
        // IF WE HANGUP BUT THE CALL IS NOT OVER, WE COMPLETE THE RESERVATION
        this.reservation.value.complete();
      }

      //WE UPDATE THE PARTICIPANT TO HANGUP
      this.api.CallParticipantByCallSid(callConnection.parameters.CallSid).then((event) => {
        const callParticipant = event.items[0];
        this.api.UpdateCallParticipant({
          id: callParticipant.id,
          status: 'hangup'
        });
      });

      this.callConnection.next(null);
    });
  }

  disconnect() {
    const activeConnection = this.device.activeConnection();
    if (activeConnection) {
      activeConnection.disconnect();
    }
  }

  mute() {
    this.device.activeConnection().mute(true);
  }

  unmute() {
    this.device.activeConnection().mute(false);
  }

  formatNumber(number: string) {
    if (number.length == 10) {
      return '+33' + number.substring(1);
    }

    return number;
  }

  compose(to, user: User, callVia, callReasonID) {
    this.worker.createTask(
      to,
      callVia.model.phone,
      user.workflowSid,
      user.taskQueueSid,
      {
        taskChannelUniqueName: 'voice',
        attributes: {
          mode: 'outbound',
          from: callVia.model.phone,
          to: to,
          websiteId: callVia.type == 'switchboard' ? callVia.model.connectorId : null,
          switchboardID: callVia.type == 'switchboard' ? callVia.model.id : null,
          callReasonID: callReasonID
        }
      }
    );
  }

  composeCallCampaign(to, customerId, callCampaign) {
    this.worker.createTask(
      this.formatNumber(to),
      this.user.phone,
      this.user.workflowSid,
      this.user.taskQueueSid,
      {
        taskChannelUniqueName: 'voice',
        attributes: {
          mode: 'outbound',
          from: this.user.phone,
          to: to,
          callCampaignID: callCampaign.id,
          customerId: customerId
        }
      }
    );
  }

  coach(call: Call) {
    this.worker.createTask(
      'client:' + this.user.username,
      this.user.phone,
      this.user.workflowSid,
      this.user.taskQueueSid,
      {
        taskChannelUniqueName: 'voice',
        attributes: {
          mode: 'coaching',
          id: call.id,
          conferenceName: call.conferenceName,
          coach: call.callSid
        }
      }
    );
  }

  listen(call: Call) {
    this.worker.createTask(
      'client:' + this.user.username,
      this.user.phone,
      this.user.workflowSid,
      this.user.taskQueueSid,
      {
        taskChannelUniqueName: 'voice',
        attributes: {
          mode: 'coaching',
          id: call.id,
          conferenceName: call.conferenceName
        }
      }
    );
  }

  sendDtmf(digit: string) {
    this.device.activeConnection().sendDigits(digit);
  }

  getOutputDevices(): Promise<any> {
    return navigator.mediaDevices.getUserMedia({ audio: true }).then((result) => {
      return this.device.audio.availableOutputDevices;
    });
  }

  getInputDevices(): Promise<any> {
    return navigator.mediaDevices.getUserMedia({ audio: true }).then((result) => {
      return this.device.audio.availableInputDevices;
    });
  }


  setInputDevice(deviceId) {
    this.device.audio.setInputDevice(deviceId);
  }
}
