import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEllipsisH, faGripHorizontal, faMicrophone, faMicrophoneSlash, faPause, faPhone, faPlay, faShare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import * as Twilio from 'twilio-client';
import { TwilioService } from 'src/app/services/twilio.service';
import { Reservation } from 'twilio-taskrouter';
import { Call, User } from 'src/app/API.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-ongoing-call',
  templateUrl: './ongoing-call.component.html',
  styleUrls: ['./ongoing-call.component.scss']
})
export class OngoingCallComponent implements OnInit {
  @Input() callConnection: Twilio.Connection = null;
  @Input() reservation: Reservation = null;
  @Input() user: User;
  @Input() isHolded: boolean = false;
  @Input() call: Call = null;

  @Output() transfer: EventEmitter<boolean> = new EventEmitter(false);
  @Output() addMember: EventEmitter<boolean> = new EventEmitter(false);
  @Output() showDialpad: EventEmitter<boolean> = new EventEmitter(false);


  isMuted: boolean = false;
  addUser: boolean = false;

  faEllipsisH = faEllipsisH;
  faUserPlus = faUserPlus;
  faShare = faShare;
  faPhone = faPhone;
  faMute = faMicrophoneSlash;
  faUnmute = faMicrophone;
  faPause = faPause;
  faPlay = faPlay;
  faGripHorizontal = faGripHorizontal;

  constructor(
    private twilioService: TwilioService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    // IF WE ACCEPTED A TRANSFER
    if (this.reservation.task.transfers.incoming) {
      // IF ITS A COLD TRANSFER, WE UNHOLD THE CUSTOMER
      if (this.reservation.task.transfers.incoming.mode == 'COLD') {
        this.unhold();
      // IF ITS A WARM TRANSFER, THE CUSTOMER IN ON HOLD
      } else if (this.reservation.task.transfers.incoming.mode == 'WARM') {
        this.isHolded = true;
      }
    }
  }

  mute() {
    this.twilioService.mute();
    this.isMuted = true;
  }

  unmute() {
    this.twilioService.unmute();
    this.isMuted = false;
  }

  async hold() {
    this.reservation.task.updateParticipant({
      hold: true
    }).then(() => {
      this.isHolded = true;
    });
  }

  unhold() {
    this.reservation.task.updateParticipant({
      hold: false
    }).then(() => {
      this.isHolded = false;
    });
  }

  hangup() {
    this.twilioService.disconnect();
  }

  startTransfer() {
    this.transfer.emit(true);
  }

  startAddMember() {
    this.addMember.emit(true);
  }

  startShowDialpad() {
    this.showDialpad.emit(true);
  }
}
