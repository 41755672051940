<div *ngIf="transferMode && transferUser" class="mt-3 d-flex align-items-center justify-content-center">
    <span class="text-muted text-uppercase">Transfert de {{ transferUser.firstname }}</span>
</div>

<div class="d-flex align-items-center justify-content-center mt-3" *ngIf="show; else waiting">
    <button class="btn btn-icon btn-circle btn-lg btn-danger mr-3" (click)="rejectCallAssignment()">
        <fa-icon [icon]="faPhoneSlash"></fa-icon>
    </button>
    <button class="btn btn-icon btn-circle btn-lg btn-primary" (click)="acceptCallAssignment()">
        <fa-icon [icon]="faPhone"></fa-icon>
    </button>
</div>

<ng-template #waiting>
    <div class="mt-3 d-flex align-items-center justify-content-center">
        <span class="text-muted text-uppercase">Connexion en cours</span>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-3">
        <button class="btn btn-icon btn-circle btn-lg btn-danger mr-3"  (click)="closeConnection()">
            <fa-icon [icon]="faPhoneSlash"></fa-icon>
        </button>
    </div>
</ng-template>