import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconboxComponent } from './iconbox.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [IconboxComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule
  ],
  exports: [IconboxComponent]
})
export class IconboxModule { }
