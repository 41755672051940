import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBackspace, faChevronRight, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { APIService, CallReason, Contact, ModelSortDirection, Switchboard, User } from 'src/app/API.service';
import { TwilioService } from 'src/app/services/twilio.service';
@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() switchboards: Switchboard[];
  @Input() initNumber: string;

  faBackspace = faBackspace;
  faPhone = faPhone;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  keypadForm: FormGroup;
  keys: {}[];
  callReasons: CallReason[] = [];
  selectCallVia: boolean = false;
  callVia: any;
  contacts: Contact[] = [];

  constructor(
    private fb: FormBuilder,
    private twilioService: TwilioService,
    private api: APIService
  ) {
  }

  async ngOnInit() {
    this.callReasons = (await this.api.CallReasonsByPosition('callReason', { gt: -1 }, ModelSortDirection.ASC)).items;

    this.keypadForm = this.fb.group({
      number: [this.initNumber, Validators.required],
      callReasonId: [null, Validators.required]
    });

    this.keypadForm.valueChanges.subscribe((value) => {
      this.searchContacts(value.number);
    });

    const callVia = localStorage.getItem('callVia');
    if (callVia) {
      this.callVia = JSON.parse(callVia);
    } else {
      this.selectCallViaUser(this.user);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.initNumber && !changes.initNumber.isFirstChange()) {
      this.keypadForm.patchValue({
        number: changes.initNumber.currentValue
      });
    }
  }

  searchContacts(search: string) {
    if (search && search.length > 0) {
      this.api.ContactByUserID(this.user.id, null, {
        or: [
          { search: { contains: search.toLowerCase() } },
          { phone: { contains: search.toLocaleLowerCase() } }
        ]
      }, 9).then((event) => {
        this.contacts = event.items;
      });
    } else {
      this.contacts = [];
    }
  }

  selectContact(contact: Contact) {
    this.keypadForm.patchValue({
      number: contact.phone
    });
  };

  selectCallViaUser(user: User) {
    this.callVia = {
      type: 'user',
      model: user
    };

    localStorage.setItem('callVia', JSON.stringify(this.callVia));
    this.selectCallVia = false;
  }

  selectCallViaSwitchboard(switchboard: Switchboard) {
    this.callVia = {
      type: 'switchboard',
      model: switchboard
    };

    localStorage.setItem('callVia', JSON.stringify(this.callVia));
    this.selectCallVia = false;
  }

  compose() {
    if (this.keypadForm.valid) {
      this.twilioService.compose(
        this.keypadForm.value.number,
        this.user,
        this.callVia,
        this.keypadForm.value.callReasonId
      );
    }

  }

}
