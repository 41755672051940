import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  isLoading: boolean = false;
  toggled: boolean = false;
  constructor(
    private websocketService: WebsocketService,
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.websocketService.subscribe();
    this.loaderService.changeEmitted$.subscribe(
      isLoading => {
        this.isLoading = isLoading;
      }
    );

    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.toggled = false;
      }
    });
  }

}
