import { Component, Input, OnInit } from '@angular/core';
import { faCloud, faPhone, faTicketAlt, faUserCog, faUsers } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/API.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() toggled: boolean = false;
  faPhone = faPhone;
  faUserCog = faUserCog;
  faTicketAlt = faTicketAlt;
  faCloud = faCloud;
  faUsers = faUsers;

  opened: string = null;
  user: User;
  constructor(
    private authService: AuthService
  ) {
    this.user = this.authService.getCurrentUser();
  }

  ngOnInit(): void {
  }

  toggle(menu: string) {
    if (menu == this.opened) {
      this.opened = null;
    } else {
      this.opened = menu;
    }
  }

}
